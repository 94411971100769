const EN_US = {
  'ordersOperationalDashboardDomain.title': 'Orders Operational Dashboard',
  'ordersOperationalDashboardDomain.menuItemTitle': 'Operational dashboard',
  'ordersOperationalDashboardDomain.nav.title': 'Operational dashboard (beta)',
  'ordersOperationalDashboardDomain.searchPage.title': 'Search',
  'ordersOperationalDashboardDomain.searchPage.searchTab': 'Find Order',
  'ordersOperationalDashboardDomain.searchPage.deliveryTab': 'Delivery',
  'ordersOperationalDashboardDomain.searchPage.pickupTab': 'Pickup',
  'ordersOperationalDashboardDomain.searchPage.lmdTab': 'Last Mile Delivery',
  'ordersOperationalDashboardDomain.searchPage.searchTab.title': 'Find an Order',
  'ordersOperationalDashboardDomain.searchPage.searchByIdTitle': 'Order ID',
  'ordersOperationalDashboardDomain.searchPage.searchByEmailTitle': 'Email',
  'ordersOperationalDashboardDomain.searchPage.searchByPhoneTitle': 'Phone number',
  'ordersOperationalDashboardDomain.searchPage.searchByFilters': 'Filters ',
  'ordersOperationalDashboardDomain.searchPage.searchType.order': 'Order ID',
  'ordersOperationalDashboardDomain.searchPage.searchType.email': 'Email',
  'ordersOperationalDashboardDomain.searchPage.searchType.phone': 'Phone Number',
  'ordersOperationalDashboardDomain.searchPage.searchSubTitle': 'What are you searching for?',
  'ordersOperationalDashboardDomain.searchPage.searchByOrderType.title': 'Order type',
  'ordersOperationalDashboardDomain.searchPage.orderType.title': 'Order Type',
  'ordersOperationalDashboardDomain.searchPage.orderType.placeholder': 'Order Type',
  'ordersOperationalDashboardDomain.searchPage.dateRangeType.title': 'Filter dates by',
  'ordersOperationalDashboardDomain.searchPage.dateRange.title': 'Date range',
  'ordersOperationalDashboardDomain.searchPage.orderType.delivery': 'Delivery',
  'ordersOperationalDashboardDomain.searchPage.orderType.lmd': 'Last Mile Delivery',
  'ordersOperationalDashboardDomain.searchPage.orderType.pickup': 'Pickup',
  'ordersOperationalDashboardDomain.searchPage.location.title': 'Location',
  'ordersOperationalDashboardDomain.searchPage.status.title': 'Status',
  'ordersOperationalDashboardDomain.searchPage.status.placeholder': 'Status',
  'ordersOperationalDashboardDomain.searchPage.clear': 'Clear',
  'ordersOperationalDashboardDomain.searchPage.apply': 'Apply',
  'ordersOperationalDashboardDomain.searchPage.searchByRetailerLocation.title': 'Location',
  'ordersOperationalDashboardDomain.searchPage.retailerLocation.title': 'Locations',
  'ordersOperationalDashboardDomain.searchPage.retailerLocation.placeholder': 'Locations',
  'ordersOperationalDashboardDomain.searchPage.orderStatus.placed': 'Order Placed',
  'ordersOperationalDashboardDomain.searchPage.orderStatus.waiting': 'Waiting for Delivery',
  'ordersOperationalDashboardDomain.searchPage.orderStatus.enRoute': 'Order En Route',
  'ordersOperationalDashboardDomain.searchPage.orderStatus.complete': 'Order Complete',
  'ordersOperationalDashboardDomain.searchPage.orderStatus.delayed': 'Order Delayed',
  'ordersOperationalDashboardDomain.searchPage.orderStatus.cancelled': 'Order Cancelled',
  'ordersOperationalDashboardDomain.searchPage.filterPill.removeButtonLabel': 'Remove filter',
  'ordersOperationalDashboardDomain.searchPage.clearAll': 'Clear All',
  'ordersOperationalDashboardDomain.searchPage.search': 'Search',
  'ordersOperationalDashboardDomain.searchPage.searchType': 'Search by',
  'ordersOperationalDashboardDomain.searchPage.moreFilters.title': 'More Filters',
  'ordersOperationalDashboardDomain.searchPage.moreFilters.alternativeTitle': 'Filters',
  'ordersOperationalDashboardDomain.searchPage.moreFilters.countPlaceholder': 'More Filters',
  'ordersOperationalDashboardDomain.searchPage.modal.title': 'More filters',
  'ordersOperationalDashboardDomain.searchPage.modal.cancel': 'Cancel',
  'ordersOperationalDashboardDomain.searchPage.modal.save': 'Save',
  'ordersOperationalDashboardDomain.searchPage.modal.clearAll': 'Clear All',
  'ordersOperationalDashboardDomain.searchPage.modal.fulfillmentDate': 'Fulfillment date',
  'ordersOperationalDashboardDomain.searchPage.modal.createDate': 'Create date',
  'ordersOperationalDashboardDomain.searchPage.modal.today': 'Today',
  'ordersOperationalDashboardDomain.searchPage.modal.yesterday': 'Yesterday',
  'ordersOperationalDashboardDomain.searchPage.modal.selected': 'Selected Day',
  'ordersOperationalDashboardDomain.searchPage.modal.custom': 'Custom',
  'ordersOperationalDashboardDomain.searchPage.modal.createDateMessage': 'Create Date: {date}',
  'ordersOperationalDashboardDomain.searchPage.modal.fulfillmentDateMessage':
    'Fulfillment Date: {date}',
  'ordersOperationalDashboardDomain.searchPage.modal.createDateRangeMessage':
    'Create date: {startDate} -> {endDate}',
  'ordersOperationalDashboardDomain.searchPage.modal.fulfillmentDateRangeMessage':
    'Fulfillment date: {startDate} -> {endDate}',
  'ordersOperationalDashboardDomain.searchPage.table.populatedState.title':
    '{numResult} Search results for "{orderId}"',
  'ordersOperationalDashboardDomain.searchPage.table.initialState.title': 'Find an order',
  'ordersOperationalDashboardDomain.searchPage.table.initialState.description':
    'Use the search box, choose a store or store group, and select a date range to start a search.',
  'ordersOperationalDashboardDomain.searchPage.table.initialState.imageAlt': 'magnifying glass',
  'ordersOperationalDashboardDomain.searchPage.table.emptyResultsDefault.title': 'No orders found',
  'ordersOperationalDashboardDomain.searchPage.table.emptyResultsDefault.description':
    'Use the search box, choose a store or store group, and select a date range to start a search.',
  'ordersOperationalDashboardDomain.searchPage.table.emptyResultsDefault.imageAlt':
    'basket with magnifying glass and list',
  'ordersOperationalDashboardDomain.searchPage.table.emptyResultsForOrderId.title':
    'No orders found for "{orderId}"',
  'ordersOperationalDashboardDomain.searchPage.table.emptyResultsForOrderId.description':
    'Instacart order IDs are typically represented as integers. For example, an order ID might look like this: 123123123123. Order IDs can be found in transaction logs.',
  'ordersOperationalDashboardDomain.searchPage.table.emptyResultsForOrderId.imageAlt':
    'basket with magnifying glass and list',
  'ordersOperationalDashboardDomain.searchPage.table.serverError.title': 'Uh oh, an error occurred',
  'ordersOperationalDashboardDomain.searchPage.table.serverError.description':
    'An error occurred while fetching your data. If the issue persists, please contact support.',
  'ordersOperationalDashboardDomain.searchPage.table.serverError.imageAlt':
    'basket with magnifying glass and list',
  'ordersOperationalDashboardDomain.searchPage.table.serverError.timeout.title':
    'Search took too long',
  'ordersOperationalDashboardDomain.searchPage.table.serverError.timeout.description':
    'A request took too long fetching your data. Try narrowing the filters. If the issue persists, please contact support.',
  'ordersOperationalDashboardDomain.searchPage.table.serverError.refetch': 'Search again',
  'ordersOperationalDashboardDomain.searchPage.table.column.orderId': 'Order ID',
  'ordersOperationalDashboardDomain.searchPage.table.column.orderType': 'Order type',
  'ordersOperationalDashboardDomain.searchPage.table.column.orderStatus': 'Status',
  'ordersOperationalDashboardDomain.searchPage.table.column.customerName': 'Customer name',
  'ordersOperationalDashboardDomain.searchPage.table.column.store': 'Store',
  'ordersOperationalDashboardDomain.searchPage.table.column.deliveryEta': 'Delivery ETA',
  'ordersOperationalDashboardDomain.searchPage.table.column.pickupEta': 'Pickup ETA',
  'ordersOperationalDashboardDomain.searchPage.table.column.createDate': 'Created',
  'ordersOperationalDashboardDomain.searchPage.table.column.date': 'Date',
  'ordersOperationalDashboardDomain.searchPage.table.column.orderDate': 'Order date',
  'ordersOperationalDashboardDomain.searchPage.table.column.fulfillmentDate': 'Fulfillment date',
  'ordersOperationalDashboardDomain.searchPage.table.column.status': 'Status',
  'ordersOperationalDashboardDomain.searchPage.table.column.totalCharge': 'Total charge',
  'ordersOperationalDashboardDomain.searchPage.table.column.orderSource': 'Order source',
  'ordersOperationalDashboardDomain.searchPage.table.noResult': 'No results',
  'ordersOperationalDashboardDomain.searchPage.table.delivery': 'Delivery',
  'ordersOperationalDashboardDomain.searchPage.table.pickup': 'Pickup',
  'ordersOperationalDashboardDomain.searchPage.table.lmd': 'Last Mile Delivery',
  'ordersOperationalDashboardDomain.searchPage.tooltip.title': 'Not sure what you are looking for?',
  'ordersOperationalDashboardDomain.searchPage.tooltip.detail':
    'To optimize search performance, start by selecting a store location and then add more filters as necessary.',
  'ordersOperationalDashboardDomain.searchPage.tooltip.betaTag': 'BETA',
  'ordersOperationalDashboardDomain.searchPage.contextError.header': 'Error',
  'ordersOperationalDashboardDomain.searchPage.contextError.message':
    'Retailer request context is not configured properly, please contact support.',
  'ordersOperationalDashboardDomain.lmdtab.table.column.orderId': 'ORDER ID',
  'ordersOperationalDashboardDomain.lmdtab.table.column.orderStatus': 'STATUS',
  'ordersOperationalDashboardDomain.lmdtab.table.column.customerName': 'CUSTOMER NAME',
  'ordersOperationalDashboardDomain.lmdtab.table.column.store': 'STORE',
  'ordersOperationalDashboardDomain.lmdtab.table.column.driverDispatched': 'DRIVER DISPATCH TIME',
  'ordersOperationalDashboardDomain.lmdtab.table.column.driverEtaToStore': 'DRIVER ETA TO STORE',
  'ordersOperationalDashboardDomain.lmdtab.table.column.driverAtStore': 'DRIVER AT STORE',
  'ordersOperationalDashboardDomain.lmdtab.table.column.driverEtaToCustomer':
    'DRIVER ETA TO CUSTOMER',
  'ordersOperationalDashboardDomain.lmdtab.table.column.driverAtCustomer': 'DRIVER AT CUSTOMER',
  'ordersOperationalDashboardDomain.lmdtab.table.column.created': 'CREATED',
  'ordersOperationalDashboardDomain.lmdtab.table.column.date': 'DATE',
  'ordersOperationalDashboardDomain.lmdtab.table.tooltip':
    'Estimated delivery time is past the customer’s delivery window',
  'ordersOperationalDashboardDomain.lmdtab.table.searchButton': 'Search',
  'ordersOperationalDashboardDomain.lmdtab.table.clearButton': 'Clear',
  'ordersOperationalDashboardDomain.trackingPage.title': 'Order Tracking',
  'ordersOperationalDashboardDomain.trackingPage.menuItemTitle.orderId': 'Order# {orderId}',
  'ordersOperationalDashboardDomain.trackingPage.menuItemTitle.orderIdWithType':
    '{orderType} order #{orderId}',
  'ordersOperationalDashboardDomain.trackingPage.trackingTab': 'Order Tracking',
  'ordersOperationalDashboardDomain.trackingPage.detailsTab': 'Order Details',
  'ordersOperationalDashboardDomain.pickupPage.pickUpItemList.title': 'Item list',
  'ordersOperationalDashboardDomain.itemBreakdown.catalogViewItem': 'View in catalog',
  'ordersOperationalDashboardDomain.pickupPage.pickUpCouponList.title': 'Coupon breakdown',
  'ordersOperationalDashboardDomain.pickupPage.pickUpCouponList.noCouponApplied':
    'No coupons applied',
  'ordersOperationalDashboardDomain.pickupPage.pickUpCouponList.applied':
    '{count} {count, plural, one {coupon} other {coupons}} applied',
  'ordersOperationalDashboardDomain.pickupPage.pickupInformation.title': 'Pickup Information',
  'ordersOperationalDashboardDomain.pickupPage.pickupInformation.name': 'Name',
  'ordersOperationalDashboardDomain.pickupPage.pickupInformation.contactNumber': 'Contact Number',
  'ordersOperationalDashboardDomain.pickupPage.pickupInformation.address': 'Address',
  'ordersOperationalDashboardDomain.pickupPage.pickupInformation.paymentMethod': 'Payment Method',
  'ordersOperationalDashboardDomain.pickupPage.pickupInformation.payment': 'Payment',
  'ordersOperationalDashboardDomain.orderDetails.pickupInformation.pickupWindow': 'Pickup window',
  'ordersOperationalDashboardDomain.orderDetails.pickupInformation.deliveryWindow':
    'Delivery window',
  'ordersOperationalDashboardDomain.orderDetails.refundBanner.outOfStock': 'Item out of stock',
  'ordersOperationalDashboardDomain.orderDetails.refundBanner.returned':
    'Customer was credited for this item',

  'ordersOperationalDashboardDomain.orderDetails.pickupInformation.pickupWindow.timeString':
    '{startTime} to {endTime}',
  'ordersOperationalDashboardDomain.orderDetails.pickupInformation.pickupWindow.timeRangeString':
    '{timeRange}',
  'ordersOperationalDashboardDomain.pickupPage.orderBreakdown.title': 'Order breakdown',
  'ordersOperationalDashboardDomain.pickupPage.orderBreakdown.subTotal': 'Item subtotal',
  'ordersOperationalDashboardDomain.pickupPage.orderBreakdown.orderTotal': 'Order Total',
  'ordersOperationalDashboardDomain.pickupPage.orderBreakdown.grandTotal': 'Grand Total',
  'ordersOperationalDashboardDomain.pickupPage.orderBreakdown.taxAmount': 'Tax amount',
  'ordersOperationalDashboardDomain.pickupPage.orderBreakdown.refundTotal': 'Refund total',
  'ordersOperationalDashboardDomain.pickupPage.summary.title': 'Summary',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.costBeforeDiscounts':
    'Cost before discounts',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.costBeforeDiscountsItemTotal':
    ' item total',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.costBeforeDiscountsOrderTotal':
    ' order total',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.totalSaved':
    'Total saved ${totalSaved}',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.savedFromMembership':
    '${amount} from your Instacart+ membership.',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.savedFromDiscounts':
    '${amount} from discounts, coupons, promos, etc..',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.savedFromDiscountsGeneric':
    'Savings may come from deals, promos, or coupons',
  'ordersOperationalDashboardDomain.pickupPage.orderSummary.noSavings': 'No savings in this order',
  'ordersOperationalDashboardDomain.pickupPage.pickupInfo.title': 'Pickup info',
  'ordersOperationalDashboardDomain.pickupPage.deliveryInfo.title': 'Delivery info',
  'ordersOperationalDashboardDomain.pickupPage.pickupInfo.instructions': 'Instructions',
  'ordersOperationalDashboardDomain.pickupPage.pickupInfo.driverName': 'Driver',
  'ordersOperationalDashboardDomain.pickupPage.store.title': 'Store',
  'ordersOperationalDashboardDomain.pickupPage.store.locationCode': 'Location code',
  'ordersOperationalDashboardDomain.pickupPage.store.address': 'Address',
  'ordersOperationalDashboardDomain.pickupPage.store.shopper': 'Shopper',
  'ordersOperationalDashboardDomain.trackingPage.cancelOrder': 'Cancel Order',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.title': 'Order Location',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.title': 'Order information',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.notavailable': 'Not available',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.orderId': 'Order ID',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.locationCode': 'Location Code',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.fulfillment': 'Fulfillment',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.address': 'Address',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.payment': 'Payment',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.bagDetails.title': 'Bag details',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.bagDetails.bagCount': 'Bag count',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.bagDetails.itemCount':
    'Item count',
  'ordersOperationalDashboardDomain.orderDetails.coupon.reward': 'Reward',
  'ordersOperationalDashboardDomain.orderDetails.coupon.validDates': 'Valid',
  'ordersOperationalDashboardDomain.orderDetails.coupon.source': 'Source',
  'ordersOperationalDashboardDomain.orderDetails.coupon.appliedTo': 'Applied to',
  'ordersOperationalDashboardDomain.orderDetails.coupon.discountableType': 'Discount',
  'ordersOperationalDashboardDomain.orderDetails.discountInfo.discountableType.unspecified':
    'Unknown',
  'ordersOperationalDashboardDomain.orderDetails.discountInfo.discountableType.basketLevelOffer':
    'Basket-level',
  'ordersOperationalDashboardDomain.orderDetails.discountInfo.discountableType.itemLevelOffer':
    'Item-level',
  'ordersOperationalDashboardDomain.orderDetails.discountInfo.discountableType.customerFeeOffer':
    'Customer-fee',
  'ordersOperationalDashboardDomain.orderDetails.coupon.appliedToContent':
    '{count} {count, plural, one {item} other {items}}',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.bagDetails.itemWeight':
    'Item weight (in lbs.)',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.alcoholic':
    'This order includes alcohol',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.transactionInformation.title':
    'Transaction information',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.transactionInformation.initialTip':
    'Initial tip',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.transactionInformation.initialTipValue':
    '${initialTip}',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.transactionInformation.cartTotal':
    'Cart total',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.transactionInformation.cartTotalValue':
    '${cartTotal}',
  'ordersOperationalDashboardDomain.orderDetails.deliveryInformation.title': 'Delivery information',
  'ordersOperationalDashboardDomain.orderDetails.deliveryInformation.deliveryWindow':
    'Delivery window',
  'ordersOperationalDashboardDomain.orderDetails.deliveryInformation.deliveryWindow.timeString':
    '{startTime} to {endTime}',
  'ordersOperationalDashboardDomain.orderDetails.deliveryInformation.additionalDeliveryInformation.title':
    'Additional delivery information',
  'ordersOperationalDashboardDomain.orderDetails.deliveryInformation.additionalDeliveryInformation.specialInstruction':
    'Special instructions',
  'ordersOperationalDashboardDomain.orderDetails.deliveryInformation.additionalDeliveryInformation.leaveUnattended':
    'Leave unattended',
  'ordersOperationalDashboardDomain.orderDetails.lastmile.unavailable': 'Unavailable',
  'ordersOperationalDashboardDomain.orderDetails.customerDetails': 'Customer',
  'ordersOperationalDashboardDomain.orderDetails.paymentMethod.endingIn': 'Ending in {number}',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.title': 'Total Order Payment',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.authAmount': 'Auth Amount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.totalCharge': 'Total Charge',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.itemsPrice': 'Items Price',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.subtotal': 'Subtotal',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.discountedPrice': 'Discounted Price',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.tipAmount': 'Tip Amount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.tips': 'Tips',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.totalRefundedAmount':
    'Total Refunded Amount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.alcoholServiceFee':
    'Alcohol Service Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.bagsFee': 'Bags Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.bottleDeposit': 'Bottle Deposit',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.deliveryFee': 'Delivery Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.pickupFee': 'Pickup Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.freeDelivery': 'FREE',

  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.beverageContainerFee':
    'Beverage Container Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.fuelSurcharge': 'Fuel Surcharge',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.heavyDeliveryFee':
    'Heavy Delivery Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.heavyDeliveryFeeOffset':
    'Heavy Delivery Fee Offset',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.inStoreServiceFee':
    'In Store Service Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.longDistanceServiceFee':
    'Long Distance Service Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.priorityFee': 'Priority Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.serviceFee': 'Service Fee',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.alcoholExciseTax':
    'Alcohol Excise Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.bagsFeeTax': 'Bags Fee Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.bottleWaterTax': 'Bottle Water Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.deliveryFeeTax': 'Delivery Fee Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.pickupFeeTax': 'Pickup Fee Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.fuelSurchargeTax':
    'Fuel Surcharge Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.goodsAndServicesTax':
    'Good And Services Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.harmonizedSalesTax':
    'Harmonized Sales Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.healthyTax': 'Healthy Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.liquorTax': 'Liquor Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.markupSalesTax': 'Markup Sales Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.priorityFeeTax': 'Priority Fee Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.privateLabelDiscountSalesTax':
    'Private Label Discount Sales Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.provincialSalesTax':
    'Provincial Sales Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.quebecSalesTax': 'Quebec Sales Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.retailerSalesTax':
    'Retailer Sales Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.salesTaxTotal': 'Sales Tax Total',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.serviceFeeTax': 'Service Fee Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.sugarTax': 'Sugar Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.valueAddedTax': 'Value Added Tax',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.cpgDeliveryPromotionDiscount':
    'CPG Delivery Promotion Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.creditDiscount': 'Credit Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.dealsDiscount': 'Deals Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.loyaltyDiscount': 'Loyalty Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.privateLabelBroadcastDiscount':
    'Private Label Broadcast Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.privateLabelDiscount':
    'Private Label Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.retailerCouponDiscount':
    'Retailer Coupon Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.retailerDiscount':
    'Retailer Discount',
  'ordersOperationalDashboardDomain.orderDetails.orderAmounts.total': 'Total',
  'ordersOperationalDashboardDomain.orderDetails.orderList.title': 'Order List',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.items': 'Items',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.replacements': 'Replacements',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.refunds': 'Refunds',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.products': 'Product',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.description': 'Description',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.unit': 'Unit/Qty',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.price': 'Price',
  'ordersOperationalDashboardDomain.orderDetails.orderList.headers.subTotal': 'Sub Total',
  'ordersOperationalDashboardDomain.orderDetails.orderList.replacements': 'Replacements',
  'ordersOperationalDashboardDomain.orderDetails.orderList.refunded': 'Refunded',
  'ordersOperationalDashboardDomain.orderDetails.orderList.quantity': 'Qty: {quantity}',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.trackingDetails':
    'Tracking details',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.customerInfo':
    'Customer information',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.shopperInfo':
    'Shopper information',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.estPickupTime':
    'Est. Pickup Time',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.estDeliveryTime':
    'Est. Delivery Time',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.orderStatus': 'Order Status',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.name': 'Name',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.contactNumber': 'Contact number',
  'ordersOperationalDashboardDomain.orderDetails.orderInformation.email': 'Email',
  'ordersOperationalDashboardDomain.orderTracking.map.scheduledForDelivery':
    'Scheduled for delivery',
  'ordersOperationalDashboardDomain.orderTracking.map.outForDelivery': 'Delivery en route',
  'ordersOperationalDashboardDomain.orderTracking.map.delivered': 'Delivered',
  'ordersOperationalDashboardDomain.orderTracking.map.accuracySubtext':
    'Note: This map is updated every 3-5 mins',
  'ordersOperationalDashboardDomain.reschedule.title': 'Reschedule Order',
  'ordersOperationalDashboardDomain.reschedule.rescheduleOrderNumber':
    'Reschedule Order # {orderId}',
  'ordersOperationalDashboardDomain.reschedule.customerInformation': 'Customer Information',
  'ordersOperationalDashboardDomain.reschedule.firstName': 'First name',
  'ordersOperationalDashboardDomain.reschedule.lastName': 'Last name',
  'ordersOperationalDashboardDomain.reschedule.phoneNumber': 'Phone number',
  'ordersOperationalDashboardDomain.reschedule.deliveryAddress': 'Delivery Address',
  'ordersOperationalDashboardDomain.reschedule.streetName': 'Street name',
  'ordersOperationalDashboardDomain.reschedule.includeBuildingNumber': 'include bulding number',
  'ordersOperationalDashboardDomain.reschedule.inLbs': 'in lbs',
  'ordersOperationalDashboardDomain.reschedule.unitNumber': 'Unit number',
  'ordersOperationalDashboardDomain.reschedule.city': 'City',
  'ordersOperationalDashboardDomain.reschedule.zipCode': 'Zip code',
  'ordersOperationalDashboardDomain.reschedule.orderInformation': 'Order Information',
  'ordersOperationalDashboardDomain.reschedule.locationCode': 'Location code',
  'ordersOperationalDashboardDomain.reschedule.bagDetails': 'Bag details',
  'ordersOperationalDashboardDomain.reschedule.bagCount': 'Bag count',
  'ordersOperationalDashboardDomain.reschedule.itemCount': 'Item count',
  'ordersOperationalDashboardDomain.reschedule.itemWeight': 'Item weight',
  'ordersOperationalDashboardDomain.reschedule.includeAlcohol': 'This order includes Alcohol',
  'ordersOperationalDashboardDomain.reschedule.transactionInformation': 'Transaction Information',
  'ordersOperationalDashboardDomain.reschedule.initialTip': 'Initial tip',
  'ordersOperationalDashboardDomain.reschedule.cartTotal': 'Cart total',
  'ordersOperationalDashboardDomain.reschedule.deliveryInformation': 'Delivery Information',
  'ordersOperationalDashboardDomain.reschedule.serviceOptionId': 'I have a Service Option ID',
  'ordersOperationalDashboardDomain.reschedule.serviceOptionId.label': 'Service Option ID',
  'ordersOperationalDashboardDomain.reschedule.deliveryWindowStart': 'Delivery window start',
  'ordersOperationalDashboardDomain.reschedule.deliveryWindowEnd': 'Delivery window end',
  'ordersOperationalDashboardDomain.reschedule.fulfillmentStartDate': 'Fulfillment Date Start',
  'ordersOperationalDashboardDomain.reschedule.fulfillmentEndDate': 'Fulfillment Date End',
  'ordersOperationalDashboardDomain.reschedule.deliveryTimeStart': 'Delivery Time Start',
  'ordersOperationalDashboardDomain.reschedule.deliveryTimeEnd': 'Delivery Time End',
  'ordersOperationalDashboardDomain.reschedule.notAvailable': 'Not available',
  'ordersOperationalDashboardDomain.reschedule.cancelButton': 'Cancel Order',
  'ordersOperationalDashboardDomain.reschedule.rescheduleButton': 'Reschedule Order',
  'ordersOperationalDashboardDomain.reschedule.cancelOrderError':
    'There is an error when cancelling order',
  'ordersOperationalDashboardDomain.reschedule.rescheduleOrderError':
    'There is an error when rescheduling order',
  'ordersOperationalDashboardDomain.reschedule.cancel': 'Cancel',
  'ordersOperationalDashboardDomain.reschedule.reschedule': 'Reschedule',
  'ordersOperationalDashboardDomain.reschedule.orderRescheduled': 'Order rescheduled',
  'ordersOperationalDashboardDomain.reschedule.orderCancelled': 'Order canceled',
  'ordersOperationalDashboardDomain.orderTracking.rescheduleButton': 'Reschedule',
  'ordersOperationalDashboardDomain.trackingPage.rescheduleOrder': 'Reschedule',
  'ordersOperationalDashboardDomain.orderTracking.orderBeingDelivered':
    'This order can no longer be modified. If any changes are needed, please reach out to customer care at retailsupport@instacart.com for instructions.',
  'ordersOperationalDashboardDomain.createOrder.invalidServiceOptionId':
    'Failed to create order: invalid service option Id',
  'ordersOperationalDashboardDomain.createOrder.unsupportedLocale':
    'Failed to create order: unsupported locale',
  'ordersOperationalDashboardDomain.createOrder.invalidStartOrEndAt':
    'Failed to create order: invalid start or end at',
  'ordersOperationalDashboardDomain.orderTracking.map.nonlmd.orderStatus.new': 'New',
  'ordersOperationalDashboardDomain.orderTracking.map.nonlmd.orderStatus.processing': 'Processing',
  'ordersOperationalDashboardDomain.orderTracking.map.nonlmd.orderStatus.shopping': 'Shopping',
  'ordersOperationalDashboardDomain.orderTracking.map.nonlmd.orderStatus.delivering': 'Delivering',
  'ordersOperationalDashboardDomain.orderTracking.map.nonlmd.orderStatus.readyForPickup':
    'Ready for Pickup',
  'ordersOperationalDashboardDomain.orderTracking.map.nonlmd.orderStatus.complete': 'Complete',
  'ordersOperationalDashboardDomain.orderTracking.map.nonlmd.orderStatus.canceled': 'Canceled',
  'ordersOperationalDashboardDomain.orderTracking.map.lmd.orderStatus.orderPlaced': 'Order Placed',
  'ordersOperationalDashboardDomain.orderTracking.map.lmd.orderStatus.waitForDelivery':
    'Waiting for delivery',
  'ordersOperationalDashboardDomain.orderTracking.map.lmd.orderStatus.orderEnRoute':
    'Order EnRoute',
  'ordersOperationalDashboardDomain.orderTracking.map.lmd.orderStatus.orderComplete':
    'Order Complete',
  'ordersOperationalDashboardDomain.orderTracking.map.lmd.orderStatus.delayed': 'Order Delayed',
  'ordersOperationalDashboardDomain.orderTracking.map.lmd.orderStatus.canceled': 'Order Canceled',
  'ordersOperationalDashboardDomain.searchPage.multiUsersModal.title': 'Select User',
  'ordersOperationalDashboardDomain.searchPage.multiUsersModal.cancel': 'Cancel',
  'ordersOperationalDashboardDomain.searchPage.multiUsersModal.confirm': 'Confirm',
  'ordersOperationalDashboardDomain.searchPage.multiUsersModal.unavailable': 'Unavailable',
  'ordersOperationalDashboardDomain.orderDetails.statusBar.label': 'Status',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.pickupTitle':
    'Pickup details',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.returnDetails.returnTitle':
    'Return details',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.deliveryTitle':
    'Delivery details',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.pickupWindow':
    'Pickup window:',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.deliveryWindow':
    'Delivery window:',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.arrived':
    'Arrived at',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.eta': 'ETA:',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.deliveredTime':
    '(Delivered in {time})',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.dueTime':
    '(Due in {time})',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.late': '(Late)',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.deliveryDetails.dueNow':
    '(Due now)',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.storeDetails.title':
    'From store',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.storeDetails.returnTitle':
    'To store',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.storeDetails.pickupTitle':
    'At store',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.customerDetails.title':
    'To customer',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.delivery': 'Delivery',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.return': 'Return',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.returnDetails.title':
    'Return details',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.returnDetails.returnBy':
    'Return by:',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.returnDetails.accept':
    '({time} to accept)',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.returnDetails.completed':
    'Return completed at',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.info.returnDetails.waiting':
    'Awaiting return',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.orderStatus.cancelled':
    'Order canceled',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.orderStatus.delayed':
    'Delayed in transit',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.orderStatus.failedDelivery':
    'Failed delivery',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.orderStatus.partialDelivery':
    'Partial delivery',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.timezoneLabel':
    'Time zone: {timezone}',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.collapseStates': 'Collapse Details',
  'ordersOperationalDashboardDomain.orderDetails.orderTimeline.expandStates': 'Show Details',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.deliveryPhoto.alt':
    'Picture of delivery location',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.deliveryPhoto.inProgress':
    'Delivery in progress',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.deliveryPhoto.expired':
    'Photo expired (7 days)',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.deliveryPhoto.noPhoto':
    'No photo uploaded',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.deliveryPhoto.attended':
    'Attended delivery no photo',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.modal.locationTitle':
    'Order Location',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.modal.photoTitle':
    'Delivery Confirmation Photo',
  'ordersOperationalDashboardDomain.orderDetails.orderLocationCard.modal.close': 'Close',
  'ordersOperationalDashboardDomain.dashboard.filter.date.title': 'Date or date range',
  'ordersOperationalDashboardDomain.dashboard.filter.fulfillmentMethod.title': 'Fulfillment method',
  'ordersOperationalDashboardDomain.dashboard.filter.status.title': 'Status',
  'ordersOperationalDashboardDomain.dashboard.filter.location.title': 'Locations',
  'ordersOperationalDashboardDomain.dashboard.filter.fulfillmentMethod.type.pickup': 'Pickup',
  'ordersOperationalDashboardDomain.dashboard.filter.fulfillmentMethod.type.delivery': 'Delivery',
  'ordersOperationalDashboardDomain.dashboard.filter.fulfillmentMethod.type.lastMileDelivery':
    'Last Mile Delivery',
  'ordersOperationalDashboardDomain.dashboard.tab.name': 'Dashboard',
  'ordersOperationalDashboardDomain.dashboard.table.column.orderId': 'Order ID',
  'ordersOperationalDashboardDomain.dashboard.table.column.status': 'Status',
  'ordersOperationalDashboardDomain.dashboard.table.column.name': 'Name',
  'ordersOperationalDashboardDomain.dashboard.table.column.orderDate': 'Order date',
  'ordersOperationalDashboardDomain.dashboard.table.column.fulfillmentDate': 'Fulfillment date',
  'ordersOperationalDashboardDomain.dashboard.table.column.store': 'Store',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.title': 'Order summary',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.status': 'Status',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.leaveUnattended':
    'Leave unattended',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.alcohol': 'Alcohol',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.orderPlaced': 'Order placed',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.bagCountLabel': 'Bag count',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.bagsCount': '{bagsCount} bags',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.bagsCountSingular': '1 bag',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.bagLabel': 'Bag label',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.itemCount': 'Item count',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.itemWeightLabel': 'Item weight',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.itemsWeight': '{itemsWeight} lbs',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.instacartPlus': 'Instacart+',
  'ordersOperationalDashboardDomain.orderDetails.orderSummaryCard.instacartPlusApplied': 'Applied',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.title': 'Communication',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.subtitle':
    'Expires after 7 days',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.viewMessages': 'View messages',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.shopperChat': 'Shopper Chat',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.modal.title': 'Messages',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.modal.customer': 'Customer:',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.modal.shopper': 'Driver:',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.modal.photoExpandedTitle':
    'Photo',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.modal.messagePhotoAlt':
    'Chat message photo',
  'ordersOperationalDashboardDomain.orderDetails.orderChatLogsCard.modal.photoExpandedAlt':
    'Expanded chat message photo',
  'ordersOperationalDashboardDomain.modifyOrder.button': 'Modify order',
  'ordersOperationalDashboardDomain.modifyOrder.modal.title': 'Modify Order',
  'ordersOperationalDashboardDomain.modifyOrder.modal.backButton': 'Back',
  'ordersOperationalDashboardDomain.modifyOrder.modal.nextButton': 'Next',
  'ordersOperationalDashboardDomain.modifyOrder.modal.modificationSelectLabel':
    'Select a modification type*',
  'ordersOperationalDashboardDomain.modifyOrder.modal.cancel': 'Cancel Order',
  'ordersOperationalDashboardDomain.modifyOrder.modal.reschedule': 'Reschedule Order',
  'ordersOperationalDashboardDomain.modifyOrder.modal.reasonSelectLabel': 'Select a reason*',
  'ordersOperationalDashboardDomain.modifyOrder.modal.otherReasonLabel': 'Additional notes',
  'ordersOperationalDashboardDomain.modifyOrder.modal.confirm':
    'Confirm the below details before proceeding:',
  'ordersOperationalDashboardDomain.modifyOrder.modal.confirm.reason':
    'Order will be canceled due to: "{reason}"',
  'ordersOperationalDashboardDomain.modifyOrder.modal.confirm.fee':
    'The customer wil be charged a {feeAmount} cancellation fee',
  'ordersOperationalDashboardDomain.modifyOrder.modal.confirm.cancelButton': 'Cancel order',
  'ordersOperationalDashboardDomain.modifyOrder.modal.confirm.rescheduleButton': 'Reschedule order',
  'ordersOperationalDashboardDomain.modifyOrder.orderCancelledSuccessfully':
    'Order cancelled successfully',
  'ordersOperationalDashboardDomain.dashboardPage.title': 'Orders',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.title': 'Search for an order',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.subtitle':
    'Across all locations and all time.',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchType.orderId': 'Order ID',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchType.email': 'Email',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchType.phoneNumber':
    'Phone number',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchType.customerName':
    'Customer name',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchTypePlaceholder.orderId':
    'Search by Order ID',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchTypePlaceholder.email':
    'Search by email',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchTypePlaceholder.phoneNumber':
    'Search by phone number',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchTypePlaceholder.customerName':
    'Search by customer name',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.addFilter': 'Add filter',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.orderSourcePlaceholder':
    'All order sources',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.orderStatusPlaceholder':
    'All order statuses',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.retailerLocationPlaceholder':
    'All store locations',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.fulfillmentTypePlaceholder':
    'All fulfillment types',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchByFilterTitle':
    'Recent orders',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.searchByFilterLastUpdated':
    'Last updated: {lastUpdatedAt}',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.resetFilters': 'Reset',
  'ordersOperationalDashboardDomain.dashboardPage.filterSection.submitSearch': 'Search',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderId.header':
    'Order ID',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderType.header':
    'Fulfillment type',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderStatus.header':
    'Status',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.customerName.header':
    'Name',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.store.header': 'Store',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderDate.header':
    'Order date',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.fulfillmentDate.header':
    'Fulfillment date',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.status.header':
    'Status',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.totalCharge.header':
    'Total charge',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderSource.header':
    'Order source',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderSource.cell.delivery':
    'Delivery',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderSource.cell.pickup':
    'Pickup',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.column.orderSource.cell.lmd':
    'Last Mile Delivery',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.orderStatus.inProgress':
    'In progress',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.orderStatus.orderPlaced':
    'Order placed',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.orderStatus.enRoute':
    'En route',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.orderStatus.waitingForDelivery':
    'Waiting for delivery',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.orderStatus.completed':
    'Completed',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.orderStatus.canceled':
    'Canceled',
  'ordersOperationalDashboardDomain.dashboardPage.searchResultsTable.orderStatus.unknown': '-',
} as const

export default EN_US
