import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { HideMarketplace, ViewType } from '@retailer-platform/dashboard/gin-and-tonic'
import { DomainRoute } from '../utils/domain/routingComponents'
import { onboardingSiteSetupAccessControl } from '../access-control/onboardingAccess.configuration'

const OnboardingSiteSetupPage = lazy(() => import('../pages/site-setup/OnboardingSiteSetupPage'))
// @hygen:inject page-import

export const onboardingStoreConfigScopedRouter = (
  <DomainRoute route="onboarding-site-scoped">
    <DashSwitch>
      <DomainRoute
        exact
        route="onboarding-site-setup"
        component={OnboardingSiteSetupPage}
        accessControl={onboardingSiteSetupAccessControl}
        scopePicker={{
          titles: ['onboardingDomain.siteSetup.title'],
          selectionType: ViewType.RETAILER_AND_STORE_CONFIG,
          storeConfigFilter: HideMarketplace,
        }}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
