import { type ReactNode, type FunctionComponent, useState } from 'react'
import styled from '@emotion/styled'
import { RemoveIcon } from '@instacart/ids-core'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { FocusTrap, TextInput } from '@mantine/core'
import { StoreIcon } from '@instacart/ids-core'
import { ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import { type Option } from './utils'
import { StyledLogoBox, StyledLogoImage } from './Common'

type Props = {
  selectedState: Record<string, Option>
  setSelectedState: (selectedState: Record<string, Option>) => void
  multiSelect: boolean
  selectionType: ViewType
  scopeSelectionOptional?: boolean
  onClear: () => void
  defaultToAll?: boolean
  setTempSelectedState: (selectedState: Record<string, Option>) => void
  searchFilter: string
  setSearchFilter: (searchFilter: string) => void
  isOpen: boolean
}

const Container = styled.div({
  gap: '8px',
  display: 'flex',
  alignItems: 'center',
  height: '40px',
})

const ImageItem = styled.div({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
})

const TextItem = styled.div({
  paddingLeft: '8px',
  display: 'flex',
  alignItems: 'center',
  userSelect: 'none',
})

const PickerLabel: FunctionComponent<Props> = ({
  selectedState,
  setSelectedState,
  multiSelect,
  selectionType,
  scopeSelectionOptional,
  onClear,
  defaultToAll,
  setTempSelectedState,
  searchFilter,
  setSearchFilter,
  isOpen,
}) => {
  let label: ReactNode
  if (multiSelect) {
    const selectedLeafNodes = Object.values(selectedState).filter(e => e.leafNode)
    if (selectionType === ViewType.RETAILER) {
      if (selectedLeafNodes.length === 0 && defaultToAll) {
        label = <TextItem>All banners</TextItem>
      } else if (selectedLeafNodes.length === 0 && !defaultToAll) {
        label = <TextItem>Select banner</TextItem>
      } else {
        label = <TextItem>Banners ({selectedLeafNodes.length})</TextItem>
      }
    } else if (selectionType === ViewType.STORE_CONFIG) {
      if (selectedLeafNodes.length === 0 && defaultToAll) {
        label = <TextItem>All sites</TextItem>
      } else if (selectedLeafNodes.length === 0 && !defaultToAll) {
        label = <TextItem>Select site</TextItem>
      } else {
        label = <TextItem>Sites ({selectedLeafNodes.length})</TextItem>
      }
    } else {
      if (selectedLeafNodes.length === 0 && defaultToAll) {
        label = <TextItem>All sites & banners</TextItem>
      } else if (selectedLeafNodes.length === 0 && !defaultToAll) {
        label = <TextItem>Select site & banner</TextItem>
      } else {
        label = <TextItem>Sites & banners ({selectedLeafNodes.length})</TextItem>
      }
    }
  } else {
    const option = Object.values(selectedState)[0]
    if (selectionType === ViewType.RETAILER) {
      if (option) {
        label = (
          <ImageItem>
            {option.logoUrl && (
              <StyledLogoBox>
                <StyledLogoImage src={option.logoUrl} alt="logo" />
              </StyledLogoBox>
            )}
            {option.type == 'banner' && !option.logoUrl && (
              <StyledLogoBox>
                <StoreIcon size={30} color="systemGrayscale30" />
              </StyledLogoBox>
            )}
            {option.name}
          </ImageItem>
        )
      } else {
        label = <TextItem>Select banner</TextItem>
      }
    } else if (selectionType === ViewType.STORE_CONFIG) {
      if (option) {
        label = <TextItem>{option.name}</TextItem>
      } else {
        label = <TextItem>Select site</TextItem>
      }
    } else {
      if (option) {
        const siteOption = option.breadcrumbs[0]
        const bannerOption = option
        const name = [siteOption?.shortName || '', bannerOption.name].join(' > ')
        label = (
          <ImageItem>
            {bannerOption.logoUrl && (
              <StyledLogoBox>
                <StyledLogoImage src={option.logoUrl} alt="logo" />
              </StyledLogoBox>
            )}
            {bannerOption.type == 'banner' && !bannerOption.logoUrl && (
              <StyledLogoBox>
                <StoreIcon size={30} color="systemGrayscale30" />
              </StyledLogoBox>
            )}
            {name}
          </ImageItem>
        )
      } else {
        label = <TextItem>Select site & banner</TextItem>
      }
    }
  }

  return (
    <Container>
      {!isOpen && <>{label}</>}
      {isOpen && (
        <TextItem
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <FocusTrap active={true}>
            <TextInput
              style={{ width: '100%' }}
              placeholder="Search" // TODO
              variant="unstyled"
              onChange={e => {
                setSearchFilter(e.target.value)
              }}
            />
          </FocusTrap>
        </TextItem>
      )}

      {!isOpen && Object.values(selectedState).length > 0 && scopeSelectionOptional ? (
        <RemoveIcon
          onClick={e => {
            e.stopPropagation()
            setTempSelectedState({})
            setSelectedState({})
            onClear()
          }}
          size={16}
        />
      ) : null}
    </Container>
  )
}

export default PickerLabel
