import type { PropsWithChildren, FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useRetailerId, useStoreConfigId } from '../../../utils/routing/params.hooks'
import {
  type FilterSitesFn,
  type SiteOperationIntent,
} from '../retailer-and-site-picker/RetailerAndSitePicker.types'
import { ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import MultiScopePicker from './MultiScopePicker'

export interface MultiScopePickerWrapperProps {
  selectionType?: ViewType
  multiSelect?: boolean
  scopeSelectionOptional?: boolean
  storeConfigOperationIntent?: SiteOperationIntent
  storeConfigFilter?: FilterSitesFn
}

const MultiScopePickerWrapper: FunctionComponent<
  PropsWithChildren<MultiScopePickerWrapperProps>
> = ({
  selectionType = ViewType.RETAILER_AND_STORE_CONFIG,
  multiSelect = false,
  scopeSelectionOptional,
  storeConfigOperationIntent,
  storeConfigFilter,
}) => {
  const retailerId = useRetailerId()
  const storeConfigId = useStoreConfigId({ throwOnMissing: false })
  const history = useHistory()
  const [selectedRetailerId, setSelectedRetailerId] = useState<string>(null)
  const [selectedStoreConfigId, setSelectedStoreConfigId] = useState<string>(null)

  const retailerNeedsSelection =
    (retailerId === '0' || retailerId === 'undefined') &&
    (selectionType == ViewType.RETAILER || selectionType == ViewType.RETAILER_AND_STORE_CONFIG) &&
    !scopeSelectionOptional
  const storeConfigNeedsSelection =
    (storeConfigId === '0' || storeConfigId == 'undefined' || !storeConfigId) &&
    (selectionType == ViewType.STORE_CONFIG ||
      selectionType == ViewType.RETAILER_AND_STORE_CONFIG) &&
    !scopeSelectionOptional

  const openByDefault = retailerNeedsSelection || storeConfigNeedsSelection

  const selected: { retailerId?: string; siteId?: string } = {}

  if (retailerId) {
    selected.retailerId = retailerId
  }
  if (storeConfigId) {
    selected.siteId = storeConfigId
  }

  const onSelected = (selectedOptions: { retailerId?: string; siteId?: string }[]) => {
    if (selectedOptions.length === 1) {
      if (selectionType == ViewType.RETAILER) {
        setSelectedRetailerId(selectedOptions[0].retailerId)
      } else if (selectionType == ViewType.STORE_CONFIG) {
        setSelectedStoreConfigId(selectedOptions[0].siteId)
      } else if (selectionType == ViewType.RETAILER_AND_STORE_CONFIG) {
        setSelectedRetailerId(selectedOptions[0].retailerId)
        setSelectedStoreConfigId(selectedOptions[0].siteId)
      }
    } else {
      setSelectedRetailerId('0')
      setSelectedStoreConfigId('0')
    }
  }

  // Use useEffect when redirecting, since we don't want to redirect during the rendering phase.
  useEffect(() => {
    // unfortunately this very manual way of changing the URL is required. Normally we could use
    // useRouteMatch() with no arguments to get a raw URL such as /partner/:partner_id/warehouse/:warehouse/dashboard
    // and then generatePath(useRouteMatch(), {partner_id: 289, warehouse_id, 1000}}) to cleanly generate a new URL
    // This approach fails if the user doesn't have access to a parent route.
    // For example if we don't have access to /partner/:partner_id/warehouse/:warehouse/dashboard and we try to hit
    // /partner/:partner_id/warehouse/:warehouse/dashboard/search, then on our "No Access" Error page, useRouteMatch()
    // will return /partner/:partner_id/warehouse/:warehouse/dashboard even though the URL says /partner/289/warehouse/1000/dashboard/search
    if (
      selectionType == ViewType.RETAILER &&
      selectedRetailerId &&
      selectedRetailerId != retailerId
    ) {
      const newPath = location.pathname.replace(
        /\/warehouses\/(undefined|\d+)/,
        '/warehouses/' + selectedRetailerId
      )
      history.push(newPath)
    } else if (
      selectionType == ViewType.STORE_CONFIG &&
      selectedStoreConfigId &&
      selectedStoreConfigId != storeConfigId
    ) {
      let newPath = location.pathname.replace(/\/warehouses\/(undefined|\d+)/, '/warehouses/' + '0')
      newPath = newPath.replace(/\/sites\/(undefined|[0-9]+)/, '/sites/' + selectedStoreConfigId)
      history.push(newPath)
    } else if (
      selectionType == ViewType.RETAILER_AND_STORE_CONFIG &&
      ((selectedRetailerId && selectedRetailerId != retailerId) ||
        (selectedStoreConfigId && selectedStoreConfigId != storeConfigId))
    ) {
      let newPath = location.pathname.replace(
        /\/warehouses\/(undefined|\d+)/,
        '/warehouses/' + selectedRetailerId
      )
      newPath = newPath.replace(/\/sites\/(undefined|[0-9]+)/, '/sites/' + selectedStoreConfigId)
      history.push(newPath)
    }
  })

  return (
    <MultiScopePicker
      selectedOptions={[selected]}
      selectionType={selectionType}
      multiSelect={multiSelect}
      scopeSelectionOptional={scopeSelectionOptional}
      openByDefault={openByDefault}
      storeConfigOperationIntent={storeConfigOperationIntent}
      storeConfigFilter={storeConfigFilter}
      onSelected={onSelected}
    />
  )
}

export default MultiScopePickerWrapper
