export enum Metrics {
  SUM_GMV = 'SUM_GMV',
  COUNT_ORDER = 'COUNT_ORDER',
  COUNT_FIRST_ORDER = 'COUNT_FIRST_ORDER',
  AVG_BASKET = 'AVG_BASKET',
  AVG_RATING = 'AVG_RATING',
  AVG_ORDERED_ITEM = 'AVG_ORDERED_ITEM',
  AVG_ITEM_FOUND = 'AVG_ITEM_FOUND',
  SEARCH_CONVERSION = 'SEARCH_CONVERSION',
  AVG_ITEM_FILLED = 'AVG_ITEM_FILLED',
  IMAGE_COVERAGE = 'IMAGE_COVERAGE',
  RATIO_UNATA_GMV = 'RATIO_UNATA_GMV',
  RATIO_CONNECT_GMV = 'RATIO_CONNECT_GMV',
  RATIO_MARKETPLACE_GMV = 'RATIO_MARKETPLACE_GMV',
  RATIO_PBI_GMV = 'RATIO_PBI_GMV',
  RATIO_LMD_GMV = 'RATIO_LMD_GMV',
  RATIO_DELIVERY_GMV = 'RATIO_DELIVERY_GMV',
  RATIO_EBT_GMV = 'RATIO_EBT_GMV',
  RATIO_CONVENIENCE_GMV = 'RATIO_CONVENIENCE_GMV',
  RATIO_ALCOHOL_GMV = 'RATIO_ALCOHOL_GMV',
  RATIO_EXPRESS_GMV = 'RATIO_EXPRESS_GMV',
  RATIO_LOYALTY_GMV = 'RATIO_LOYALTY_GMV',
  RATIO_FIRST_ORDER_GMV = 'RATIO_FIRST_ORDER_GMV',
  RATIO_ORDER_WITH_RATING = 'RATIO_ORDER_WITH_RATING',
}

export enum Dimensions {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  ORDER_CURRENCY = 'ORDER_CURRENCY',
  PARTNER_GEO_DIVISION = 'PARTNER_GEO_DIVISION',
  REGION = 'REGION',
  WAREHOUSE = 'WAREHOUSE',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_CODE = 'WAREHOUSE_LOCATION_CODE',
  WAREHOUSE_LOCATION_GROUP = 'WAREHOUSE_LOCATION_GROUP',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  FULFILLMENT_TYPE = 'FULFILLMENT_TYPE',
  INSIGHTS_PORTAL_ORDER_SOURCE = 'INSIGHTS_PORTAL_ORDER_SOURCE',
  INSIGHTS_PORTAL_REPORTING_PLATFORM = 'INSIGHTS_PORTAL_REPORTING_PLATFORM',
}

export enum GroupBys {
  DATE_PT = 'DATE_PT',
  WEEK_SAT_PT = 'WEEK_SAT_PT',
  WEEK_SUN_PT = 'WEEK_SUN_PT',
  WEEK_MON_PT = 'WEEK_MON_PT',
  WEEK_TUE_PT = 'WEEK_TUE_PT',
  WEEK_WED_PT = 'WEEK_WED_PT',
  WEEK_THU_PT = 'WEEK_THU_PT',
  WEEK_FRI_PT = 'WEEK_FRI_PT',
  MONTH_PT = 'MONTH_PT',
  WAREHOUSE_LOCATION = 'WAREHOUSE_LOCATION',
  WAREHOUSE_LOCATION_STATE = 'WAREHOUSE_LOCATION_STATE',
  PARTNER_GEO_DIVISION = 'PARTNER_GEO_DIVISION',
  WAREHOUSE_LOCATION_GROUP = 'WAREHOUSE_LOCATION_GROUP',
  REGION = 'REGION',
  FULFILLMENT_TYPE = 'FULFILLMENT_TYPE',
  INSIGHTS_PORTAL_ORDER_SOURCE = 'INSIGHTS_PORTAL_ORDER_SOURCE',
  INSIGHTS_PORTAL_REPORTING_PLATFORM = 'INSIGHTS_PORTAL_REPORTING_PLATFORM',
}
