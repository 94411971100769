import { useMemo } from 'react'
import { type RowInfo } from 'react-table'
import { StoreGroupsFormLocationSelectorToggle } from '../../StoreGroupsFormLocationSelectorToggle'
import { type StoreLocationDetails } from '../../../utils/locations.hooks'
import { LocationSelectorActions } from '../../../utils/locationSelector.types'
import { useDashMessages } from '../../../../../../../../../../intl/intl.hooks'

interface FormLocationSelectorRow extends RowInfo {
  original: StoreLocationDetails
}

interface Props {
  isLocationSelected: (id: string) => boolean
  handleToggleLocation: (id: string) => void
  showActionButtons?: boolean
}

export const useStoreGroupFormLocationTableColumns = ({
  isLocationSelected,
  handleToggleLocation,
  showActionButtons,
}: Props) => {
  const columnMessages = useDashMessages({
    code: 'settings.storeGroups.form.table.columns.code',
    storeName: 'settings.storeGroups.form.table.columns.storeName',
    banner: 'settings.storeGroups.form.table.columns.banner',
    address: 'settings.storeGroups.form.table.columns.address',
    city: 'settings.storeGroups.form.table.columns.city',
    state: 'settings.storeGroups.form.table.columns.state',
    zipCode: 'settings.storeGroups.form.table.columns.zipCode',
  })

  const columns = useMemo(
    () => [
      {
        header: columnMessages.code,
        width: 80,
        accessor: 'locationCode',
      },
      {
        header: columnMessages.storeName,
        accessor: 'name',
      },
      {
        header: columnMessages.banner,
        accessor: 'retailerName',
      },
      {
        header: columnMessages.address,
        accessor: 'street',
      },
      {
        header: columnMessages.city,
        width: 110,
        accessor: 'city',
      },
      {
        header: columnMessages.state,
        width: 60,
        accessor: 'state',
      },
      {
        header: columnMessages.zipCode,
        width: 80,
        accessor: 'zipCode',
      },
      {
        Header: null,
        width: 110,
        show: showActionButtons,
        Cell: ({ original: { id } }: FormLocationSelectorRow) => {
          const isSelected = isLocationSelected(id)

          const actionType = isSelected
            ? LocationSelectorActions.Remove
            : LocationSelectorActions.Add

          const onClick = () => handleToggleLocation(id)

          return <StoreGroupsFormLocationSelectorToggle onClick={onClick} actionType={actionType} />
        },
      },
    ],
    [handleToggleLocation, isLocationSelected, showActionButtons, columnMessages]
  )

  return columns
}
