// @ts-nocheck legacy file transformed to TS
import type { ReactNode } from 'react'

import { Component } from 'react'
import styled from '@emotion/styled'
import { Text, colors } from 'ic-snacks'
import { errors } from '../../utils/error-handling/errors'

const Centered = styled.div`
  margin: 40px;
  text-align: center;
`

class ErrorBoundary extends Component<{ pathname: string; children: ReactNode }> {
  constructor(props) {
    super(props)
    this.state = { hasError: false, pathname: props.pathname }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true })

    errors.captureException(error, `ErrorBoundary with error + ${error}`, {
      extra: {
        isErrorBoundary: true,
      },
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.pathname !== nextProps.pathname) {
      return { hasError: false, pathname: nextProps.pathname }
    }

    return null
  }

  render() {
    if (this.state.hasError) {
      return (
        <Centered>
          <Text variant="T.14" style={{ color: colors.RED_500 }}>
            Something went wrong. Please try again later.
          </Text>
        </Centered>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
