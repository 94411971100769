import React, { type FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { PageHeader } from '@retailer-platform/shared-components'
import { useCurrentAccountDetails } from '@retailer-platform/dashboard/utils'
import { type DashRouteComponentProps } from '@retailer-platform/dashboard/routing'
import {
  DocumentTitle,
  LoadingPage,
  HeaderPortal,
} from '@retailer-platform/dashboard/gin-and-tonic'
import { TDSStylesProvider, Text } from '@retailer-platform/shared-components/src/tds'
import { NotificationList } from '../../components/notifications-list/NotificationsList'
import { useDomainMessages } from '../../utils/domain/intl'

interface Props
  extends DashRouteComponentProps<{
    /*
    Add here any properties that you expect to come from the route. For instance,

    id: string
  */
  }> {}

const NotificationListContainer = styled.div({
  padding: '1em',
})

const PlatformNotificationsListPage: FunctionComponent<React.PropsWithChildren<Props>> = () => {
  const { account, loading } = useCurrentAccountDetails()
  const i18n = useDomainMessages({
    title: 'notificationsDomain.list.platformNotifications.pageTitle',
    desc: 'notificationsDomain.list.platformNotifications.pageDescription',
  })

  if (loading) return <LoadingPage />

  return (
    <TDSStylesProvider>
      <div data-testid="notifications-page">
        <DocumentTitle titleId="notificationsDomain.list.bannerNotifications.pageTitle" />
        <HeaderPortal
          headerComponent={
            <PageHeader
              removeAllPadding
              title={<Text typography="title">{i18n.title}</Text>}
              subTitle={i18n.desc}
            />
          }
        />
        <NotificationListContainer>
          <NotificationList accountId={account.id} />
        </NotificationListContainer>
      </div>
    </TDSStylesProvider>
  )
}

export default PlatformNotificationsListPage
