import type { PropsWithChildren, FunctionComponent } from 'react'
import { useEffect, Fragment, useState } from 'react'
import { IconButton } from '@retailer-platform/shared-components/src/tds'
import { TrashIcon } from '@instacart/ids-core'
import { injectIntl } from 'react-intl'
import { type InjectedDashIntlProps } from '../../../../../../intl/intl.types'
import { useDeleteStoreGroup } from '../../../graphql/delete.hooks'
import { useNotifications } from '../../../../../common/utils/notifications/notifications.hooks'
import { type StoreGroup } from '../../../../../common/graphql-types/retailerLocationGroup.types'
import { StoreGroupsListConfirmDeleteModal } from './StoreGroupsListConfirmDeleteModal'

interface Props extends InjectedDashIntlProps {
  storeGroup: StoreGroup
}

const _StoreGroupsListTableDeleteButton: FunctionComponent<PropsWithChildren<Props>> = ({
  storeGroup: { id: storeGroupId, name: storeGroupName, retailerLocationIds },
  intl,
}) => {
  const { deleteStoreGroup, loading, success } = useDeleteStoreGroup()
  const { notify } = useNotifications()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const onClick = () => setShowConfirmationModal(true)
  const onConfirm = () => deleteStoreGroup({ storeGroupId })

  // Hide confirmation modal & notify after successful deletion
  useEffect(() => {
    if (!success) return
    notify(
      intl.formatMessage(
        { id: 'settings.storeGroups.notifications.deleteSuccess' },
        { storeGroupName }
      )
    )
    setShowConfirmationModal(false)
  }, [intl, notify, setShowConfirmationModal, storeGroupName, success])

  return (
    <Fragment>
      {showConfirmationModal && (
        <StoreGroupsListConfirmDeleteModal
          storeGroupName={storeGroupName}
          retailerLocationCount={retailerLocationIds.length}
          onClose={() => {
            setShowConfirmationModal(false)
          }}
          onConfirm={onConfirm}
        />
      )}
      <IconButton
        variant="tertiary"
        disabled={loading}
        icon={TrashIcon}
        onClick={onClick}
        data-testid="store-group-delete-button"
      />
    </Fragment>
  )
}

export const StoreGroupsListTableDeleteButton = injectIntl(_StoreGroupsListTableDeleteButton)
