export enum FeatureToggle {
  retailerFundedMarketing = 'rt_retailer_funded_marketing',
  retailerFundedMarketingCampaign = 'rt_retailer_funded_marketing_campaign',
  retailerFundedMarketingPhase4 = 'rt_retailer_funded_marketing_phase4',
  retailerFundedMarketingPercentageOff = 'rt_retailer_funded_marketing_percentage_off',
  growBasketSizeTemplate = 'rt_rfm_grow_basket_sizes_template',
  icmsNewAndChurnedCustomersTemplate = 'rt_rfm_icms_new_and_churned_customers_template',
  alcoholBasedOfferOption = 'rt_rfm_alcohol_based_offer_option',
  alcoholBasedOfferOptionTemp = 'rt_rfm_alcohol_based_offer_option_temp',
  InsightsCampaignAnalytics = 'rt_insights_campaign_insights',
  InsightsCampaignInsightsDisabledRetailers = 'rt_insights_campaign_insights_disabled_retailers',
  campaignsV2Enabled = 'rt_campaigns_v2_enabled',
  campaignsV2TemplateAwarenessEnabled = 'rt_campaigns_v2_template_awareness_enabled',
  campaignsV2TemplateEmailEnabled = 'rt_campaigns_v2_template_email_enabled',
  campaignsV2TemplateSearchEnabled = 'rt_campaigns_v2_template_search_enabled',
  campaignsV2TemplateGrowBasketSizes = 'rt_campaigns_v2_template_grow_basket_sizes',
  campaignsV2TemplateCrmOfflineEnabled = 'rt_campaigns_v2_template_crm_offline_enabled',
  campaignsV2TemplateCrmOfflineNonIncentiveEnabled = 'rt_campaigns_v2_template_crm_offline_non_incentive_enabled',
  campaignsV2TemplateLifecycleEnabled = 'rt_campaigns_v2_template_lifecycle_enabled',
  crmCampaignManualOverrides = 'rt_crm_campaign_manual_overrides',
  campaignV2TemplateMomentEnabled = 'rt_campaigns_v2_template_moment_enabled',
  retailerFundedMarketingUserDenyList = 'rt_rfm_user_deny_list',
  campaignsV2TemplateAcquisition = 'rt_campaigns_v2_template_acquisition',
  campaignsV2TemplateAudienceTargeting = 'rt_campaigns_v2_template_audience_targeting',
  campaignsCrmPushEnabled = 'rt_campaigns_crm_push_enabled',
  segmentationTargetingEmailCSVEnabled = 'rt_campaign_targeting_email_csv',
  offersFreeDeliveryPickupEnabled = 'rt_offers_free_delivery_pickup',
  campaignsDxGyEnabled = 'rt_campaigns_dxgy_integration',
  updateTargetingRules = 'campaign_management_allow_update_targeting_rules',
  enableGlobalSegmentSelectionForRetailerSpecific = 'rt_enable_global_segment_selection_for_retailer_specific_campaigns',
  enableDefineCustomSegment = 'rt_enable_campaign_targeting_rule_builder',
  draftCampaigns = 'rt_draft_campaigns',
  showMarketplaceCampaignsForCostco = 'rt_show_marketplace_campaigns_for_costco',
  rouletteModuleEnabled = 'rt_campaigns_roulette_module_enabled',
  showPredefinedSegmentsTargetingOption = 'rt_campaign_targeting_tooling_show_predefined_segments',
  ruleBuilderEnableUserEmailTargeting = 'rt_targeting_rule_builder_enable_user_email',
  ruleBuilderEnableLoyaltyTargeting = 'rt_targeting_rule_builder_enable_loyalty',
  ruleBuilderDisableLoyaltyTargeting = 'rt_targeting_rule_builder_disable_loyalty',
  ruleBuilderEnableInstacartRules = 'rt_targeting_rule_builder_enable_instacart_rules',
  useDynamicCsvUploadBucket = 'rt_use_dynamic_csv_upload_bucket',
  useDynamicCsvUploadBucketRetailer = 'rt_use_dynamic_csv_upload_bucket_retailer',
  enableEditStoreGroup = 'rt_enable_segment_edit_store_group',
  ruleBuilderFeatureRegistryEnabled = 'rt_rule_builder_feature_registry_enabled',
  ruleBuilderMultiConjunctionSegmentsEnabled = 'rt_rule_builder_multi_conjunction_segments_enabled',
  checkoutBenefitCodeEnabled = 'rt_checkout_benefit_code',
  autoApplyBasketOffersEnabled = 'rt_auto_apply_basket_offers',
  retailerDepartmentOfferEnabled = 'rt_retailer_department_offer_enabled',
  searchAllSlotTypesEnabled = 'rt_search_all_slot_types_enabled',
  promoteStorefrontCreativesEnabled = 'rt_promote_storefront_creatives_enabled',
  multiSitesPromoteStorefrontCreativesEnabled = 'rt_multi_sites_promote_module',
  autoApplyFeeDiscountEnabled = 'rt_auto_apply_fee_discount_enabled',
  additionalOrderConditionEnabled = 'rt_additional_order_condition_enabled',
  termsAndConditionsOfferRedemptionLimitEnabled = 'rt_terms_and_conditions_offer_redemption_limit_enabled',
  vgpCreateCouponCodeEnabled = 'rt_vgp_create_coupon_code_enabled',
  offerCreditBackRewardTypeEnabled = 'rt_offer_credit_back_reward_type_enabled',
  checkoutBenefitCodeDiscountAsApplicationMethodEnabled = 'rt_checkout_benefit_code_discount_as_application_method_enabled',
  offerSetPriceWeightedItemEnabled = 'rt_offer_set_price_weighted_item_enabled',
  // Scalable Targeting Tooling rollout. Will be short lived
  useFeatureRegistryStoreGroups = 'rt_rule_builder_feature_registry_store_groups_enabled',
  useFeatureRegistryLoyaltyStatus = 'rt_rule_builder_feature_registry_loyalty_enabled',
  useFeatureRegstryEmailCSV = 'rt_rule_builder_feature_registry_email_csv_enabled',
  useFeatureRegistryRetailerOrders = 'rt_rule_builder_feature_registry_retailer_orders_enabled',
  useFeatureRegistryInstacartOrders = 'rt_rule_builder_feature_registry_instacart_orders_enabled',
  dayOfWeekSchedule = 'rt_campaign_schedule_day_of_week_tooling',
  nonIncentiveCampaignsEnabled = 'rt_campaigns_mp_non_incentives_enabled',
  wholesalerCampaignsEnabled = 'rt_campaigns_wholesalers_enabled',
  announcementBannerCampaignsEnabled = 'rt_announcement_banner_campaigns_enabled',
  storefrontCreativeReusability = 'rt_storefront_creative_reusability',
  retailerCustomCampaignEnabled = 'rt_retailer_custom_campaign_enabled',
  retailerScopeMultiSelectEnabled = 'rt_retailer_scope_multi_select_enabled',
  dsPropertiesEnabled = 'rt_campaigns_ds_properties_enabled',
  campaignDashboardPagination = 'rt_campaign_dashboard_pagination_sprouts',
  editableTemplateVariablesEnabled = 'rt_editable_template_variables_enabled',
  autoRedeemApplicationMethodEnabled = 'rt_auto_redeem_application_method_enabled',
  brandExclusionCriteriaInOffers = 'rt_brand_exclusion_criteria_in_offers',
  showAdminTemplatesPage = 'rt_campaigns_show_admin_templates_page',
  growthCampaignsIPPSlotSelection = 'growth_campaigns_ipp_slot_selection',
}
