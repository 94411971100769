import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  NavItem,
  AdminNavEntryPoint,
  NavEntryPoint,
  AdminNavEntryPointV2,
} from '@retailer-platform/dashboard/routing'
import { accountManagementRouter } from './routing/accountManagementRouter'
import { accountManagementAdminRouter } from './routing/accountManagementAdminRouter'
import { accountManagementPartnerRouter } from './routing/accountManagementPartnerRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { accountManagementAdminAccessControl } from './access-control/accountManagementAdminAccess.configuration'
import { accountManagementAccessControl } from './access-control/accountManagementAccess.configuration'
import { accountManagementPartnerAccessControl } from './access-control/accountManagementPartnerAccess.configuration'
import { viewRetailerSecurityAccessControl } from './access-control/multiFactorAuthAccess.configuration'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'account-management',
  totem: {
    entity: 'account-management-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [accountManagementRouter],
    admin: [accountManagementAdminRouter],
    partnerScoped: [accountManagementPartnerRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Admin,
      attachToV2: NavEntryPoint.UserMenuUserManagement,
      subNavItems: [
        {
          type: 'item',
          route: 'retailer-security',
          labelId: 'accountManagementDomain.nav.security.title',
          accessControl: viewRetailerSecurityAccessControl,
          positionNavV2: 4,
        },
      ],
    },
    {
      attachTo: NavItem.Admin,
      attachToV2: NavEntryPoint.UserMenuUserManagement,
      subNavItems: [
        {
          type: 'item',
          route: 'partner-account-management-list',
          labelId: 'accountManagementDomain.nav.title',
          accessControl: accountManagementPartnerAccessControl,
          positionNavV2: 0,
        },
      ],
    },
  ],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.Account,
      attachToV2: AdminNavEntryPointV2.UserMenu,
      subNavItems: [
        {
          route: 'app-admin-account-management-list',
          labelId: 'accountManagementDomain.nav.title',
          accessControl: accountManagementAdminAccessControl,
          positionNavV2: 0,
        },
      ],
    },
  ],
}

export default definition
