import type { PropsWithChildren, FunctionComponent } from 'react'
import { useMemo } from 'react'
import styled from '@emotion/styled'
import { type ApolloError } from '@apollo/client'
import { type FetchPartnersQuery } from '../../../__codegen__/api'
import { useDashMessages } from '../../../intl/intl.hooks'
import { PartnerOption } from './PartnerOption'
import { LinkText } from './SelectPartner.styles'

export interface Props {
  filter: string
  setPartnerId: (id: string) => void
  onPartnerRemoveFromRecents: (id: string) => void
  onRemoveAllRecents: () => void
  recentlyVisitedPartnerData: FetchPartnersQuery
  recentlyVisitedPartnerError: ApolloError
}

const Header = styled.div({ display: 'flex' })

const HeaderTitle = styled.div({
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '20px',
})

export const RecentlyVisitedList: FunctionComponent<PropsWithChildren<Props>> = ({
  filter,
  setPartnerId,
  onPartnerRemoveFromRecents,
  onRemoveAllRecents,
  recentlyVisitedPartnerData,
  recentlyVisitedPartnerError,
}) => {
  const messages = useDashMessages({
    recentlyVisited: 'selectPartner.recentlyVisited.recentlyVisited',
    clearAll: 'selectPartner.recentlyVisited.clearAll',
  })

  const partnersChunk = useMemo(() => {
    const partners = recentlyVisitedPartnerData?.partners?.filter(partner =>
      partner.name.match(new RegExp(filter, 'i'))
    )

    if (recentlyVisitedPartnerError) {
      return [
        <div key="0" css={{ textAlign: 'center' }}>
          Error Loading Partners
        </div>,
      ]
    }

    if (!partners) {
      return []
    }

    return partners.map(partner => (
      <div
        data-testid={'recently-visited-partner-id-' + partner.id}
        key={partner.id}
        onClick={evt => {
          setPartnerId(partner.id)
        }}
      >
        <PartnerOption partner={partner} onPartnerRemoveFromRecents={onPartnerRemoveFromRecents} />
      </div>
    ))
  }, [
    recentlyVisitedPartnerData?.partners,
    filter,
    onPartnerRemoveFromRecents,
    setPartnerId,
    recentlyVisitedPartnerError,
  ])

  return (
    <div>
      <Header>
        <HeaderTitle>{messages.recentlyVisited}</HeaderTitle>
        <LinkText onClick={onRemoveAllRecents}>{messages.clearAll}</LinkText>
      </Header>
      {partnersChunk}
    </div>
  )
}
