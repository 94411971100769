import { type AnalyticsBrowser } from '@segment/analytics-next'
import { errors } from '../../../error-handling/errors'
import clientEnv from '../../../../utils/global/clientEnv'
import { eventTrackingEnabled } from './eventDebugging'
import { type GlobalAnalytics } from './segmentAnalytics.types'

const noop = () => {
  // noop
}
export const noopSegmentAnalytics = {
  alias: noop,
  group: noop,
  identify: noop,
  page: noop,
  track: noop,
}

export const segmentAnalytics = (): AnalyticsBrowser | typeof noopSegmentAnalytics => {
  const globalWithAnalytics = (global as GlobalAnalytics).analytics

  if (!eventTrackingEnabled()) {
    return noopSegmentAnalytics
  }

  // If the analytics module is not enabled, then we we should error and return early
  if (!globalWithAnalytics) {
    // Skip logging this in local/development environments. Cypress runs a production build
    // of the app, but doesn't define the necessary env vars for analytic calls to be made
    if (
      clientEnv.PUBLIC_CLIENT_ENVIRONMENT != null &&
      !['local', 'development'].includes(clientEnv.PUBLIC_CLIENT_ENVIRONMENT)
    ) {
      const errorMessage =
        'Segment `analytics` module unavailable - script was missing or failed to load'
      console.warn(errorMessage)
      errors.captureMessage(errorMessage, { level: 'error' })
    }
    return noopSegmentAnalytics
  }

  return globalWithAnalytics
}

export const SEGMENT_OPTIONS = {}
