import { omit } from 'lodash'
import { createSchemaConfig, buildTimeIntervalDimensionsConfig } from '../helpers/schema.helpers'
import { TimeIntervalDimensions } from '../types/groupBy.types'
import {
  InsightsFormatterType,
  InsightsGroupByTypes,
  InsightsMetricDesiredDirection,
} from '../types/schema.types'
import { Metrics, Dimensions } from './pickupWarehouseLocation.types'

export { Metrics, Dimensions }

export const schemaConfig = createSchemaConfig({
  metrics: Metrics,
  dimensions: Dimensions,
  defaultDateDimension: Dimensions.INSIGHTS_PORTAL_DATE_PT,
  metricsConfig: {
    [Metrics.SECONDS_PER_ITEM]: {
      formatter: InsightsFormatterType.Decimal,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.SECONDS_PER_ITEM',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.SECONDS_PER_ITEM',
      desiredDirection: InsightsMetricDesiredDirection.NEGATIVE,
    },
    [Metrics.LATE_STAGE_PCT]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.LATE_STAGE_PCT',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.LATE_STAGE_PCT',
      desiredDirection: InsightsMetricDesiredDirection.NEGATIVE,
    },
    [Metrics.AVG_ORDER_RATING]: {
      formatter: InsightsFormatterType.Decimal,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.AVG_ORDER_RATING',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.AVG_ORDER_RATING',
    },

    [Metrics.UTILIZATION_PCT]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.UTILIZATION_PCT',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.UTILIZATION_PCT',
    },
    [Metrics.WAITING_TIME_P75_WAIT_TIME]: {
      formatter: (val: string | number) => {
        if (!val) return '-'
        const minutes = Math.floor(Number(val) / 60)
        const seconds = Math.floor(Number(val) % 60)
        return `${minutes}:${seconds.toString().padStart(2, '0')}`
      },
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.P75_WAIT_TIME',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.P75_WAIT_TIME',
      desiredDirection: InsightsMetricDesiredDirection.NEGATIVE,
    },
    [Metrics.NUM_BATCHES]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_BATCHES',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_BATCHES',
    },
    [Metrics.NUM_BATCHES_ACCEPTED]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_BATCHES_ACCEPTED',
    },
    [Metrics.BATCH_ACCEPTANCE_PCT]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.BATCH_ACCEPTANCE_PCT',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.BATCH_ACCEPTANCE_PCT',
    },
    [Metrics.NUM_COMPLETED_PARTNER_PICK]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_COMPLETED_PARTNER_PICK',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_COMPLETED_PARTNER_PICK',
    },
    [Metrics.NUM_DELIVERY_BATCHES]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_DELIVERY_BATCHES',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_DELIVERY_BATCHES',
    },
    [Metrics.NUM_DELIVERY_BATCHES_ACCEPTED]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_DELIVERY_BATCHES_ACCEPTED',
      tooltip:
        'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_DELIVERY_BATCHES_ACCEPTED',
    },
    [Metrics.DELIVERY_BATCH_ACCEPTANCE_PCT]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.DELIVERY_BATCH_ACCEPTANCE_PCT',
      tooltip:
        'insightsPortalDomain.pickupWarehouseLocation.tooltips.DELIVERY_BATCH_ACCEPTANCE_PCT',
    },
    [Metrics.NUM_COMPLETED_PARTNER_PICK_DELIVERY]: {
      formatter: InsightsFormatterType.Integer,
      label:
        'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_COMPLETED_PARTNER_PICK_DELIVERY',
      tooltip:
        'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_COMPLETED_PARTNER_PICK_DELIVERY',
    },
    [Metrics.NUM_PICKUP_BATCHES]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_PICKUP_BATCHES',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_PICKUP_BATCHES',
    },
    [Metrics.NUM_PICKUP_BATCHES_ACCEPTED]: {
      formatter: InsightsFormatterType.Integer,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_PICKUP_BATCHES_ACCEPTED',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_PICKUP_BATCHES_ACCEPTED',
    },
    [Metrics.PICKUP_BATCH_ACCEPTANCE_PCT]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.PICKUP_BATCH_ACCEPTANCE_PCT',
      tooltip: 'insightsPortalDomain.pickupWarehouseLocation.tooltips.PICKUP_BATCH_ACCEPTANCE_PCT',
    },
    [Metrics.NUM_COMPLETED_PARTNER_PICK_PICKUP]: {
      formatter: InsightsFormatterType.Integer,
      label:
        'insightsPortalDomain.pickupWarehouseLocation.metrics.NUM_COMPLETED_PARTNER_PICK_PICKUP',
      tooltip:
        'insightsPortalDomain.pickupWarehouseLocation.tooltips.NUM_COMPLETED_PARTNER_PICK_PICKUP',
    },
    [Metrics.FIVE_HOUR_AVAILABILITY_PCT]: {
      formatter: InsightsFormatterType.Percentage,
      label: 'insightsPortalDomain.pickupWarehouseLocation.metrics.FIVE_HOUR_AVAILABILITY_PCT',
      private: true,
    },
  },
  dimensionsConfig: {
    [Dimensions.INSIGHTS_PORTAL_DATE_PT]: {
      type: InsightsGroupByTypes.TimeInterval,
      label: 'insightsPortalDomain.pillTabs.days.label',
      formatter: InsightsFormatterType.Date,
      date: true,
    },
    [Dimensions.WAREHOUSE_LOCATION]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.pickupWarehouseLocation.dimensions.WAREHOUSE_LOCATION',
    },
    [Dimensions.WAREHOUSE_LOCATION_GROUP]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.pickupWarehouseLocation.dimensions.WAREHOUSE_LOCATION_GROUP',
    },
    [Dimensions.PARTNER]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.pickupWarehouseLocation.dimensions.PARTNER',
    },
    [Dimensions.WAREHOUSE]: {
      type: InsightsGroupByTypes.Info,
      label: 'insightsPortalDomain.pickupWarehouseLocation.dimensions.WAREHOUSE',
    },
    ...omit(buildTimeIntervalDimensionsConfig, [TimeIntervalDimensions.DATE_PT]),
  },
})
