import { ApolloClient, InMemoryCache, defaultDataIdFromObject } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import isObjectLike from 'lodash/isObjectLike'
import clientEnv from '../../utils/global/clientEnv'
import possibleTypes from '../../__codegen__/possibleTypes.json'
import { authLink, errorLink } from './clientUtilities'
import errorsTransformLink from './errorsTransformLink'
import { defaultClientOptions } from './clientOptions'
import { createOperationNameLink } from './operationNameLink'

let retailersClient: ApolloClient<{}> = null
let retailerPlatformMeshClient: ApolloClient<{}> = null

const dataIdFromObject = object => {
  if (isObjectLike(object.id)) {
    // Apollo MemoryCache assumes id is a simple primitive,
    // so types with id property being objects breaks the cache system.
    // We just stringify the whole object to generate a unique id from the body.
    // Initially added to support the instacart_ads_platform_taas_v1_SegmentState type.
    return JSON.stringify(object.id)
  }
  return defaultDataIdFromObject(object)
}

localStorage.removeItem('useApolloClient')

export const fetchRetailersClient = () => {
  if (!retailersClient) {
    const retailersHttpLink = new BatchHttpLink({
      uri: `${clientEnv.PUBLIC_CLIENT_RPP_URL}/graphql`,
      batchMax: 1,
    })

    const operationNameLink = createOperationNameLink({
      uri: `${clientEnv.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL}/graphql`,
    })

    retailersClient = new ApolloClient({
      name: 'RetailersApolloClient',
      link: authLink
        .concat(errorLink)
        .concat(operationNameLink)
        .concat(errorsTransformLink)
        .concat(retailersHttpLink),
      cache: new InMemoryCache(),
      defaultOptions: defaultClientOptions,
    })
  }
  return retailersClient
}

// Use this for feature specific to graphql-mesh
export const fetchRetailerPlatformMeshClient = () => {
  if (!retailerPlatformMeshClient) {
    const retailerPlatformMeshHttpLink = new BatchHttpLink({
      uri: `${clientEnv.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL}/graphql`,
      batchMax: 1,
    })

    const operationNameLink = createOperationNameLink({
      uri: `${clientEnv.PUBLIC_CLIENT_RETAILER_PLATFORM_MESH_URL}/graphql`,
    })

    retailerPlatformMeshClient = new ApolloClient({
      name: 'RetailerPlatformMeshApolloClient',
      link: authLink
        .concat(errorLink)
        .concat(operationNameLink)
        .concat(errorsTransformLink)
        .concat(retailerPlatformMeshHttpLink),
      cache: new InMemoryCache({
        possibleTypes,
        dataIdFromObject,
      }),
      defaultOptions: defaultClientOptions,
    })
  }
  return retailerPlatformMeshClient
}

/*
 * Use this for exiting dashboard-client use-case to transparently switch between
 * old dashboard-client and the new graphql-mesh client
 */
export const fetchDashboardClient = () => fetchRetailerPlatformMeshClient()
