import type { PropsWithChildren, FC } from 'react'
import { useState } from 'react'
import { useCheckLaunchOnboardingProgress } from '@retailer-platform/domain-onboarding'
import clientEnv from '../../../../../utils/global/clientEnv'
import { useCurrentAccountContext } from '../../../../../utils/current-account/CurrentAccountContext'
import { usePartnerContext } from '../../../../../utils/contexts/partner/PartnerContext.hooks'
import { useAdminControls } from '../../../../../utils/contexts/admin-controls/AdminControlsContext'
import { Permission } from '../../../../../legacy/common/types'
import { type NavMenuHierarchy, useUserSettingsHierarchy } from '../../NavMenuHierarchy'
import { useNavContext } from '../../../nav/utils/NavContext'
import { PartnerType } from '../../../../../exports/utils'
import { useTrackEvent } from '../../../../../utils/events/useTrackEvent.hook'
import { type AccessControlConfig } from '../../../../../legacy/components/AccessControl/accessControl.utils'
import { AdminControlModal } from '../../../admin-controls-modal/AdminControlsModal'
import { CollapsibleOption } from './CollapsibleOption'
import { Option } from './Option'
import { UserMenu } from './UserMenu'
import { Divider, TitleText } from './UserSettingsMenu.common'
import { SubOption } from './SubOption'

type Props = {
  setShowPartnerSelector: (value: boolean) => void
  setCurrentlySelectedItem: (value: number) => void
  setShowManageAccount: (value: boolean) => void
  navMenuHierarchy: NavMenuHierarchy
}

const InternalAdminAccessControl = {
  permissions: Permission.AdminPanel,
}

type MenuEntry = {
  title: string
  onClick?: () => void
  route?: string
  accessControl?: AccessControlConfig
}

export const UserSettingsMenuRetailer: FC<PropsWithChildren<Props>> = ({
  setShowPartnerSelector,
  setCurrentlySelectedItem,
  setShowManageAccount,
}) => {
  const [currentlyOpenSubMenu, setCurrentlyOpenSubMenu] = useState(-1)
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const context = useCurrentAccountContext()
  const partnerContext = usePartnerContext()
  const { hasInProgressLaunchOnboarding } = useCheckLaunchOnboardingProgress()
  const { setNavV2Enabled, allowNavV1RouletteEnabled, setMostRecentClickedRoute } = useNavContext()
  const { navMenuHierarchy: userSettingsHierarchy } = useUserSettingsHierarchy(setShowManageAccount)

  const showSettings = partnerContext.partnerType !== PartnerType.IDP

  const onClose = () => {
    setIsOpen(false)
    setMostRecentClickedRoute(null)
  }

  const docsUrl =
    partnerContext.partnerType === PartnerType.IDP
      ? 'https://docs.instacart.com/developer_platform_api/'
      : clientEnv.PUBLIC_CLIENT_PARTNER_DOCS_URL
  const termsUrl =
    partnerContext.partnerType === PartnerType.IDP
      ? 'https://docs.instacart.com/developer_platform_api/guide/terms_and_policies/developer_terms/'
      : 'https://docs.instacart.com/instacart/ipp-terms/'

  const trackEvent = useTrackEvent()

  return (
    <UserMenu
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setCurrentlySelectedItem={setCurrentlySelectedItem}
      title={partnerContext.partnerName}
      navMenuHierarchy={userSettingsHierarchy}
    >
      <AdminControlModal />
      <div>
        <TitleText padding={'8px 24px'}>{partnerContext.partnerName}</TitleText>
        <Option
          onClick={() => {
            setShowPartnerSelector(true)
            onClose()
          }}
          title="navV2.userSettings.switchPartner"
        />
        {hasInProgressLaunchOnboarding && (
          <Option
            onClick={onClose}
            route="onboarding-launch-checklist"
            title="onboarding.launchChecklist"
          />
        )}
        <Divider />
        {userSettingsHierarchy.hierarchy
          .filter(section => (showSettings || !section.isSettings) && !section.isUserActions)
          .map((section, index) => (
            <CollapsibleOption
              key={section.name}
              index={index}
              currentlyOpen={currentlyOpenSubMenu}
              setCurrentlyOpen={setCurrentlyOpenSubMenu}
              title={section.name}
              onClose={onClose}
            >
              {section.children?.map((child, idx) => (
                <SubOption
                  key={idx}
                  onClick={() => {
                    child.onClick?.()
                    onClose()
                  }}
                  title={child.name}
                  route={child.route}
                  position={child.positionNavV2}
                  accessControl={child.accessControl}
                />
              ))}
            </CollapsibleOption>
          ))}
        <CollapsibleOption
          index={userSettingsHierarchy.hierarchy.length}
          currentlyOpen={currentlyOpenSubMenu}
          setCurrentlyOpen={setCurrentlyOpenSubMenu}
          title="help"
          onClose={onClose}
        >
          <SubOption onClick={onClose} href={docsUrl} title="navV2.userSettings.documentation" />
          <SubOption onClick={onClose} href="https://instacart.com/press" title="press" />
          <SubOption onClick={onClose} href="https://instacart.com/privacy" title="privacy" />
          <SubOption
            onClick={onClose}
            href="https://instacart.safebase.us/"
            title="security_and_trust"
          />
          <SubOption onClick={onClose} href={termsUrl} title="terms" />
        </CollapsibleOption>

        <Divider />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            padding: '12px',
            paddingLeft: '27px',
          }}
        >
          <TitleText fontWeight={600} fontSize="14px" lineHeight="20px">
            {context.account.givenName}
          </TitleText>
          <TitleText fontWeight={400} fontSize="12px" lineHeight="16px">
            {context.account.email}
          </TitleText>
        </div>

        {userSettingsHierarchy.hierarchy
          .filter(section => section.isUserActions)
          .map(section =>
            section.children?.map((item, idx) => (
              <Option
                key={idx}
                onClick={() => {
                  item.onClick?.()
                  onClose()
                }}
                route={item.route}
                title={item.name}
                accessControl={item.accessControl}
              />
            ))
          )}

        {allowNavV1RouletteEnabled && (
          <Option
            onClick={() => {
              setNavV2Enabled(false)
              onClose()
              trackEvent({
                id: 'navV2.navigation.switchToNavV1',
                description: 'Tracks whenever the user switches back to NavV1',
                data: {
                  accountID: context.account.id,
                },
              })
            }}
            title="navV2.userSettings.switchToNavV1"
          />
        )}
      </div>
    </UserMenu>
  )
}
