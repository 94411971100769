import MultiScopePickerWrapper, {
  type MultiScopePickerWrapperProps,
} from '../multi-scope-picker-2/MultiScopePickerWrapper'
import {
  RetailerAndSitePickerWrapper,
  type RetailerScopeWrapperProps,
} from '../retailer-and-site-picker/RetailerAndSitePickerWrapper'
import { useCurrentAccountDetails } from '../../../api/current-account/currentAccount.hooks'

export interface RetailerScopeSelectorProps
  extends MultiScopePickerWrapperProps,
    RetailerScopeWrapperProps {}

/**
 * Wrap the V1 and V2 scope selector components in a single component
 * We render the V2 version of the selector if users have the 'rt_scope_picker_v2' feature flag
 */
export const RetailerScopeSelector = ({
  /* V1 and V2 props */
  selectionType,
  retailerSelectionOptional,
  storeConfigOperationIntent,
  storeConfigFilter,
  /* V1 only props */
  retailerInsufficientPermission,
  /* V2 only props */
  multiSelect,
}: RetailerScopeSelectorProps) => {
  const { account: currentAccount } = useCurrentAccountDetails()
  const scopePickerV2 = !!currentAccount?.featureToggles?.find(
    feature => feature.name === 'rt_scope_picker_v2'
  )

  return scopePickerV2 ? (
    <MultiScopePickerWrapper
      selectionType={selectionType}
      multiSelect={multiSelect}
      scopeSelectionOptional={retailerSelectionOptional}
      storeConfigOperationIntent={storeConfigOperationIntent}
      storeConfigFilter={storeConfigFilter}
    />
  ) : (
    <RetailerAndSitePickerWrapper
      selectionType={selectionType}
      retailerSelectionOptional={retailerSelectionOptional}
      storeConfigOperationIntent={storeConfigOperationIntent}
      storeConfigFilter={storeConfigFilter}
      retailerInsufficientPermission={retailerInsufficientPermission}
    />
  )
}
