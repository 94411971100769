import React, { useCallback } from 'react'
import { Button, type ButtonProps } from '../../tds'
import { useMessages } from '../../utils/intl/intl.hooks'
import { type TestID } from '../../utils/testing/types'
import { Modal, type ModalProps } from './Modal'

interface Props extends Omit<ModalProps, 'footerContent' | 'children'>, TestID {
  /** Callback when the detrimental button is clicked -- this also triggers the close callback  */
  onCancel?: () => void
  /** Label to use for the detrimental button -- can be JSX */
  cancelLabel?: React.ReactChild
  /** Callback when the primary button is clicked -- this also triggers the close callback  */
  onConfirm?: () => void
  /** Label to use for the primary button -- can be JSX */
  confirmLabel?: React.ReactChild
  confirmDisabled?: boolean
  /** type of the cancel button to use e.g detrimental will be red */
  cancelVariant?: ButtonProps['variant']
  /** type of the confirm button to use e.g detrimental will be red */
  confirmVariant?: ButtonProps['variant']
}

export const Confirm: React.FunctionComponent<React.PropsWithChildren<Props>> = ({
  onConfirm,
  onCancel,
  onRequestClose,
  confirmLabel,
  confirmDisabled,
  cancelLabel,
  cancelVariant = 'detrimental',
  confirmVariant = 'primary',
  ...props
}) => {
  const messages = useMessages({
    confirmLabel: 'sharedComponents.modal.confirm.confirmLabel',
    cancelLabel: 'sharedComponents.modal.confirm.cancelLabel',
  })

  const innerOnConfirm = useCallback(
    (evt: React.MouseEvent) => {
      onConfirm?.()
      return onRequestClose?.(evt)
    },
    [onConfirm, onRequestClose]
  )

  const innerOnCancel = useCallback(
    (evt: React.MouseEvent) => {
      onCancel?.()
      return onRequestClose?.(evt)
    },
    [onCancel, onRequestClose]
  )

  const baseTestId = `${props['data-testid'] || 'default'}-confirm`

  const footer = (
    <Button.Group>
      <Button
        data-testid={`${baseTestId}-cancel-button`}
        variant={cancelVariant}
        css={{ margin: 0, marginRight: 20 }}
        onClick={innerOnCancel}
      >
        {cancelLabel || messages.cancelLabel}
      </Button>

      <Button
        variant={confirmVariant}
        data-testid={`${baseTestId}-confirm-button`}
        css={{ margin: 0 }}
        onClick={innerOnConfirm}
        disabled={confirmDisabled}
      >
        {confirmLabel || messages.confirmLabel}
      </Button>
    </Button.Group>
  )
  return <Modal {...props} onRequestClose={innerOnCancel} footerContent={footer} />
}
