import { gql } from '@apollo/client'
import { useDashMutation } from '../../../apollo/clientHooks'
import { type StoreGroup } from '../../../common/graphql-types/retailerLocationGroup.types'

export const UPDATE_STORE_GROUP_MUTATION = gql`
  mutation updateStoreGroup($id: ID!, $name: String!, $retailerLocationIds: [ID!]) {
    retailerLocationGroupUpdate(id: $id, name: $name, retailerLocationIds: $retailerLocationIds) {
      retailerLocationGroup {
        id
        name
        updatedAt
        retailerLocationIds
      }
    }
  }
`

export interface UpdateStoreGroupMutationData {
  retailerLocationGroupUpdate: {
    retailerLocationGroup: StoreGroup
  }
}

export interface UpdateStoreGroupsMutationVariables {
  id: string
  name: string
  retailerLocationIds: string[]
}

interface UpdateProps {
  id: string
  name: string
  retailerLocationIds: string[]
}

export const useUpdateStoreGroup = () => {
  const [triggerUpdateStoreGroup, { data, error, loading }] = useDashMutation<
    UpdateStoreGroupMutationData,
    UpdateStoreGroupsMutationVariables
  >(UPDATE_STORE_GROUP_MUTATION)

  const updateStoreGroup = ({ id, name, retailerLocationIds }: UpdateProps) =>
    triggerUpdateStoreGroup({
      variables: {
        id,
        name,
        retailerLocationIds,
      },
    })

  const updatedStoreGroup = data && data.retailerLocationGroupUpdate.retailerLocationGroup

  return {
    updateStoreGroup,
    updatedStoreGroup,
    loading,
    error,
  }
}
