import React, { Fragment, type FunctionComponent, useCallback, useState } from 'react'
import { type GridColumns, GridList } from '@retailer-platform/shared-components'
import { Button, Checkbox } from '@retailer-platform/shared-components/src/tds'
import styled from '@emotion/styled'
import {
  type NotificationsNotificationType,
  useGetNotificationsListQuery,
  useSubscribeNotificationMutation,
  useUnsubscribeNotificationMutation,
} from '../../api'
import { filterNotificationList, updateLocalListCache } from '../../utils'
import NotificationFilterHeader from '../notifications-filter/NotificationFilterHeader'
import { useDomainAccessControl } from '../../utils/domain/accessControl'
import { Permission } from '../../access-control/permissions'
import { NotificationsModal } from '../notifications-modal/NotificationsModal'
import { useDomainMessage, useDomainMessages } from '../../utils/domain/intl'

interface NotificationListProps {
  retailerId?: string
  accountId: string
}

export enum EligibilityOptions {
  All = 'all',
  Eligible = 'eligible',
  Ineligible = 'ineligible',
}
export enum SubscriptionOptions {
  All = 'all',
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
}

const NotificationsFilterHeaderContainer = styled.div({
  padding: '10px',
})

const DescriptionCell = styled.div({
  whiteSpace: 'normal',
})

export const NotificationList: FunctionComponent<
  React.PropsWithChildren<NotificationListProps>
> = props => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [eligibleFilter, setEligibleFilter] = useState<null | EligibilityOptions>(null)
  const [subscribedFilter, setSubscribedFilter] = useState<null | SubscriptionOptions>(null)
  const [selectedNotificationType, setSelectedNotificationType] = useState<null | string>(null)
  const [selectedEventName, setSelectedEventName] = useState<null | string>(null)

  const { accountId, retailerId } = props

  const {
    data: listData,
    loading: listLoading,
    error: listError,
  } = useGetNotificationsListQuery({
    variables: { retailerId },
  })

  const [subscribeNotificationMutation] = useSubscribeNotificationMutation()
  const [unsubscribeNotificationMutation] = useUnsubscribeNotificationMutation()
  const hasAccess = useDomainAccessControl()
  const isAdmin = hasAccess({ permissions: Permission.NotificationsAdmin })

  const i18n = useDomainMessages({ noNotifications: 'notificationsDomain.list.noNotifications' })

  const filteredList = listData
    ? filterNotificationList({
        data: listData.notificationTypesForCurrentAccountOnRetailer,
        eligibilityOption: eligibleFilter ?? undefined,
        subscriptionOption: subscribedFilter ?? undefined,
      })
    : []
  const columns: GridColumns<NotificationsNotificationType> = [
    {
      id: 'event-name',
      Header: useDomainMessage('notificationsDomain.list.columnTitle.eventName'),
      Cell: ({ row }) => <div>{row.original.name}</div>,
    },
    {
      id: 'description',
      Header: useDomainMessage('notificationsDomain.list.columnTitle.description'),
      Cell: ({ row }) => <DescriptionCell>{row.original.description}</DescriptionCell>,
    },
    {
      id: 'subscribe',
      Header: useDomainMessage('notificationsDomain.list.columnTitle.subscribe'),
      Cell: ({
        row: {
          original: { isEligible, isSubscribed, id: notificationTypeId },
        },
      }) => {
        const handleSubscribe = useCallback(() => {
          subscribeNotificationMutation({
            variables: {
              accountId,
              notificationTypeId,
              retailerId,
            },
            optimisticResponse: {
              notificationSubscriptionCreate: {
                success: true,
                __typename: 'NotificationSubscriptionCreatePayload',
              },
            },
            update: (cache, { data }) => {
              updateLocalListCache({
                cache,
                data: data ?? undefined,
                retailerId,
                notificationTypeId,
                updatedValue: true,
              })
            },
          })
        }, [notificationTypeId])

        const handleUnsubscribe = useCallback(() => {
          unsubscribeNotificationMutation({
            variables: {
              accountId,
              notificationTypeId,
              retailerId,
            },
            optimisticResponse: {
              notificationSubscriptionDelete: {
                success: true,
                __typename: 'NotificationSubscriptionDeletePayload',
              },
            },
            update: (cache, { data }) => {
              updateLocalListCache({
                cache,
                data: data ?? undefined,
                retailerId,
                notificationTypeId,
                updatedValue: false,
              })
            },
          })
        }, [notificationTypeId])
        const message = useDomainMessage('notificationsDomain.modal.cell.ineligible')

        return !isEligible ? (
          <div>{message}</div>
        ) : (
          <Checkbox
            label=""
            checked={isSubscribed}
            onChange={() => (isSubscribed ? handleUnsubscribe() : handleSubscribe())}
          />
        )
      },
    },
    {
      id: 'manage-users',
      Header: useDomainMessage('notificationsDomain.list.columnTitle.manageUsers'),
      Cell: ({
        row: {
          original: { id, name },
        },
      }) => (
        <Button
          disabled={!isAdmin}
          onClick={() => {
            setSelectedEventName(name)
            setSelectedNotificationType(id)
            setShowModal(true)
          }}
        >
          {useDomainMessage('notificationsDomain.list.button.manageUsers')}
        </Button>
      ),
    },
  ]

  return (
    <>
      <NotificationsFilterHeaderContainer>
        <NotificationFilterHeader
          subscribedFilter={subscribedFilter ?? undefined}
          eligibleFilter={eligibleFilter ?? undefined}
          setSubscribedFilter={val => setSubscribedFilter(val ?? null)}
          setEligibleFilter={val => setEligibleFilter(val ?? null)}
        />
      </NotificationsFilterHeaderContainer>
      <GridList
        onLoadMore={() => {
          //
        }}
        data={filteredList}
        columns={columns}
        loadingMore={false}
        canLoadMore={false}
        height={700}
        isLoading={listLoading}
        errorMessage={listError?.message}
        emptyStateMessage={i18n.noNotifications}
      />
      {!!selectedNotificationType && !!selectedEventName && (
        <NotificationsModal
          eventName={selectedEventName}
          showModal={showModal}
          notificationTypeId={selectedNotificationType}
          setShowModal={setShowModal}
          {...props}
        />
      )}
    </>
  )
}
