import type { PropsWithChildren, FunctionComponent } from 'react'
import { useMemo, useState } from 'react'
// eslint-disable-next-line @retailer-platform/no-restricted-imports
import { Popover } from '@mantine/core'
import styled from '@emotion/styled'
import cloneDeep from 'lodash/cloneDeep'
import { type ViewType } from '../retailer-scope-wrapper/RetailerScopeWrapper'
import { Divider } from './Common'
import PickerLabel from './PickerLabel'
import { getKey, getLeafNodes } from './utils'
import { type Option } from './utils'
import MultiScopeDropdownOptionContainer from './MultiScopeDropdownOptionContainer'
import ApplyCancelComponent from './ApplyCancelComponent'
import { type PopoverConfig } from './Common'
import { PopoverTriggerDiv } from './PopoverTrigger'

const TriggerContainer = styled.div<{ width: string | number }>(({ width }) => ({
  margin: '10px 0px',
  width: width,
}))

export interface Props {
  options: Option[]
  multiSelect: boolean
  scopeSelectionOptional?: boolean
  openByDefault?: boolean
  onSingleSelect: (option: Option) => void
  onMultiSelect: (options: Option[]) => void
  selectionType: ViewType
  popoverConfig?: PopoverConfig
  defaultToAll?: boolean
}

const Container = styled.div({})

const OverflowContainer = styled.div<{ maxHeight: number }>(({ maxHeight }) => ({
  maxHeight: maxHeight,
  overflow: 'auto',
  padding: '8px 0px',
}))

const renderOptions = (
  options: Option[],
  multiSelect: boolean,
  selectedState: Record<string, Option>,
  setSelectedState: (state: Record<string, Option>) => void,
  onSingleSelect: (option: Option) => void,
  searchFilter: string
) =>
  options.map(option => (
    <div key={option.id}>
      <MultiScopeDropdownOptionContainer
        option={option}
        multiSelect={multiSelect}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        onSingleSelect={onSingleSelect}
        searchFilter={searchFilter}
      />
    </div>
  ))

const getFilteredOptions = (options: Option[], searchFilter: string) => {
  options.forEach(option => {
    if (!option.leafNode && option.children) {
      option.children = getFilteredOptions(option.children, searchFilter)
    }
  })

  const filteredOptions = options.filter(
    option =>
      option.children?.length > 0 || option.name.toLowerCase().includes(searchFilter.toLowerCase())
  )

  return filteredOptions
}

const MultiScopeDropdownComponent: FunctionComponent<PropsWithChildren<Props>> = ({
  options,
  multiSelect,
  scopeSelectionOptional,
  openByDefault,
  onSingleSelect,
  onMultiSelect,
  selectionType,
  popoverConfig,
  defaultToAll = true,
}) => {
  const [isOpen, setIsOpen] = useState(openByDefault)
  const [searchFilter, setSearchFilter] = useState('')

  const setIsOpenInternal = (isOpen: boolean) => {
    setIsOpen(isOpen)

    if (!isOpen) {
      // reset the search filter when the dropdown is closed
      setSearchFilter('')
    }
  }

  const filteredOptions = useMemo(
    () => getFilteredOptions(cloneDeep(options), searchFilter),
    [options, searchFilter]
  )

  const leafNodes = getLeafNodes(filteredOptions)
  const defaultSelectedState: Record<string, Option> = {}

  leafNodes.forEach(node => {
    if (node.selected) {
      defaultSelectedState[getKey(node)] = node
    }
  })

  const [selectedState, setSelectedState] = useState<Record<string, Option>>(defaultSelectedState)
  const [tempSelectedState, setTempSelectedState] =
    useState<Record<string, Option>>(defaultSelectedState)

  const onClickAccept = () => {
    setIsOpenInternal(false)
    setTempSelectedState(selectedState)
    if (Object.values(selectedState).length > 0) {
      onMultiSelect(Object.values(selectedState).filter(e => e.leafNode))
    } else if (defaultToAll) {
      // Only select all if defaultToAll is true
      // nothing has been selected, which impllies that all options should be selected
      const leafNodes = getLeafNodes(options)
      onMultiSelect(leafNodes)
    } else {
      onMultiSelect([]) // Otherwise return empty selection
    }
  }

  const onClear = () => {
    if (defaultToAll) {
      // Only select all if defaultToAll is true
      // nothing has been selected, which impllies that all options should be selected
      const leafNodes = getLeafNodes(options)
      onMultiSelect(leafNodes)
    } else {
      onMultiSelect([]) // Otherwise return empty selection
    }
  }

  const onClickCancel = () => {
    setSelectedState(tempSelectedState)
    setIsOpenInternal(false)
  }

  const handlePopoverChange = () => {
    if (isOpen) {
      if (multiSelect) {
        setSelectedState(tempSelectedState)
      }
      setIsOpenInternal(false)
    }
  }

  const onTriggerClick = () => {
    if (isOpen) {
      handlePopoverChange()
    } else {
      setIsOpenInternal(true)
    }
  }

  const onSingleSelectInternal = (option: Option) => {
    setIsOpenInternal(false)
    onSingleSelect(option)
  }

  const height = popoverConfig?.maxHeight ?? 350
  const position = popoverConfig?.position ?? 'bottom-start'
  const offset = popoverConfig?.offset ?? 4
  const width = popoverConfig?.width ?? 450
  const withinPortal = popoverConfig?.withinPortal ?? true
  const triggerWidth = popoverConfig?.triggerWidth ?? 'auto'
  return (
    <Popover
      opened={isOpen}
      onChange={handlePopoverChange}
      position={position}
      offset={offset}
      zIndex={100}
      withinPortal={withinPortal}
      width={width}
    >
      <Popover.Target>
        <TriggerContainer width={triggerWidth}>
          <PopoverTriggerDiv
            css={{
              padding: '6px 8px 6px 8px',
              gap: '8px',
              cursor: 'pointer',
            }}
            onClick={onTriggerClick}
            isOpen={isOpen}
            data-testid="multi-scope-picker-trigger"
            focusOutline={false}
          >
            <PickerLabel
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              multiSelect={multiSelect}
              onClear={onClear}
              selectionType={selectionType}
              scopeSelectionOptional={scopeSelectionOptional}
              defaultToAll={defaultToAll}
              setTempSelectedState={setTempSelectedState}
              isOpen={isOpen}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
            ></PickerLabel>
          </PopoverTriggerDiv>
        </TriggerContainer>
      </Popover.Target>

      <Popover.Dropdown style={{ padding: '0px' }}>
        <Container data-testid="multi-scope-dropdown-container">
          <OverflowContainer maxHeight={height}>
            {renderOptions(
              filteredOptions,
              multiSelect,
              selectedState,
              setSelectedState,
              onSingleSelectInternal,
              searchFilter
            )}
          </OverflowContainer>

          {multiSelect && (
            <>
              <Divider />
              <ApplyCancelComponent onClickAccept={onClickAccept} onClickCancel={onClickCancel} />
            </>
          )}
        </Container>
      </Popover.Dropdown>
    </Popover>
  )
}

export default MultiScopeDropdownComponent
