import styled from '@emotion/styled'
import React, { type ButtonHTMLAttributes } from 'react'
import { ChevronDownIcon } from '@instacart/ids-core'
import { colors, fontSize } from '@retailer-platform/shared-components/src/foundation'
import { useTDSContext } from '@retailer-platform/shared-components/src/tds'

export type PopoverTriggerProps = ButtonHTMLAttributes<HTMLDivElement> & {
  /**
   * Whether the trigger should be compact.
   */
  compact?: boolean
  /**
   * Additional class name to apply. This is also used for enabling usage of the `css` prop to customize the component.
   */
  className?: string
  /**
   * Whether the popover is open, will change the chevron icon.
   */
  isOpen?: boolean
  onClick?: () => void

  focusOutline?: boolean
}

const StyledRootElement = styled.div<PopoverTriggerProps>(
  ({ compact, disabled, focusOutline = true }) => {
    const color = disabled ? colors.GRAYSCALE.X30 : colors.GRAYSCALE.X50
    const backgroundColor = disabled ? colors.GRAYSCALE.X10 : colors.GRAYSCALE.X0

    return {
      color,
      backgroundColor,
      height: compact ? 28 : 40,
      padding: '4px 8px',
      borderRadius: compact ? '6px' : '8px',
      border: `1px solid ${colors.GRAYSCALE.X30}`,
      fontWeight: compact ? 'bold' : 'normal',
      fontSize: compact ? fontSize.X12 : fontSize.X15,
      verticalAlign: 'middle',
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      transition: 'all 0.1s ease',
      gap: 6,

      '&:focus': focusOutline && {
        outline: `2px solid ${colors.GRAYSCALE.X70}`,
        outlineOffset: 2,
      },

      '& svg': {
        fill: color,
      },
    }
  }
)

/**
 * A component that is commonly used for triggering popovers while maintaining a common look and feel.
 * This accepts any children passed to it, and will automatically color any SVGs to match the color of the text (including disabled states).
 *
 * Combine with [Popover](https://mantine.dev/core/popover/) to have a fully working popover.
 * Look into the stories for an integrated example using both components.
 */
export const PopoverTriggerDiv: React.FunctionComponent<
  React.PropsWithChildren<PopoverTriggerProps>
> = React.forwardRef<HTMLDivElement, PopoverTriggerProps>(
  ({ children, disabled, ...props }, ref) => {
    const { useTDS } = useTDSContext()
    return (
      <StyledRootElement {...props} ref={ref} disabled={disabled}>
        <div
          css={{
            overflow: 'hidden',
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 6,
          }}
        >
          {children}
        </div>

        <ChevronDownIcon
          css={{
            transition: 'transform 0.2s ease',
            transform: props.isOpen ? 'rotate(180deg)' : '',
          }}
          size={useTDS ? 14 : 24}
        />
      </StyledRootElement>
    )
  }
)
