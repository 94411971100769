import { ICPlatformVersion } from '@retailer-platform/dashboard/access-control'
import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureEntitlements } from './featureEntitlements'

export const minMoreAndHamburgerMenuVersion = ICPlatformVersion.r2025_02_0
export const minHeaderNavAndStylingEditVersion = ICPlatformVersion.r2025_01_0
// TODO, remove after R2
export const tempPreR2ReleasePartners = [
  '1230', //HORNBACHERS
  '1250', //PCNY
  '1260', //COBORNS
  '1261', //MPFOODS
  '1262', //CASHWISE
  '1331', //ANDYS
  '1197', //GELSONS
  '1209', //FOODBAZAAR
  '1288', //SAVE_MART
  '1308', //LUCKY_SUPERMARKETS
  '1309', //FOOD_MAXX
]
export const legacyReadOnlyAccessControl: DomainAccessControlConfig = {
  permissions: [Permission.ReadOnly],
}

export const siteManagementNavigationAccessControl = {
  featureEntitlementIds: [FeatureEntitlements.Navigation],
  permissions: [Permission.StoreConfigsView, Permission.StorefrontNavigationView],
} satisfies DomainAccessControlConfig

export const siteManagementNavigationModifyAccessControl = {
  featureEntitlementIds: [FeatureEntitlements.Navigation],
  permissions: [Permission.StoreConfigsModify],
} satisfies DomainAccessControlConfig
