const EN_US = {
  'siteManagementDomain.title': 'Site Management',
  'siteManagementDomain.common.unsavedChanges':
    'You have unsaved changes. Are you sure you want to leave?',
  'siteManagementDomain.common.optional': '(optional)',
  'siteManagementDomain.common.accessability.close': 'Close',
  'siteManagementDomain.common.accessability.toggleAccordion': 'Toggle accordion',
  'siteManagementDomain.common.accessability.seeMore': 'See more dropdown',
  'siteManagementDomain.common.required': 'Required',
  'siteManagementDomain.common.components.colorPicker.accessability': 'color select',
  'siteManagementDomain.common.components.colorPicker.placeholder': 'Search for a color',
  'siteManagementDomain.common.debugger.title': 'Debugger',
  'siteManagementDomain.common.debugger.state': 'State',
  'siteManagementDomain.common.debugger.errors': 'Errors',
  'siteManagementDomain.common.comingSoon': 'This feature is coming soon.',
  'siteManagementDomain.common.comingSoonLegacy':
    'This feature is coming soon. Head over to our legacy navigation tool to make any updates to your existing menu.',
  'siteManagementDomain.common.comingSoonLegacy.cta': 'Legacy navigation',
  'siteManagementDomain.navigation.page.title': 'Navigation',
  'siteManagementDomain.navigation.common.configurableGroupHeader.placeholder':
    'Add a title (optional)',
  'siteManagementDomain.navigationStyling.page.title': 'Header',
  'siteManagementDomain.navigationStyling.page.subtitle': 'Styling',
  'siteManagementDomain.navigationStyling.headingSection.title': 'Header navigation styling',
  'siteManagementDomain.navigationStyling.headingSection.subtitle': 'Desktop web',
  'siteManagementDomain.navigationStyling.topBarStylingSection.title': 'Top bar styling',
  'siteManagementDomain.navigationStyling.topBarStylingSection.subtitle':
    'Configure navigation bar spacing, background and foreground colors',
  'siteManagementDomain.navigationStyling.topBarSizeSection.title': 'Spacing',
  'siteManagementDomain.navigationStyling.topBarStickinessSection.title':
    'Top bar scrolling motion',
  'siteManagementDomain.navigationStyling.bottomBarStylingSection.title': 'Bottom bar styling',
  'siteManagementDomain.navigationStyling.bottomBarStylingSection.subtitle':
    'Configure navigation bar spacing, background and foreground colors',
  'siteManagementDomain.navigationStyling.bottomBarSizeSection.title': 'Spacing',
  'siteManagementDomain.navigationStyling.bottomBarStickinessSection.title':
    'Bottom bar scrolling motion',
  'siteManagementDomain.navigationStyling.headerLogoSection.title': 'Logo',
  'siteManagementDomain.navigationStyling.headerLogoSection.subtitle':
    'Edit the positioning of your logo',
  'siteManagementDomain.navigationStyling.searchBarSection.title': 'Search bar & Cart',
  'siteManagementDomain.navigationStyling.searchBarSection.subtitle':
    'Edit the positioning of your search bar and cart elements (both are always in the same bar)',
  'siteManagementDomain.navigationStyling.searchBarColumnSection.title': 'Search bar column',
  'siteManagementDomain.navigationStyling.searchBarColumnSection.subtitle':
    'Edit the positioning of your search bar',
  'siteManagementDomain.navigationStyling.fulfillmentSelectorSection.title': 'Fulfillment selector',
  'siteManagementDomain.navigationStyling.fulfillmentSelectorSection.subtitle':
    'Edit the positioning of your fulfillment selector element',
  'siteManagementDomain.navigationStyling.accountSection.title': 'Account',
  'siteManagementDomain.navigationStyling.accountSection.subtitle':
    'Edit the positioning of your account menu',
  'siteManagementDomain.navigationStyling.bannerSelectorSection.title': 'Banner selector',
  'siteManagementDomain.navigationStyling.bannerSelectorSection.subtitle':
    'Edit the positioning of your multi banner links',
  'siteManagementDomain.navigation.sections.header.groups.top.title': 'Top bar link block',
  'siteManagementDomain.navigation.sections.header.groups.topFeatured.title':
    'Top bar featured link block',
  'siteManagementDomain.navigation.sections.header.groups.bottom.title': 'Bottom bar link block',
  'siteManagementDomain.navigation.sections.header.groups.bottomFeatured.title':
    'Bottom bar featured link block',
  'siteManagementDomain.navigation.sections.header.groups.top.education.title':
    'Top link block menu',
  'siteManagementDomain.navigation.sections.header.groups.topFeatured.education.title':
    'Top bar featured link block',
  'siteManagementDomain.navigation.sections.header.groups.top.education.body':
    'The top bar link block is the main area for navigational links on your desktop header. We recommend including your primary links.',
  'siteManagementDomain.navigation.sections.header.groups.topFeatured.education.body':
    'The top bar featured link block appears on the right of the top bar of the header. You can customize the style of featured links to be a standard link, image or button. We recommend a limit of three featured links to allow them to stand out.',
  'siteManagementDomain.navigation.sections.header.groups.top.education.image': 'preview',
  'siteManagementDomain.navigation.sections.header.groups.bottom.education.title':
    'Bottom link block menu',
  'siteManagementDomain.navigation.sections.header.groups.bottomFeatured.education.title':
    'Bottom bar featured link block',
  'siteManagementDomain.navigation.sections.header.groups.bottom.education.body':
    'The bottom bar link block is an additional section in the header for navigational links.',
  'siteManagementDomain.navigation.sections.header.groups.bottomFeatured.education.body':
    'The bottom bar featured link block appears on the right of the bottom bar of the header. You can customize the style of featured links to be a standard link, image or button. We recommend a limit of three featured links to allow them to stand out.',
  'siteManagementDomain.navigation.sections.header.groups.bottom.education.image': 'preview',
  'siteManagementDomain.navigation.sections.headerColor.title.default': 'Header color',
  'siteManagementDomain.navigation.sections.headerColor.helpText.default':
    'This will be your header background color.',
  'siteManagementDomain.navigation.sections.headerColor.fieldLabel.default': 'Choose a color',
  'siteManagementDomain.navigation.sections.headerColor.title.native': 'Header color',
  'siteManagementDomain.navigation.sections.headerColor.helpText.native':
    'This will be your header background color.',
  'siteManagementDomain.navigation.sections.headerColor.fieldLabel.native': 'Choose a color',
  'siteManagementDomain.navigation.sections.headerColor.title.mobile': 'Header color',
  'siteManagementDomain.navigation.sections.headerColor.helpText.mobile':
    'This will be your header background color.',
  'siteManagementDomain.navigation.sections.headerColor.fieldLabel.mobile': 'Choose a color',
  'siteManagementDomain.navigation.sections.headerColor.title.desktopTopBar': 'Top bar styling',
  'siteManagementDomain.navigation.sections.headerColor.helpText.desktopTopBar':
    'This will be your header top bar background color.',
  'siteManagementDomain.navigation.sections.headerColor.fieldLabel.desktopTopBar':
    'Top bar background color',
  'siteManagementDomain.navigation.sections.headerColor.title.desktopBottomBar':
    'Bottom bar styling',
  'siteManagementDomain.navigation.sections.headerColor.helpText.desktopBottomBar':
    'This will be your header bottom bar background color.',
  'siteManagementDomain.navigation.sections.headerColor.fieldLabel.desktopBottomBar':
    'Bottom bar background color',
  'siteManagementDomain.navigation.sections.headerColor.education.title': 'Mobile web header',
  'siteManagementDomain.navigation.sections.headerColor.education.body':
    'The mobile web header includes the logo, cart button, menu icon and search bar. You can configure the color of your header. {cta}.',
  'siteManagementDomain.navigation.sections.headerColor.education.title.native':
    'Native app header',
  'siteManagementDomain.navigation.sections.headerColor.education.body.native':
    'The native app header includes the logo, cart button, and search bar. You can configure the color of your header. {cta}.',
  'siteManagementDomain.navigation.sections.headerColor.education.bodyCTA': 'Learn more',
  'siteManagementDomain.navigation.sections.headerColor.education.image': 'preview',
  'siteManagementDomain.navigation.sections.headerType.helpText.default':
    'Based on your background color selection above, edit the foreground color to ensure legibility. This will impact things like text and logo styling.',
  'siteManagementDomain.navigation.sections.headerType.fieldLabel.default': 'Foreground color',
  'siteManagementDomain.navigation.sections.foregroundPreview.text':
    'This is how your text will appear over your background color',
  'siteManagementDomain.navigation.sections.headerBackgroundType.fieldLabel.default':
    'Background color',
  'siteManagementDomain.navigation.sections.headerType.helpText.native':
    'Based on your background color selection above, edit the foreground color to ensure legibility. This will impact things like text and logo styling.',
  'siteManagementDomain.navigation.sections.headerType.fieldLabel.native': 'Foreground color',
  'siteManagementDomain.navigation.sections.headerType.helpText.mobile':
    'Based on your background color selection above, edit the foreground color to ensure legibility. This will impact things like text and logo styling.',
  'siteManagementDomain.navigation.sections.headerType.fieldLabel.mobile': 'Foreground color',
  'siteManagementDomain.navigation.sections.headerType.helpText.desktopTopBar':
    'Based on your background color selection above, edit the foreground color to ensure legibility. This will impact things like text and logo styling.',
  'siteManagementDomain.navigation.sections.headerType.fieldLabel.desktopTopBar':
    'Foreground color',
  'siteManagementDomain.navigation.sections.headerType.helpText.desktopBottomBar':
    'Based on your background color selection above, edit the foreground color to ensure legibility. This will impact things like text and logo styling.',
  'siteManagementDomain.navigation.sections.headerType.fieldLabel.desktopBottomBar':
    'Foreground color',
  'siteManagementDomain.navigation.sections.headerType.label.light': 'Light',
  'siteManagementDomain.navigation.sections.headerType.helpText.light':
    'For dark colored backgrounds',
  'siteManagementDomain.navigation.sections.headerType.label.dark': 'Dark',
  'siteManagementDomain.navigation.sections.headerType.helpText.dark':
    'For light colored backgrounds',
  'siteManagementDomain.navigation.sections.headerStickiness.label': 'Sticky header',
  'siteManagementDomain.navigation.sections.headerStickiness.helpText':
    'If the navigation bar is selected, it stays visible at all times. If unselected, it hides when scrolling down and reappears when scrolling up. However, if the bar contains search and cart functions, it will always be sticky and cannot be unselected.',
  'siteManagementDomain.navigation.sections.headerBackgroundType.label.brand':
    'Select from a brand color',
  'siteManagementDomain.navigation.sections.headerBackgroundType.label.hex':
    'Choose a custom hex code',
  'siteManagementDomain.navigation.sections.headerColorHex.fieldLabel.default': 'Hex color',
  'siteManagementDomain.navigation.sections.headerColorHex.placeholder.default': 'Enter hex color',
  'siteManagementDomain.navigation.sections.headerColorHex.error.default':
    'Please enter valid hex color',
  'siteManagementDomain.navigation.sections.headerColorHex.fieldLabel.mobile': 'Hex color',
  'siteManagementDomain.navigation.sections.headerColorHex.placeholder.mobile': 'Enter hex color',
  'siteManagementDomain.navigation.sections.headerColorHex.fieldLabel.desktopTopBar':
    'Top bar background color hex',
  'siteManagementDomain.navigation.sections.headerColorHex.placeholder.desktopTopBar':
    'Enter hex color',
  'siteManagementDomain.navigation.sections.headerColorHex.fieldLabel.desktopBottomBar':
    'Bottom bar background color hex',
  'siteManagementDomain.navigation.sections.headerColorHex.placeholder.desktopBottomBar':
    'Enter hex color',
  'siteManagementDomain.navigation.sections.tabMenu.title': 'Tab menu',
  'siteManagementDomain.navigation.sections.tabMenu.groups.configurableTabs.title':
    'Configurable tabs',
  'siteManagementDomain.navigation.sections.tabMenu.groups.mandatoryTabs.title': 'Mandatory tabs',
  'siteManagementDomain.navigation.sections.tabMenu.groups.mandatoryTabs.education.title':
    'What is configurable?',
  'siteManagementDomain.navigation.sections.tabMenu.groups.mandatoryTabs.education.body':
    'We recommend linking to internal pages only. You can add up to three configurable tabs. Tabs appear in the app from left to right based on the order you define.',
  'siteManagementDomain.navigation.sections.tabMenu.groups.mandatoryTabs.education.minTabsImage':
    'minimum of 3 tabs',
  'siteManagementDomain.navigation.sections.tabMenu.groups.mandatoryTabs.education.maxTabsImage':
    'maximum of 5 tabs',
  'siteManagementDomain.navigation.sections.linkBlock.title': 'Link Block {number}',
  'siteManagementDomain.navigation.sections.linkBlock.education.body':
    'Link block {number} is {mainOrAdditional} area for navigational links. You can customize the style of the links to be a standard link, image, button or a nested navigation.',
  'siteManagementDomain.navigation.sections.linkBlock.education.body.main': 'the main',
  'siteManagementDomain.navigation.sections.linkBlock.education.body.additional': 'an additional',
  'siteManagementDomain.navigation.sections.linkBlock.education.image': 'preview',
  'siteManagementDomain.navigation.forms.controls.displayText.label': '{verb} display name',
  'siteManagementDomain.navigation.forms.controls.displayText.placeholder': 'Enter display name',
  'siteManagementDomain.navigation.forms.controls.counter': '{count}/{max}',
  'siteManagementDomain.navigation.forms.controls.linkDestination.label': 'Select link destination',
  'siteManagementDomain.navigation.forms.controls.linkDestination.accessability.internalPage':
    'Internal page',
  'siteManagementDomain.navigation.forms.controls.linkDestination.accessability.externalPage':
    'External link',
  'siteManagementDomain.navigation.forms.controls.internalPage.label':
    'Search for an internal page',
  'siteManagementDomain.navigation.forms.controls.externalPage.label': 'Add web address (URL)',
  'siteManagementDomain.navigation.forms.controls.externalPage.placeholder':
    'https://www.example-url.com',
  'siteManagementDomain.navigation.forms.controls.externalPage.tabs.label': 'Open URL in',
  'siteManagementDomain.navigation.forms.controls.externalPage.tabs.sameTab': 'Same tab',
  'siteManagementDomain.navigation.forms.controls.externalPage.tabs.newTab': 'New tab',
  'siteManagementDomain.navigation.forms.controls.style.label': 'Select link styling',
  'siteManagementDomain.navigation.forms.controls.style.preview': 'Label',
  'siteManagementDomain.navigation.forms.controls.style.accessability': 'Style select',
  'siteManagementDomain.navigation.forms.controls.style.options.none': 'None',
  'siteManagementDomain.navigation.forms.controls.style.options.icon': 'Icon',
  'siteManagementDomain.navigation.forms.controls.style.options.button': 'Button',
  'siteManagementDomain.navigation.forms.controls.style.options.image': 'Image',
  'siteManagementDomain.navigation.forms.controls.style.image.label': 'Upload background image',
  'siteManagementDomain.navigation.forms.controls.style.image.help':
    'Recommended asset proportions: square or horizontal rectangle',
  'siteManagementDomain.navigation.forms.controls.style.button.label': 'Select button styling',
  'siteManagementDomain.navigation.forms.controls.style.button.optionLabel':
    '{variant} button styling',
  'siteManagementDomain.navigation.forms.controls.style.button.variants.primary': 'Primary',
  'siteManagementDomain.navigation.forms.controls.style.button.variants.secondary': 'Secondary',
  'siteManagementDomain.navigation.forms.controls.style.button.variants.tertiary': 'Tertiary',
  'siteManagementDomain.navigation.forms.controls.menuPreset.label': 'Add a menu preset {optional}',
  'siteManagementDomain.navigation.forms.controls.menuPreset.placeholder':
    'Search for a menu preset',
  'siteManagementDomain.navigation.forms.controls.menuPreset.options.none.label': 'None',
  'siteManagementDomain.navigation.forms.controls.menuPreset.options.departments.label':
    'All departments list',
  'siteManagementDomain.navigation.forms.controls.menuPreset.options.departments.description':
    'This preset auto-populates a list of all your departments so you don’t have to add them one by one.',
  'siteManagementDomain.navigation.forms.controls.icon.label': 'Choose an icon',
  'siteManagementDomain.navigation.forms.controls.icon.placeholder': 'Search for an icon',
  'siteManagementDomain.navigation.forms.controls.icon.accessability': 'Icon select',
  'siteManagementDomain.navigation.forms.controls.type.label': 'Select a type of link',
  'siteManagementDomain.navigation.forms.controls.type.options.standard.label': 'Regular link',
  'siteManagementDomain.navigation.forms.controls.type.options.standard.help':
    'Link shows up directly on the navigation bar.',
  'siteManagementDomain.navigation.forms.controls.type.options.nestedMenu.label': 'Nested link',
  'siteManagementDomain.navigation.forms.controls.type.options.nestedMenu.help':
    'Multiple links will nest under a parent link.',
  'siteManagementDomain.navigation.forms.controls.type.options.container.label': 'Container link',
  'siteManagementDomain.navigation.forms.controls.type.options.container.help':
    'Show multiple nested links under the same parent link.',
  'siteManagementDomain.navigation.forms.shopDestinations.home.label': 'Home',
  'siteManagementDomain.navigation.forms.shopDestinations.departments.label': 'Aisles',
  'siteManagementDomain.navigation.forms.shopDestinations.buyItAgain.label': 'Buy It Again',
  'siteManagementDomain.navigation.forms.shopDestinations.more.label': 'More',
  'siteManagementDomain.navigation.links.emptyStateTitle': 'No links',
  'siteManagementDomain.navigation.links.emptyStateDescription':
    'You don’t have any links yet. Click on the “{cta}” button below to create your menu.',
  'siteManagementDomain.navigation.links.newTabCTA': 'Add new tab',
  'siteManagementDomain.navigation.links.newLinkCTA': 'Add new link',
  'siteManagementDomain.navigation.links.overLimit':
    'You have reached the maximum number of links.',
  'siteManagementDomain.navigation.links.overLimitTabs':
    'You have reached the maximum number of links.',
  'siteManagementDomain.navigation.links.hereCTA': 'here',
  'siteManagementDomain.navigation.links.addSublinkCTA': 'Add sublink',
  'siteManagementDomain.navigation.links.addNestedMenuCTA': 'Add nested menu',
  'siteManagementDomain.navigation.links.editStyling': 'Edit Styling',
  'siteManagementDomain.navigation.links.edit': 'Edit',
  'siteManagementDomain.navigation.links.delete': 'Delete',
  'siteManagementDomain.navigation.links.departmentLabel':
    'To edit your departments head over to the departments tool.',
  'siteManagementDomain.navigation.links.departmentCTA': 'Departments',
  'siteManagementDomain.navigation.links.modal.forms.verbs.add': 'Add',
  'siteManagementDomain.navigation.links.modal.forms.verbs.edit': 'Edit',
  'siteManagementDomain.navigation.links.modal.forms.verbs.delete': 'Delete',
  'siteManagementDomain.navigation.links.modal.forms.tabLink.title': '{verb} tab link',
  'siteManagementDomain.navigation.links.modal.forms.topLevel.title': '{verb} link',
  'siteManagementDomain.navigation.links.modal.forms.nestedMenu.title': '{verb} nested menu',
  'siteManagementDomain.navigation.links.modal.forms.sublink.title': '{verb} sublink',
  'siteManagementDomain.navigation.links.modal.forms.deleteConfirmation.type': 'Type',
  'siteManagementDomain.navigation.links.modal.forms.deleteConfirmation.displayName':
    'Display name',
  'siteManagementDomain.navigation.links.modal.forms.deleteConfirmation.destination': 'Destination',
  'siteManagementDomain.navigation.links.modal.forms.discardConfirmation.title': 'Discard changes',
  'siteManagementDomain.navigation.links.modal.forms.discardConfirmation.message':
    'You have unsaved changes that will be lost',
  'siteManagementDomain.navigation.links.modal.submitCTA': 'Done',
  'siteManagementDomain.navigation.links.modal.cancelCTA': 'Cancel',
  'siteManagementDomain.navigation.links.modal.deleteCTA': 'Delete',
  'siteManagementDomain.navigation.links.modal.discardCTA': 'Discard',
  'siteManagementDomain.navigation.links.attributes.separator': ' - ',
  'siteManagementDomain.navigation.links.attributes.menuPreset.autoPopulated':
    'Automatically populated',
  'siteManagementDomain.navigation.links.attributes.menuPreset.departments': 'All departments',
  'siteManagementDomain.navigation.links.attributes.container': 'Container link',
  'siteManagementDomain.navigation.links.attributes.group':
    '{number} {number, plural, =1 {sublink} other {sublinks}}',
  'siteManagementDomain.navigation.links.attributes.standard.collection': 'Collection',
  'siteManagementDomain.navigation.links.attributes.standard.url': 'URL',
  'siteManagementDomain.navigation.links.attributes.standard.page': 'Page',
  'siteManagementDomain.navigation.links.attributes.standard.default': 'Internal',
  'siteManagementDomain.navigation.links.attributes.standard.authentication': 'Authentication',
  'siteManagementDomain.navigation.layout.publish.done': 'Done',
  'siteManagementDomain.navigation.layout.publish.preview': 'Preview',
  'siteManagementDomain.navigation.layout.publish.cancel': 'Cancel',
  'siteManagementDomain.navigation.layout.desktop.tabLabel': 'Desktop',
  'siteManagementDomain.navigation.layout.desktop.title': 'Desktop menus',
  'siteManagementDomain.navigation.layout.desktop.description':
    'Manage the navigation for your desktop web experience. Learn more {cta}.',
  'siteManagementDomain.navigation.layout.desktop.header.title': 'Header',
  'siteManagementDomain.navigation.layout.desktop.header.description':
    'Set up the menus for your header navigation',
  'siteManagementDomain.navigation.layout.desktop.header.cta': 'Edit styling',
  'siteManagementDomain.navigation.layout.desktop.sidebar.title': 'Sidebar',
  'siteManagementDomain.navigation.layout.desktop.sidebar.description':
    'This feature is coming soon.',
  'siteManagementDomain.navigation.layout.desktop.footer.title': 'Footer',
  'siteManagementDomain.navigation.layout.mweb.tabLabel': 'Mobile Web',
  'siteManagementDomain.navigation.layout.mweb.title': 'Mobile web menus',
  'siteManagementDomain.navigation.layout.mweb.description':
    'Manage the navigation for your mobile web experience.',
  'siteManagementDomain.navigation.layout.mweb.header.title': 'Mobile web header',
  'siteManagementDomain.navigation.layout.mweb.header.description':
    'Configure your mobile web styling',
  'siteManagementDomain.navigation.layout.mweb.menu.title': 'Mobile web menu',
  'siteManagementDomain.navigation.layout.mweb.menu.description':
    'Navigations that appear in the mobile web hamburger menu',
  'siteManagementDomain.navigation.layout.native.tabLabel': 'Native app',
  'siteManagementDomain.navigation.layout.native.title': 'Native app menus',
  'siteManagementDomain.navigation.layout.native.description':
    'Manage the navigation for your native app experience.',
  'siteManagementDomain.navigation.layout.native.header.title': 'Native app header',
  'siteManagementDomain.navigation.layout.native.header.description':
    'Configure your mobile web styling',
  'siteManagementDomain.navigation.layout.native.tabBar.title': 'Native app tab bar',
  'siteManagementDomain.navigation.layout.native.tabBar.description':
    'The native app tab bar is the primary navigation for your app. It should not include any external links. The first and last tabs are mandatory, and you can add up to three configurable tabs.',
  'siteManagementDomain.navigation.layout.native.moreMenu.title': 'More menu',
  'siteManagementDomain.navigation.layout.native.moreMenu.description':
    'The “more menu” is an additional section in your account page where you can add secondary links.',
  'siteManagementDomain.navigation.layout.native.storeMenu.title': 'Store menu',
} as const

export default EN_US
