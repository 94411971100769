import { type ApolloError } from '@apollo/client'
import { errorCodes } from './errorCodes'

const makeGraphQLErrorCodeChecker =
  (errorCode: ValueOf<typeof errorCodes>) => (apolloError?: ApolloError) => {
    if (!apolloError) return false

    return apolloError.graphQLErrors.some(err => err.extensions?.code === errorCode)
  }

export const isNotAuthorizedError = makeGraphQLErrorCodeChecker(errorCodes.NOT_AUTHORIZED)
export const isInvalidCaptchaError = makeGraphQLErrorCodeChecker(errorCodes.INVALID_CAPTCHA)
export const isInvalidCredentialsError = makeGraphQLErrorCodeChecker(errorCodes.INVALID_CREDENTIALS)
export const isSecurityError = makeGraphQLErrorCodeChecker(errorCodes.SECURITY)
export const isTokenExpiredError = makeGraphQLErrorCodeChecker(errorCodes.TOKEN_EXPIRED)
export const isDisabledAccountError = makeGraphQLErrorCodeChecker(errorCodes.DISABLED_ACCOUNT)
export const isNotFoundError = makeGraphQLErrorCodeChecker(errorCodes.NOT_FOUND)
export const isUndeterminedAccountError = makeGraphQLErrorCodeChecker(
  errorCodes.UNDETERMINED_ACCOUNT
)
export const isInvalidActionError = makeGraphQLErrorCodeChecker(errorCodes.INVALID_ACTION)
export const isAccountAlreadySetupError = makeGraphQLErrorCodeChecker(
  errorCodes.ACCOUNT_ALREADY_SETUP
)
