import type { PropsWithChildren, FunctionComponent } from 'react'
import { createContext, useCallback, useMemo, useState, useContext, useEffect } from 'react'
import { type ApolloError } from '@apollo/client'
import { useHistory } from 'react-router'
import { useRetailerSatisfactionSurveyMutation } from '../../../__codegen__/api'
import { useCurrentAccountContext } from '../../../utils/current-account/CurrentAccountContext'

type SurveyContext<Step extends String, FormState extends Record<string, any>, ToolProps> = {
  goToPage: (step: Step) => void
  goBack: () => void
  surveyState: FormState
  updateSurveyState: (key, value) => void
  clearSurveyState: () => void
  submitSurvey: () => void
  toolProps: ToolProps
  isSurveySubmitted: boolean
  error: ApolloError | null | undefined
  loading: boolean
  resetSurveySubmission: () => void
}

const surveyContext = createContext<SurveyContext<any, any, any>>(null)

export const SurveyContextProvider: FunctionComponent<
  PropsWithChildren<{
    toolProps: {}
    surveyData: [{}, (x) => void]
    onSurveyCompleted: (x) => {}
    retailerId: string
    partnerId: string
    milestoneSlug: string
  }>
> = ({
  children,
  surveyData,
  toolProps,
  onSurveyCompleted,
  retailerId,
  partnerId,
  milestoneSlug,
}) => {
  const [surveyState, setSurveyState] = surveyData
  const [surveyCompleted, setSurveyCompleted] = useState(false)
  const [shownAt] = useState(new Date().toISOString())

  const history = useHistory()
  const goToPage = useCallback(newStep => history.push(newStep), [history])
  const goBack = history.goBack

  const { account } = useCurrentAccountContext()

  const submitSurvey = () => {
    setSurveyCompleted(true)
  }

  const resetSurveySubmission = () => {
    setSurveyCompleted(false)
  }

  const prepareSurveyResponse = (userHash: {}) =>
    Object.keys(userHash).map(key => {
      const value = String(userHash[key])
      return {
        surveyQuestion: key,
        surveyResponse: value,
      }
    })

  const [retailerSatisfactionSurveyMutation, { data, loading, error }] =
    useRetailerSatisfactionSurveyMutation({
      variables: {
        retailerId: retailerId,
        partnerId: partnerId,
        accountId: account.id,
        urlPath: window.location.pathname,
        surveyResponses: prepareSurveyResponse(onSurveyCompleted(surveyState)),
        shownAt: shownAt,
        submittedAt: new Date().toISOString(),
        milestoneSlug: milestoneSlug,
      },
    })

  const isSurveySubmitted =
    surveyCompleted && data?.retailerSatisfactionSurveySubmissionCreate?.success

  const contextValue = useMemo(
    () => ({
      goBack,
      goToPage,
      surveyState,
      updateSurveyState: (key: string, value) => {
        setSurveyState(state => ({ ...state, [key]: value }))
      },
      toolProps,
      clearSurveyState: () => {
        setSurveyState({})
      },
      submitSurvey,
      isSurveySubmitted,
      error,
      loading,
      resetSurveySubmission,
    }),
    [goBack, goToPage, setSurveyState, surveyState, toolProps, isSurveySubmitted, error, loading]
  )

  useEffect(() => {
    if (surveyCompleted) {
      retailerSatisfactionSurveyMutation()
    }
  }, [surveyCompleted, retailerSatisfactionSurveyMutation])

  return <surveyContext.Provider value={contextValue}>{children}</surveyContext.Provider>
}

export const useSurvey = <StepType extends string, FormType, ToolProps>() =>
  useContext<SurveyContext<any, FormType, ToolProps>>(surveyContext)
