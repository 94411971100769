import type { PropsWithChildren, FunctionComponent } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import { TextField } from '@retailer-platform/shared-components/src/tds'
import { type InjectedDashIntlProps } from '../../../../../../intl/intl.types'
import Card from '../../../../../components/Card/Card'
import { type StoreGroupsFormikProps } from '../utils/form.types'

interface Props extends StoreGroupsFormikProps, InjectedDashIntlProps {}

export const _StoreGroupsFormNameField: FunctionComponent<PropsWithChildren<Props>> = ({
  values: { name },
  errors: { name: nameError },
  intl,
  setValues,
  values,
}) => {
  const placeholder = intl.formatMessage({
    id: 'settings.storeGroups.form.labels.name',
  })

  return (
    <Card margin="0 0 20px 0">
      <Card.Header title={<FormattedMessage id="settings.storeGroups.form.groupDetail" />} />
      <Card.Body>
        <TextField
          name="name"
          value={name}
          onChange={e => setValues({ ...values, name: e.target.value })}
          description={nameError}
          error={!!nameError}
          placeholder={placeholder}
          data-testid="store-group-name-field"
          labelProps={{
            label: placeholder,
          }}
        />
      </Card.Body>
    </Card>
  )
}

export const StoreGroupsFormNameField = injectIntl(_StoreGroupsFormNameField)
