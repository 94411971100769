import { useMemo, useState } from 'react'
import { useFilteredWarehouseLocations } from '../../../../../utils/filteredWarehouseLocations.hooks'
import { type StoreGroupsFormikValues } from '../../../utils/form.types'
import { type WarehouseLocation } from '../../../../../../../common/types'
import { useRetailerId } from '../../../../../../../../utils/routing/params.hooks'
import { usePartnerContext } from '../../../../../../../../utils/contexts/partner/PartnerContext.hooks'

interface Props {
  showSelectedLocationsOnly?: boolean
  selectedLocationIds: StoreGroupsFormikValues['selectedLocationIds']
  currentRetailerIds: StoreGroupsFormikValues['retailerIds']
}

export interface StoreLocationDetails extends WarehouseLocation {}

export const useStoreGroupLocations = ({
  showSelectedLocationsOnly,
  selectedLocationIds,
  currentRetailerIds,
}: Props) => {
  const retailerId = useRetailerId()

  const { warehouses } = usePartnerContext()

  const defaultRetailerIds =
    Number(retailerId) > 0 ? [retailerId] : warehouses.map(e => String(e.id))

  const [filter, setLocationsFilterValue] = useState<string>()
  const [retailerIdFilter, setRetailerIdFilter] = useState<Set<string>>(new Set(defaultRetailerIds))

  const idFilter = useMemo(() => {
    if (showSelectedLocationsOnly) return new Set(selectedLocationIds)

    return undefined
  }, [selectedLocationIds, showSelectedLocationsOnly])

  const { data, loading, error } = useFilteredWarehouseLocations({
    filter,
    idFilter,
    retailerIds: showSelectedLocationsOnly ? new Set(currentRetailerIds) : retailerIdFilter,
  })

  const locations: StoreLocationDetails[] = data || []

  const clearLocationsFilterValue = () => {
    setLocationsFilterValue(undefined)
  }

  return {
    loading,
    error,
    locationsFilterValue: filter || '',
    setLocationsFilterValue,
    clearLocationsFilterValue,
    locations,
    retailerIdFilter,
    setRetailerIdFilter,
  }
}
