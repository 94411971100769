import { type IntlShape } from 'react-intl'
import { type ItemGroup } from '../NavMenuItemGroup'
import { type SpotlightAction } from '../NavSearchButton'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'

const getRetailerCategories = (userSettingsHierarchy: NavMenuHierarchy, intl: IntlShape) => {
  const mapRetailerCategory = (categoryTitle: string): ItemGroup => {
    const categoryDetail = userSettingsHierarchy.hierarchy.find(
      (item: any) => item.name === categoryTitle
    )
    return {
      groupHeaderText: intl.formatMessage({ id: categoryTitle }),
      items: categoryDetail.children
        .sort((a, b) =>
          intl.formatMessage({ id: a.name }).localeCompare(intl.formatMessage({ id: b.name }))
        )
        .map((item: any) => ({
          label: intl.formatMessage({ id: item.name }),
          route: item.route,
          onClick: () => {},
        })),
    }
  }
  const categoryTitles = ['settings.title', 'navV2.userSettings.users']
  return categoryTitles.map(categoryTitle => mapRetailerCategory(categoryTitle))
}

export const formCategoriesIntoItemGroups = (
  searchActions: SpotlightAction[],
  intl: IntlShape,
  onClick: () => void,
  userSettingsHierarchy?: NavMenuHierarchy
) => {
  const retailerCategories = userSettingsHierarchy
    ? getRetailerCategories(userSettingsHierarchy, intl)
    : []

  const header = searchActions
    .map(category => category.group)
    .filter((value, index, self) => self.indexOf(value) === index)

  const categoryItemsGroups: ItemGroup[] = [
    ...header.map(categoryL1 => ({
      groupHeaderText: categoryL1,
      items: searchActions
        .filter(categoryL2 => categoryL2.group === categoryL1)
        .map(action => ({
          label: action.label,
          route: action.route || '',
          onClick: action.onClick,
        }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    })),
    ...retailerCategories,
  ].sort((a, b) => a.groupHeaderText.localeCompare(b.groupHeaderText))

  categoryItemsGroups.forEach(group => {
    group.items.forEach(item => {
      const originalOnClick = item.onClick
      item.onClick = () => {
        onClick()
        originalOnClick?.()
      }
    })
  })

  return categoryItemsGroups
}
