import React from 'react'
import { SelectField } from '@retailer-platform/shared-components/src/tds'
import { type SimpleOption } from '@retailer-platform/shared-components/src/common/select/utils/select.types'
import styled from '@emotion/styled'

interface NotificationsFilterProps {
  placeholder?: string
  options: SimpleOption[]
  selectedValue?: string
  onChange: (value: string | null) => void
}

const FilterContainer = styled.div({
  width: '200px',
})
export const NotificationsFilter = ({
  placeholder,
  options,
  selectedValue,
  onChange,
  ...rest
}: NotificationsFilterProps) => (
  <FilterContainer>
    <SelectField
      value={selectedValue}
      placeholder={placeholder}
      onChange={onChange}
      options={options}
      clearable={true}
      {...rest}
    />
  </FilterContainer>
)
