const EN_US = {
  'connectedStoresAdminDomain.selectPrompt': 'Select an item on the left to view more details',
  'connectedStoresAdminDomain.new': 'New',
  'connectedStoresAdminDomain.submit': 'Save',
  'connectedStoresAdminDomain.saving': 'Saving...',
  'connectedStoresAdminDomain.reset': 'Reset',
  'connectedStoresAdminDomain.toastAction': 'Dismiss',
  'connectedStoresAdminDomain.none': 'None',
  'connectedStoresAdminDomain.copy': 'Copy',
  'connectedStoresAdminDomain.copied': 'Copied',
  'connectedStoresAdminDomain.staging': 'Staging',
  'connectedStoresAdminDomain.production': 'Production',
  'connectedStoresAdminDomain.add': 'Add',
  'connectedStoresAdminDomain.remove': 'Remove',
  'connectedStoresAdminDomain.required': 'Required',
  'connectedStoresAdminDomain.edit': 'Edit',
  'connectedStoresAdminDomain.history': 'History',
  'connectedStoresAdminDomain.createdAt': 'Created At',
  'connectedStoresAdminDomain.updatedAt': 'Updated At',
  'connectedStoresAdminDomain.by': 'By',
  'connectedStoresAdminDomain.unknown': 'Unknown',
  'connectedStoresAdminDomain.revert': 'Revert',
  'connectedStoresAdminDomain.putSuccess': 'Record saved',
  'connectedStoresAdminDomain.putFailure': 'Could not save',
  'connectedStoresAdminDomain.example': 'Ex: "{example}"',
  'connectedStoresAdminDomain.view': 'View',

  'connectedStoresAdminDomain.caper.title': 'Caper',
  'connectedStoresAdminDomain.caper.pageTitle': 'Connected Stores Caper Page',
  'connectedStoresAdminDomain.caper.cartSubtitle': 'carts',
  'connectedStoresAdminDomain.caper.globalTitle': 'Global',
  'connectedStoresAdminDomain.caper.cityLabel': 'city',
  'connectedStoresAdminDomain.caper.configsTitle': 'Configs',
  'connectedStoresAdminDomain.caper.partnerTitle': 'Partner',
  'connectedStoresAdminDomain.caper.retailerTitle': 'Retailer',
  'connectedStoresAdminDomain.caper.retailerPartnersTitle': 'Retail Partners',
  'connectedStoresAdminDomain.caper.storeTitle': 'Store',
  'connectedStoresAdminDomain.caper.toastTitle': 'Config Updated',
  'connectedStoresAdminDomain.caper.toastError': 'Error saving changes',
  'connectedStoresAdminDomain.caper.cancelAction': 'Cancel',
  'connectedStoresAdminDomain.caper.deleteAction': 'Delete',
  'connectedStoresAdminDomain.caper.deleteTitle': 'Delete Config',
  'connectedStoresAdminDomain.caper.save': 'Save',
  'connectedStoresAdminDomain.caper.environmentBannerProd': 'Production Caper Environment',
  'connectedStoresAdminDomain.caper.environmentBannerStg': 'Staging Caper Environment',
  'connectedStoresAdminDomain.caper.deviceCreationOverridePhrase': 'customer facing cart in-store',

  'connectedStoresAdminDomain.shelfLabels': 'Carrot Tags',
  'connectedStoresAdminDomain.shelfLabels.stores.title': 'Carrot Tags - Stores',
  'connectedStoresAdminDomain.shelfLabels.connections.title': 'Carrot Tags - Connections',
  'connectedStoresAdminDomain.shelfLabels.syncStrategies.title': 'Carrot Tags - Sync Strategies',
  'connectedStoresAdminDomain.shelfLabels.stores': 'Stores',
  'connectedStoresAdminDomain.shelfLabels.connections': 'Connections',
  'connectedStoresAdminDomain.shelfLabels.syncStrategies': 'Sync Strategies',
  'connectedStoresAdminDomain.shelfLabels.search': 'Search...',
  'connectedStoresAdminDomain.shelfLabels.location': 'Location',
  'connectedStoresAdminDomain.shelfLabels.id': 'ID',
  'connectedStoresAdminDomain.shelfLabels.name': 'Name',
  'connectedStoresAdminDomain.shelfLabels.vendor': 'Vendor',
  'connectedStoresAdminDomain.shelfLabels.store': 'Store',
  'connectedStoresAdminDomain.shelfLabels.connection': 'Connection',
  'connectedStoresAdminDomain.shelfLabels.syncStrategy': 'Sync Strategy',
  'connectedStoresAdminDomain.shelfLabels.retailerLocationId': 'Retailer Location ID',
  'connectedStoresAdminDomain.shelfLabels.vendorIdentifier': 'Vendor Identifier',
  'connectedStoresAdminDomain.shelfLabels.productCodeSource': 'Product Code Source',
  'connectedStoresAdminDomain.shelfLabels.productCodeSource.product_codes': 'Product Codes',
  'connectedStoresAdminDomain.shelfLabels.productCodeSource.retailer_reference_code':
    'Retailer Reference Code',
  'connectedStoresAdminDomain.shelfLabels.productCodeNormalization': 'Product Code Normalization',
  'connectedStoresAdminDomain.shelfLabels.productCodeNormalization.none': 'None',
  'connectedStoresAdminDomain.shelfLabels.productCodeNormalization.plu_or_gtin14': 'PLU/GTIN14',
  'connectedStoresAdminDomain.shelfLabels.key': 'Key',
  'connectedStoresAdminDomain.shelfLabels.customerCode': 'Customer Code',
  'connectedStoresAdminDomain.shelfLabels.username': 'Username',
  'connectedStoresAdminDomain.shelfLabels.password': 'Password',
  'connectedStoresAdminDomain.shelfLabels.clientId': 'Client ID',
  'connectedStoresAdminDomain.shelfLabels.clientSecret': 'Client Secret',
  'connectedStoresAdminDomain.shelfLabels.subdomain': 'Subdomain',
  'connectedStoresAdminDomain.shelfLabels.vendors.ses': 'SES (Vusion)',
  'connectedStoresAdminDomain.shelfLabels.vendors.pricer': 'Pricer',
  'connectedStoresAdminDomain.shelfLabels.vendors.hanshow_v1_5': 'Hanshow v1.5 (Aperion)',
  'connectedStoresAdminDomain.shelfLabels.vendors.hanshow_v2_4': 'Hanshow v2.4 (ALDI)',
  'connectedStoresAdminDomain.shelfLabels.new': 'New',
  'connectedStoresAdminDomain.shelfLabels.healthCheck': 'Health Check',
  'connectedStoresAdminDomain.shelfLabels.checkHealthWarning':
    'Checking the health of a store attempts to load a label identifier from the vendor API and flash the corresponding label in the real, physical store. Please use this functionality sparingly.',
  'connectedStoresAdminDomain.shelfLabels.syncPaused': 'Sync Paused',
  'connectedStoresAdminDomain.shelfLabels.checkHealth': 'Check Health',
  'connectedStoresAdminDomain.shelfLabels.healthCheckSuccess': 'Health check succeeded',
  'connectedStoresAdminDomain.shelfLabels.healthCheckFailure': 'Health check failed',
  'connectedStoresAdminDomain.shelfLabels.rowLimitReached':
    'Only showing {shown} of {total} rows. Please search to view more.',

  'connectedStoresAdminDomain.upos.title': 'UPOS',
  'connectedStoresAdminDomain.upos.status': 'Status',
  'connectedStoresAdminDomain.upos.status.title': 'UPOS Status',
  'connectedStoresAdminDomain.upos.retailers': 'Retailers',
  'connectedStoresAdminDomain.upos.retailers.title': 'UPOS Retailers',
  'connectedStoresAdminDomain.upos.profiles': 'Profiles',
  'connectedStoresAdminDomain.upos.profiles.title': 'UPOS Profiles',
  'connectedStoresAdminDomain.upos.stores': 'Stores',
  'connectedStoresAdminDomain.upos.stores.title': 'UPOS Stores',
  'connectedStoresAdminDomain.upos.integrations': 'Integrations',
  'connectedStoresAdminDomain.upos.integrations.title': 'UPOS Integrations',
  'connectedStoresAdminDomain.upos.integrationSpecificConfig': 'POS Vendor Config',
  'connectedStoresAdminDomain.upos.noIntegrationSpecificConfig': 'No Integration-Specific Config',
  'connectedStoresAdminDomain.upos.apiUrl': 'API URL',
  'connectedStoresAdminDomain.upos.apiKey': 'API Key',
  'connectedStoresAdminDomain.upos.apiSecret': 'API Secret',
  'connectedStoresAdminDomain.upos.gamificationApiUrl': 'Gamification API URL',
  'connectedStoresAdminDomain.upos.gamificationApiKey': 'Gamification API Key',
  'connectedStoresAdminDomain.upos.gamificationApiSecret': 'Gamification API Secret',
  'connectedStoresAdminDomain.upos.apiSubscriptionKey': 'API Subscription Key',
  'connectedStoresAdminDomain.upos.apiTokenUrl': 'API Token URL',
  'connectedStoresAdminDomain.upos.incentives': 'Incentives',
  'connectedStoresAdminDomain.upos.staticCoupons': 'Static Coupons',
  'connectedStoresAdminDomain.upos.couponCode': 'Coupon Code',
  'connectedStoresAdminDomain.upos.amount': 'Amount',
  'connectedStoresAdminDomain.upos.lookupCodeForceCheckDigit': 'Lookup Code Force Check Digit',
  'connectedStoresAdminDomain.upos.forceLoyaltyCacheEvict': 'Force Loyalty Cache Evict',
  'connectedStoresAdminDomain.upos.dummyLoyaltyId': 'Dummy Loyalty ID',
  'connectedStoresAdminDomain.upos.transferTrackingEnabled': 'Transfer Tracking Enabled',
  'connectedStoresAdminDomain.upos.instacartSecureLink': 'Instacart Secure Link',
  'connectedStoresAdminDomain.upos.promotionEngine': 'Promotion Engine',
  'connectedStoresAdminDomain.upos.storeIdentifier': 'Store Identifier',
  'connectedStoresAdminDomain.upos.websocketUrl': 'Websocket URL',
  'connectedStoresAdminDomain.upos.authToken': 'Auth Token',
  'connectedStoresAdminDomain.upos.retailerIdentifier': 'Retailer Identifier',
  'connectedStoresAdminDomain.upos.priceEmbeddedCheckDigit': 'Price Embedded Check Digit',
  'connectedStoresAdminDomain.upos.jbrainApiKey': 'JBrain API Key',
  'connectedStoresAdminDomain.upos.url': 'URL',
  'connectedStoresAdminDomain.upos.defaultCartId': 'Default Cart ID',
  'connectedStoresAdminDomain.upos.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.retailerLocation': 'Store',
  'connectedStoresAdminDomain.upos.profile': 'Profile',
  'connectedStoresAdminDomain.upos.comment': 'Comment',
  'connectedStoresAdminDomain.upos.externalRetailerCommunicationDescription':
    'External Retailer Communication Description',
  'connectedStoresAdminDomain.upos.notificationSupported': 'Notification Supported',
  'connectedStoresAdminDomain.upos.liveAt': 'Live At',
  'connectedStoresAdminDomain.upos.externalStoreNumber': 'External Store Number',
  'connectedStoresAdminDomain.upos.externalDivisionNumber': 'External Division Number',
  'connectedStoresAdminDomain.upos.truCommerce': 'TruCommerce',
  'connectedStoresAdminDomain.upos.username': 'Username',
  'connectedStoresAdminDomain.upos.password': 'Password',
  'connectedStoresAdminDomain.upos.type': 'Type',
  'connectedStoresAdminDomain.upos.facilityId': 'Facility ID',
  'connectedStoresAdminDomain.upos.newRetailer': 'New Retailer',
  'connectedStoresAdminDomain.upos.newStore': 'New Store',
  'connectedStoresAdminDomain.upos.newIntegration': 'New Integration',
  'connectedStoresAdminDomain.upos.newProfile': 'New Profile',
  'connectedStoresAdminDomain.upos.suspendBarcodePrefix': 'Suspend Barcode Prefix',
  'connectedStoresAdminDomain.upos.activeIntegration': 'Active Integration',
  'connectedStoresAdminDomain.upos.status.healthy': 'Healthy',
  'connectedStoresAdminDomain.upos.status.unhealthy': 'Unhealthy',
  'connectedStoresAdminDomain.upos.status.undetermined': 'Not Implemented',
  'connectedStoresAdminDomain.upos.status.integration': 'Integration',
  'connectedStoresAdminDomain.upos.status.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.status.location': 'Location',
  'connectedStoresAdminDomain.upos.status.retailerLocationId': 'Retailer Location ID',
  'connectedStoresAdminDomain.upos.status.comment': 'Comment',
  'connectedStoresAdminDomain.upos.status.statusDescription': 'Status Description',
  'connectedStoresAdminDomain.upos.status.links': 'Links',
  'connectedStoresAdminDomain.upos.status.supportEmails': 'External Technical Contacts',
  'connectedStoresAdminDomain.upos.status.status': 'Status',
  'connectedStoresAdminDomain.upos.status.firstCheckedAt': 'First Checked At',
  'connectedStoresAdminDomain.upos.status.lastCheckedAt': 'Last Checked At',
  'connectedStoresAdminDomain.upos.status.healthySince': 'Healthy Since',
  'connectedStoresAdminDomain.upos.status.unhealthySince': 'Unhealthy Since',
  'connectedStoresAdminDomain.upos.status.reason': 'Reason',
  'connectedStoresAdminDomain.upos.status.debugInfo': 'Debug Info',
  'connectedStoresAdminDomain.upos.retailers.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.retailers.retailerId': 'Retailer ID',
  'connectedStoresAdminDomain.upos.retailers.name': 'Name',
  'connectedStoresAdminDomain.upos.profiles.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.profiles.profile': 'Profile',
  'connectedStoresAdminDomain.upos.profiles.type': 'Type',
  'connectedStoresAdminDomain.upos.profiles.name': 'Name',
  'connectedStoresAdminDomain.upos.profiles.alertEmailRecipients': 'Alert Email Recipients',
  'connectedStoresAdminDomain.upos.profiles.emailAddress': 'Email Address',
  'connectedStoresAdminDomain.upos.profiles.enableAlertEmails': 'Enable Alert Emails',
  'connectedStoresAdminDomain.upos.profiles.storeConfigurationId': 'Store Configuration ID',
  'connectedStoresAdminDomain.upos.profiles.clientConfigurationId': 'Client Configuration ID',
  'connectedStoresAdminDomain.upos.profiles.excludedOperations': 'Excluded Operations',
  'connectedStoresAdminDomain.upos.profiles.use3PiLoyalty': 'Use 3PI Loyalty',
  'connectedStoresAdminDomain.upos.profiles.use3PiLoyaltyOtp': 'Use 3PI Loyalty One-Time Password',
  'connectedStoresAdminDomain.upos.stores.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.stores.retailerLocationId': 'Location ID',
  'connectedStoresAdminDomain.upos.stores.locationCode': 'Location Code',
  'connectedStoresAdminDomain.upos.stores.catalogSource': 'Catalog Source',
  'connectedStoresAdminDomain.upos.stores.caperStoreId': 'Caper Store ID',
  'connectedStoresAdminDomain.upos.stores.country': 'Country',
  'connectedStoresAdminDomain.upos.stores.timeZone': 'Time Zone',
  'connectedStoresAdminDomain.upos.stores.currency': 'Currency',
  'connectedStoresAdminDomain.upos.integrations.retailer': 'Retailer',
  'connectedStoresAdminDomain.upos.integrations.retailerLocationId': 'Location ID',
  'connectedStoresAdminDomain.upos.integrations.integration': 'Integration',

  'connectedStoresAdminDomain.upos.descriptions.integrationSpecificConfig':
    'These settings are POS vendor-specific',
  'connectedStoresAdminDomain.upos.descriptions.incentives': 'How incentives are applied to orders',
  'connectedStoresAdminDomain.upos.descriptions.variableAmountCouponCode':
    'The code for variable amount coupons',
  'connectedStoresAdminDomain.upos.descriptions.staticAmountCouponCodes':
    'The mapping of static coupon codes to their respective amounts',
  'connectedStoresAdminDomain.upos.descriptions.retailerLocationId':
    'The ID of the associated Instacart retailer location',
  'connectedStoresAdminDomain.upos.descriptions.locationCode':
    "The retailer's identifier for the store used in Instacart catalog files",
  'connectedStoresAdminDomain.upos.descriptions.catalogSource':
    'Where the store gets catalog data from',
  'connectedStoresAdminDomain.upos.descriptions.comment': 'An internal, human-readable description',
  'connectedStoresAdminDomain.upos.descriptions.externalRetailerCommunicationDescription':
    'A retailer-facing, human-readable description',
  'connectedStoresAdminDomain.upos.descriptions.liveAt':
    'When the store is live for customers and when UPOS should start receiving alerts',
  'connectedStoresAdminDomain.upos.descriptions.notificationSupported':
    'Used to differentiate between Production and QA stores with the same External Store Number',
  'connectedStoresAdminDomain.upos.descriptions.profile':
    'A configuration profile that contains a group of settings',
  'connectedStoresAdminDomain.upos.descriptions.externalStoreNumber':
    'The identifier used by the POS vendor to identify the store',
  'connectedStoresAdminDomain.upos.descriptions.retailerId':
    'The ID of the associated Instacart retailer',
  'connectedStoresAdminDomain.upos.descriptions.activeIntegration':
    'The integration that is currently active for the store',
  'connectedStoresAdminDomain.upos.descriptions.profileName':
    'A descriptive, human-readable name that describes this particular group of settings',
  'connectedStoresAdminDomain.upos.descriptions.enableAlertEmails':
    'Whether to send alert emails when an outage is detected',
  'connectedStoresAdminDomain.upos.descriptions.storeConfigurationId':
    'The ID of the associated Instacart store configuration',
  'connectedStoresAdminDomain.upos.descriptions.clientConfigurationId':
    'The ID of the associated Instacart client configuration',
  'connectedStoresAdminDomain.upos.descriptions.excludedOperations':
    'The operations that are not supported by the integration',
  'connectedStoresAdminDomain.upos.descriptions.use3PiLoyalty':
    'Whether and when to use 3PI loyalty',
  'connectedStoresAdminDomain.upos.descriptions.use3PiLoyaltyOtp':
    'Whether to use 3PI loyalty one-time password',
  'connectedStoresAdminDomain.upos.descriptions.profileType':
    'Which POS vendor this profile is for',
  'connectedStoresAdminDomain.upos.descriptions.lookupCodeForceCheckDigit':
    'Whether to always add a check digit to the lookup code',
  'connectedStoresAdminDomain.upos.descriptions.forceLoyaltyCacheEvict':
    'Whether to flush the loyalty cache',
  'connectedStoresAdminDomain.upos.descriptions.transferTrackingEnabled':
    'Whether to send client_application_id in requests',
  'connectedStoresAdminDomain.upos.descriptions.dummyLoyaltyId':
    'A dummy loyalty ID used during the loyalty cache flush',
  'connectedStoresAdminDomain.upos.descriptions.priceEmbeddedCheckDigit':
    'The handling of the PLU check digit within price-embedded barcodes',
  'connectedStoresAdminDomain.upos.descriptions.instacartSecureLink':
    'Connection details for Instacart Secure Link',
} as const

export default EN_US
