import type { ChangeEvent, PropsWithChildren, ComponentType } from 'react'
import { Component } from 'react'
import styled from '@emotion/styled'
import { Button, ServerError } from 'ic-snacks'
import { FormattedMessage } from 'react-intl'
import Modal from '../../../../../components/Modal'
import Dropdown from '../../../../../components/Dropdown'
import instacart from '../../../../../common/instacart'
import { errors } from '../../../../../../utils/error-handling/errors'
import Slash from '../../../../../components/Slash'
import {
  withNotificationsContext,
  type NotificationsContextValue,
} from '../../../../../contexts/notifications/NotificationsContext'
import { type RegionLocationContextValue } from '../../../../../contexts/regionLocation/RegionLocationContext'
import TextField from '../../../../../components/Forms/TextField'
import deprecatedAnalytics from '../../../../../common/deprecatedAnalytics'
import { AsyncStatus } from '../../../../../common/types'
import { YES_NO_OPTIONS } from '../../../../../common/constants'
import { withTrackEventOnMount } from '../../../../../../utils/events/hocs'
import { COST_UNIT_OPTIONS, COST_UNIT } from '../../../../../common/utils/catalog/units'
import FormField from '../form-components/FormField'

const buttonStyle = {
  marginLeft: '10px',
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 320px;
`

interface Props
  extends NotificationsContextValue,
    Pick<RegionLocationContextValue, 'inventoryAreas' | 'inventoryAreaStatus'> {
  onClose: () => void
  partnerId: string
  warehouseId: string
  productId: string
}

interface State {
  isBusy: boolean
  isError: boolean
  inventoryAreaIds: number[]
  available: boolean
  taxable: boolean
  costPricePerUnit: string
  cost_unit: string
}

class AddProductToLocationsModal extends Component<Props, State> {
  readonly state: State = {
    isBusy: false,
    isError: false,
    inventoryAreaIds: [],
    available: false,
    taxable: false,
    costPricePerUnit: '',
    cost_unit: COST_UNIT.EACH,
  }

  handleClose = () => {
    this.props.onClose()
  }

  onSubmit = async () => {
    const { costPricePerUnit, inventoryAreaIds, taxable, available, cost_unit } = this.state
    if (!costPricePerUnit || !inventoryAreaIds) return

    this.setState({
      isBusy: true,
      isError: false,
    })

    const { partnerId, warehouseId, productId } = this.props

    try {
      await instacart.go.post(
        `/v1/partners/${partnerId}/warehouses/${warehouseId}/products/${productId}/items/bulk_create`,
        {
          data: {
            inventory_area_ids: inventoryAreaIds,
            available,
            taxable,
            cost_price_per_unit: parseFloat(costPricePerUnit),
            cost_unit,
          },
        }
      )
      deprecatedAnalytics.track('product.add_warehouse_location', {
        productId,
        source_value: 'new',
        inventory_area_ids: inventoryAreaIds,
      })
      this.props.notify(<FormattedMessage id="catalog.products.locations.requestSubmitted" />)
      this.props.onClose()
    } catch (e) {
      errors.captureException(e, `AddProductToLocationsModal with error + ${e}`)

      this.setState({
        isError: true,
        isBusy: false,
      })
    }
  }

  onInventoryAreasChange = (inventoryAreaIds: number[]) => {
    this.setState({
      inventoryAreaIds,
    })
  }

  onAvailableChange = (available: boolean) => {
    this.setState({
      available,
    })
  }

  onTaxableChange = (taxable: boolean) => {
    this.setState({
      taxable,
    })
  }

  onPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      costPricePerUnit: e.target.value,
    })
  }

  onCostUnitChange = (cost_unit: string) => {
    this.setState({
      cost_unit,
    })
  }

  // eslint-disable-next-line
  optionify(values: any[]) {
    return (values || []).map(({ id, name }) => ({
      label: name,
      value: id.toString(),
    }))
  }

  render() {
    const { inventoryAreaStatus, inventoryAreas } = this.props

    return (
      <Modal height={660} onClose={this.handleClose}>
        {(
          Header: ComponentType<PropsWithChildren<unknown>>,
          Body: ComponentType<PropsWithChildren<unknown>>,
          Footer: ComponentType<PropsWithChildren<unknown>>
        ) => [
          <Header>Add Product to Store Locations</Header>,
          <Body>
            <Container>
              <InnerContainer>
                {this.state.isError && (
                  <ServerError style={{ width: '100%' }} text="Please try again later" />
                )}
                <FormField label="Store Locations">
                  <Dropdown
                    isBusy={inventoryAreaStatus === AsyncStatus.Busy}
                    multiple
                    selectAll
                    filterable
                    defaultText="All Locations"
                    theme="dark"
                    options={this.optionify(inventoryAreas)}
                    value={this.state.inventoryAreaIds}
                    onChange={this.onInventoryAreasChange}
                  />
                </FormField>
                <FormField label="Available">
                  <Dropdown
                    theme="dark"
                    value={this.state.available}
                    onChange={this.onAvailableChange}
                    options={YES_NO_OPTIONS}
                  />
                </FormField>
                <FormField label="Taxable">
                  <Dropdown
                    theme="dark"
                    value={this.state.taxable}
                    onChange={this.onTaxableChange}
                    options={YES_NO_OPTIONS}
                  />
                </FormField>
                <FormField label="Price">
                  <TextField
                    name="costPricePerUnit"
                    prefix="$"
                    value={this.state.costPricePerUnit}
                    onChange={this.onPriceChange}
                  />
                  <Slash />
                  <Dropdown
                    width="130px"
                    theme="dark"
                    defaultText="Select Unit"
                    value={this.state.cost_unit}
                    onChange={this.onCostUnitChange}
                    options={COST_UNIT_OPTIONS}
                  />
                </FormField>
              </InnerContainer>
            </Container>
          </Body>,
          <Footer>
            <Button
              style={buttonStyle}
              onClick={this.handleClose}
              disabled={this.state.isBusy}
              snacksStyle="secondary"
            >
              Cancel
            </Button>
            <Button
              style={buttonStyle}
              onClick={this.onSubmit}
              disabled={this.state.isBusy}
              snacksStyle="primary"
            >
              Save
            </Button>
          </Footer>,
        ]}
      </Modal>
    )
  }
}

export default withTrackEventOnMount({
  id: 'catalog.products.store_locations.add_location.viewed',
  description: 'Viewed the add locations section of the product store locations page',
})(withNotificationsContext(AddProductToLocationsModal))
