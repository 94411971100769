const EN_US = {
  'cashflowDomain.title': 'Cashflow',

  // Price Audit Landing Page
  'cashflowDomain.priceAudit.title': 'Price audit',
  'cashflowDomain.priceAudit.cashflow.workflow.title': 'New Issues',
  'cashflowDomain.priceAudit.cashflow.escalated.workflow.title': 'New Escalated Issues',
  'cashflowDomain.priceAudit.refresh': 'Refresh',
  'cashflowDomain.priceAudit.startReviewing': 'Start Reviewing',
  'cashflowDomain.priceAudit.startReviewingEscalated': 'Start Reviewing Escalated',
  'cashflowDomain.priceAudit.viewFeedback': 'View Feedback',
  'cashflowDomain.priceAudit.errorTitle': 'Error',
  'cashflowDomain.priceAudit.errorBodyNoBatch': 'There are no issues to review',
  'cashflowDomain.priceAudit.errorBody.fetch.audit.batch':
    'Failed to fetch the issue. Please try again later.',
  'cashflowDomain.priceAudit.errorBody.submit.review':
    'Failed to submit the review. Please try again later.',
  'cashflowDomain.priceAudit.errorBody.fetch.immutable.item':
    'Failed to fetch the product details. Please try again later.',
  'cashflowDomain.feedback.errorTitle': 'Error',
  'cashflowDomain.feedback.errorBody': 'Failed to retrieve feedback. Please try again later.',

  // Price Audit Details Page
  'cashflowDomain.priceAudit.details.page.error.audit.batch.not.found':
    'No issue found: {auditReviewUuid}',
  'cashflowDomain.priceAudit.details.page.completed':
    'This issue has been reviewed. Would you like to review the next one?',
  'cashflowDomain.priceAudit.details.page.error.action.label.go.back': 'Go Back',
  'cashflowDomain.priceAudit.details.page.error.action.label.review.next': 'Review Next',
  'cashflowDomain.priceAudit.header.title': 'Issue #{auditReviewUuid}',
  'cashflowDomain.priceAudit.header.button.exit': 'Exit',
  'cashflowDomain.priceAudit.header.button.resolve': 'Resolve',
  'cashflowDomain.priceAudit.header.button.resolve.escalated': 'Perform QA',
  'cashflowDomain.priceAudit.label.completed': 'Completed',
  'cashflowDomain.priceAudit.label.escalated': 'QA',
  'cashflowDomain.priceAudit.item.details.copyToClipboard': 'Copied to clipboard!',
  'cashflowDomain.priceAudit.item.details.title.item.details': 'Item Details',
  'cashflowDomain.priceAudit.item.details.title.order.summary': 'Order Summary',
  'cashflowDomain.priceAudit.item.details.label.cost.unit': 'Cost unit',
  'cashflowDomain.priceAudit.item.details.label.unit.price': 'Unit price',
  'cashflowDomain.priceAudit.item.details.label.loyalty.price': 'Loyalty price',
  'cashflowDomain.priceAudit.item.details.label.sale.price': 'Sale price',
  'cashflowDomain.priceAudit.item.details.label.store': 'Store',
  'cashflowDomain.priceAudit.item.details.label.order.created.at': 'Created at',
  'cashflowDomain.priceAudit.item.details.label.order.fulfilled.at': 'Delivered at',
  'cashflowDomain.priceAudit.item.details.label.order.id': 'Order id',
  'cashflowDomain.priceAudit.item.details.label.item.price.on.receipt': 'Item price on receipt',
  'cashflowDomain.priceAudit.item.details.label.overspend': 'Overspend',
  'cashflowDomain.priceAudit.item.details.label.item.price': 'Item price',
  'cashflowDomain.priceAudit.item.details.label.upstreamClassifications':
    'Upstream classifications',
  'cashflowDomain.priceAudit.item.details.label.pickedVia': 'Picked via',
  'cashflowDomain.priceAudit.item.details.label.pickedVia.tooltip':
    'How the item was picked, Button or Scanner. Force Button means the shopper had to scan the item but decided against it.',
  'cashflowDomain.priceAudit.item.details.upstreamClassifications.price.price.greater':
    'Incorrect Price (POS > Order): Price charged at in-store POS is higher than the Instacart order price',
  'cashflowDomain.priceAudit.item.details.upstreamClassifications.price.ic.greater':
    'Incorrect Price (POS < Order): Price charged at in-store POS is lower than Instacart order price',
  'cashflowDomain.priceAudit.item.details.upstreamClassifications.weight.iccatalog.weight':
    'Unit Mismatch (POS Quantity < Order Weight): POS priced by quantity, order priced by weight, resulting in lower POS price',
  'cashflowDomain.priceAudit.item.details.upstreamClassifications.weight.iccatalog.qty':
    'Unit Mismatch (POS Weight < Order Quantity): POS priced by weight, order priced by quantity, resulting in lower POS price',
  'cashflowDomain.priceAudit.item.details.upstreamClassifications.weight.iccatalogwgt_posqty':
    'Unit Mismatch (POS Quantity > Order Weight): POS priced by quantity, order priced by weight, resulting in higher POS price',
  'cashflowDomain.priceAudit.item.details.upstreamClassifications.weight.iccatalogqty.posweight':
    'Unit Mismatch (POS Weight > Order Quantity): POS priced by weight, order priced by quantity, resulting in higher POS price',
  'cashflowDomain.priceAudit.priceComparison.table.noResults': 'No Price History Found',
  'cashflowDomain.priceAudit.priceComparison.table.title.catalog': 'Published Catalog Item History',
  'cashflowDomain.priceAudit.priceComparison.table.title.retailer': 'Data Sent from Retailer',
  'cashflowDomain.priceAudit.priceComparison.table.column.timestamp': 'Timestamp',
  'cashflowDomain.priceAudit.priceComparison.table.column.costUnit': 'Cost Unit',
  'cashflowDomain.priceAudit.priceComparison.table.column.price': 'Price',
  'cashflowDomain.priceAudit.priceComparison.table.column.loyaltyPrice': 'Loyalty Price (Dates)',
  'cashflowDomain.priceAudit.priceComparison.table.column.salePrice': 'Sale Price (Dates)',
  'cashflowDomain.priceAudit.priceComparison.table.row.orderCreated': 'Order Created',
  'cashflowDomain.priceAudit.priceComparison.table.row.orderDelivered': 'Order Delivered',
  'cashflowDomain.priceAudit.error.message.json.parsing.error':
    'Error parsing cashflow audit input data',
  'cashflowDomain.priceAudit.error.message.json.parsing.metadata': 'Invalid batch metadata',

  // Price Audit Details - Completed
  'cashflowDomain.priceAudit.completed.title': 'Agent-classified Discrepancies',
  'cashflowDomain.priceAudit.completed.classification': 'Classification',
  'cashflowDomain.priceAudit.completed.correctionType': 'Correction Type/Unit',
  'cashflowDomain.priceAudit.completed.correctionValue': 'Correction Value',
  'cashflowDomain.priceAudit.completed.comment': 'Comment',
  'cashflowDomain.priceAudit.completed.noData': 'N/A',

  // Price Audit Details - Escalated
  'cashflowDomain.priceAudit.completed.escalated.title': 'QA-classified Discrepancies',

  // Price Audit Feedback Page
  'cashflowDomain.priceAudit.feedback.title': 'Price Audit Feedback',
  'cashflowDomain.priceAudit.feedback.grid.column.auditReviewUuid': 'Issue',
  'cashflowDomain.priceAudit.feedback.grid.column.completedAt': 'Review Completed On',
  'cashflowDomain.priceAudit.feedback.grid.column.escalatedCompletedAt': 'Feedback Provided On',
  'cashflowDomain.priceAudit.feedback.noFeedback': 'No Feedback Provided Yet',
  'cashflowDomain.priceAudit.feedback.errorTitle': 'Error',
  'cashflowDomain.priceAudit.feedback.errorBody': 'error fetching feedback',
  'cashflowDomain.priceAudit.feedback.errorMessage': 'Error loading feedback:',
  'cashflowDomain.priceAudit.feedback.goBack': 'Go Back',

  // Debug Footer
  'cashflowDomain.priceAudit.debugFooter.title': 'Debugging Links',
  'cashflowDomain.priceAudit.debugFooter.catalogItem': 'Catalog Item',
  'cashflowDomain.priceAudit.debugFooter.receiptImages': 'Receipt Images',
  'cashflowDomain.priceAudit.debugFooter.order': 'Order',

  // Spread Landing Page
  'cashflowDomain.spreadAudit.title': 'Spread audit',
  'cashflowDomain.spreadAudit.refresh': 'Refresh',
  'cashflowDomain.spreadAudit.startReviewing': 'Start Reviewing',
  'cashflowDomain.spreadAudit.errorTitle': 'Error',
  'cashflowDomain.spreadAudit.errorBodyNoBatch': 'There are no issues to review',
  'cashflowDomain.spreadAudit.workflow.title': 'New Issues',

  // Spread Details Page
  'cashflowDomain.spreadAudit.details.header.title': 'Issue #{riskIssueId}',
  'cashflowDomain.spreadAudit.details.header.button.exit': 'Exit',
  'cashflowDomain.spreadAudit.details.header.button.report': 'Report',
  'cashflowDomain.spreadAudit.details.header.button.submit': 'Submit',

  'cashflowDomain.spreadAudit.details.header.tabs.primary': 'Primary',
  'cashflowDomain.spreadAudit.details.header.tabs.order': 'Order {orderSequence}',

  'cashflowDomain.spreadAudit.details.supplementary.tabs.receipt': 'Receipt',
  'cashflowDomain.spreadAudit.details.supplementary.tabs.chat': 'Chat',
  'cashflowDomain.spreadAudit.details.supplementary.tabs.notes': 'Notes',
  'cashflowDomain.spreadAudit.details.supplementary.tabs.marqueta': 'Marqueta',

  'cashflowDomain.spreadAudit.details.order.title': 'Order',
  'cashflowDomain.spreadAudit.details.order.more': 'More',
  'cashflowDomain.spreadAudit.details.order.total': 'Est. total (actual total)',
  'cashflowDomain.spreadAudit.details.order.overspend': 'Overspend',
  'cashflowDomain.spreadAudit.details.order.type': 'Type',
  'cashflowDomain.spreadAudit.details.order.chat': 'Customer/Shopper chat',
  'cashflowDomain.spreadAudit.details.order.contactHistory': 'Care contact history',
  'cashflowDomain.spreadAudit.details.order.careContact': 'Care Contact',

  'cashflowDomain.spreadAudit.details.search.addCharge': 'Add Charge',
  'cashflowDomain.spreadAudit.details.search.input.placeholder': 'Search items to add',

  'cashflowDomain.spreadAudit.details.badReceipt': 'Bad Receipt',

  'cashflowDomain.spreadAudit.details.order.details.card.order.overview': 'Order overview',
  'cashflowDomain.spreadAudit.details.order.details.card.total.spread': 'Total spread',
  'cashflowDomain.spreadAudit.details.order.details.card.instacart.total': 'Instacart total',
  'cashflowDomain.spreadAudit.details.order.details.card.retailer.total': 'Retailer total',

  // Resolve Issue Modal Form
  'cashflowDomain.priceAudit.resolve.issue.form.title': 'Resolve Issue',
  'cashflowDomain.priceAudit.resolve.issue.form.entry.title': 'Cause of Discrepancy',

  'cashflowDomain.priceAudit.resolve.issue.form.label.discrepancy.type': 'Discrepancy Type',
  'cashflowDomain.priceAudit.resolve.issue.form.label.discrepancy.type.required':
    'Discrepancy type is required',
  'cashflowDomain.priceAudit.resolve.issue.form.label.correct.price': 'Correct Price',
  'cashflowDomain.priceAudit.resolve.issue.form.label.correct.price.required':
    'Correct price is required',
  'cashflowDomain.priceAudit.resolve.issue.form.label.correct.price.type': 'Price Type',
  'cashflowDomain.priceAudit.resolve.issue.form.label.correct.price.type.required':
    'Price type is required',
  'cashflowDomain.priceAudit.resolve.issue.form.label.correct.cost.unit': 'Correct Cost Unit',
  'cashflowDomain.priceAudit.resolve.issue.form.label.correct.cost.unit.required':
    'Correct cost unit is required',
  'cashflowDomain.priceAudit.resolve.issue.form.label.notes': 'Notes',
  'cashflowDomain.priceAudit.resolve.issue.form.label.optional': ' (optional)',
  'cashflowDomain.priceAudit.resolve.issue.form.price.validator.invalidFormat':
    'Price must be a valid number with up to 2 decimal places',
  'cashflowDomain.priceAudit.resolve.issue.form.price.validator.lessThanZero':
    'Price must be greater than 0',

  // Discrepancy types -- labels and descriptions
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceRetailerData.label':
    'Price Error: Retailer Data Incorrect',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceRetailerData.description':
    'Incorrect base, loyalty, or sale price provided by retailer.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceProcessing.label':
    'Price Error: Altered During Processing',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceProcessing.description':
    'Correct price submitted by retailer, but catalog displays it differently.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceChangedAfterOrderPlacement.label':
    'Price Change: Post-Order Adjustment',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceChangedAfterOrderPlacement.description':
    'Price was correct when ordered, but altered by retailer prior to fulfillment.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitRetailerData.label':
    'Cost Unit Error: Retailer Data Incorrect',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitRetailerData.description':
    'Retailer submitted incorrect unit cost, e.g., "weight" instead of "each" or vice versa.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitProcessing.label':
    'Cost Unit Error: Altered During Processing',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitProcessing.description':
    'Correct cost unit submitted by retailer, but catalog displays it differently.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitChangedAfterOrderPlacement.label':
    'Cost Unit Change: Post-Order Adjustment',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitChangedAfterOrderPlacement.description':
    'Cost unit was correct when ordered, but altered by retailer prior to fulfillment.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceTypeMismatch.label':
    'Price Type Mismatch',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceTypeMismatch.description':
    'Price type inconsistency between catalog and retailer or customer (e.g., loyalty price issues).',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceNoRetailerData.label':
    'Price Error: No Retailer Data',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.priceNoRetailerData.description':
    'No retailer data submitted, incorrect price generated by catalog.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitNoRetailerData.label':
    'Cost Unit Error: No Retailer Data',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.costUnitNoRetailerData.description':
    'No retailer data submitted, incorrect cost unit generated by catalog.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.noDiscrepancyPricesMatch.label':
    'No Discrepancy: Prices Match',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.noDiscrepancyPricesMatch.description':
    'Catalog price and in-store price match.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.unknownItemNotOnReceipt.label':
    'Unknown: Item Not On Receipt',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.unknownItemNotOnReceipt.description':
    'Item not found on receipt due to potential batch mix-up or incorrect item purchased.',

  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.unknownBadReceipt.label':
    'Unknown: Bad Receipt',
  'cashflowDomain.priceAudit.resolve.issue.form.discrepancy.type.options.unknownBadReceipt.description':
    'Receipt torn, folded, or otherwise unreadable.',

  'cashflowDomain.priceAudit.resolve.issue.form.cost.unit.options.each': 'Each',
  'cashflowDomain.priceAudit.resolve.issue.form.cost.unit.options.lb': 'Pound (lb)',
  'cashflowDomain.priceAudit.resolve.issue.form.cost.unit.options.kg': 'Kilogram (kg)',
  'cashflowDomain.priceAudit.resolve.issue.form.correct.price.type.options.base': 'Base',
  'cashflowDomain.priceAudit.resolve.issue.form.correct.price.type.options.loyalty': 'Loyalty',
  'cashflowDomain.priceAudit.resolve.issue.form.correct.price.type.options.sale': 'Sale',
  'cashflowDomain.priceAudit.resolve.issue.form.submit.button': 'Submit and view next',
  'cashflowDomain.priceAudit.resolve.issue.form.submit.button.escalated':
    'Confirm QA and view next',
  'cashflowDomain.priceAudit.resolve.issue.form.submit.duplicate.error':
    'Cannot submit with duplicate discrepancy type',
  'cashflowDomain.priceAudit.resolve.issue.form.submit.noDiscrepancy.error':
    'Only one discrepancy type is allowed when selecting No Discrepancy or Unknown',
  'cashflowDomain.priceAudit.resolve.issue.form.submit.multiplePriceDiscrepancy.error':
    'Cannot submit with multiple price discrepancies',
  'cashflowDomain.priceAudit.resolve.issue.form.submit.multipleCostUnitDiscrepancy.error':
    'Cannot submit with multiple cost unit discrepancies',
} as const

export default EN_US
