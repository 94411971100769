import type { PropsWithChildren, FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@retailer-platform/shared-components/src/tds'
import { LocationSelectorActions } from '../utils/locationSelector.types'
import { type OnClick } from '../../../../../../../../utils/react/event.types'

interface Props {
  onClick: OnClick
  actionType: LocationSelectorActions
}

export const StoreGroupsFormLocationSelectorToggle: FunctionComponent<PropsWithChildren<Props>> = ({
  onClick,
  actionType,
}) => {
  if (actionType === LocationSelectorActions.Remove) {
    return (
      <Button
        compact
        variant="detrimental-secondary"
        onClick={onClick}
        data-testid="store-location-selector-action-remove"
      >
        <FormattedMessage id={`common.remove`} />
      </Button>
    )
  }

  return (
    <Button
      compact
      variant="secondary"
      onClick={onClick}
      data-testid="store-location-selector-action-add"
    >
      <FormattedMessage id={`common.add`} />
    </Button>
  )
}
