import { ApolloLink, type NextLink, Observable } from '@apollo/client'
import { type GraphQLError, type GraphQLFormattedError } from 'graphql'
import { type EnterpriseGraphQLError, transformGraphQLError } from './errors'
import { type ZenObservable } from 'zen-observable'

function transformErrors(
  errors: ReadonlyArray<GraphQLError>
): ReadonlyArray<EnterpriseGraphQLError> {
  return errors.map(error => transformGraphQLError(error))
}

type ErrorTransformer = (
  errors: ReadonlyArray<GraphQLFormattedError>
) => ReadonlyArray<EnterpriseGraphQLError>

/**
 * Wondering how this works?
 * https://www.apollographql.com/docs/link/overview/
 */

export const makeErrorsTransformLink = (errorTransformer: ErrorTransformer = transformErrors) =>
  new ApolloLink((operation, forward) => {
    return new Observable(observer => {
      let sub: ZenObservable.Subscription

      try {
        sub = (forward as NextLink)(operation).subscribe({
          next: result => {
            observer.next({
              ...result,
              errors: result.errors ? errorTransformer(result.errors) : undefined,
            })
          },
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        })
      } catch (e) {
        observer.error(e)
      }

      return () => {
        if (sub) sub.unsubscribe()
      }
    })
  })

export default makeErrorsTransformLink()
