import { type RPPDomain } from '@retailer-platform/dashboard/core'
import {
  AdminNavEntryPoint,
  AdminNavEntryPointV2,
  NavEntryPoint,
  NavItem,
} from '@retailer-platform/dashboard/routing'
import { onboardingAdminRouter } from './routing/onboardingAdminRouter'
import { onboardingRouter } from './routing/onboardingRouter'
import { onboardingStoreConfigScopedRouter } from './routing/onboardingStoreConfigScopedRouter.tsx'
import { onboardingPublicRouter } from './routing/onboardingPublicRouter'
import { type DomainMessages } from './utils/domain/intl'
import { routes } from './routing/routes'
import EN_US from './translations/en-US'
import { type DomainAccessControlConfig } from './utils/domain/accessControl'
import { onboardingSiteSetupAccessControl } from './access-control/onboardingAccess.configuration.ts'

const definition: RPPDomain<typeof routes, DomainMessages, DomainAccessControlConfig> = {
  id: 'onboarding',
  totem: {
    entity: 'onboarding-dashboard-tool',
  },
  routes,
  translations: { EN_US },
  routers: {
    scoped: [onboardingStoreConfigScopedRouter, onboardingRouter],
    admin: [onboardingAdminRouter],
    public: [onboardingPublicRouter],
  },
  navigationEntries: [
    {
      attachTo: NavItem.Storefront,
      attachToV2: NavEntryPoint.UserMenuSettings,
      subNavItems: [
        {
          type: 'item',
          labelId: 'onboardingDomain.siteSetup.navLabel',
          route: 'onboarding-site-setup',
          accessControl: onboardingSiteSetupAccessControl,
          id: 'onboarding-site-setup',
          positionNavV2: 7, // after the 'Storefront Onboarding' options
          subRoutes: ['onboarding-site-setup-review'],
        },
      ],
    },
  ],
  adminNavigationEntries: [
    {
      attachTo: AdminNavEntryPoint.Merchandising,
      attachToV2: AdminNavEntryPointV2.Operations,
      subNavItems: [
        {
          route: 'app-admin-onboarding-site-overview',
          labelId: 'onboardingDomain.siteOverview.navLabel',
          accessControl: onboardingSiteSetupAccessControl,
          positionNavV2: 10, // The nav entry point might need to be adjusted
        },
      ],
    },
  ],
}

export default definition
