import { URL_APP_ADMIN, URL_PARTNER_BASE } from '../../../utils/routing/routes'

export const LOCAL_STORAGE_REDIRECT_KEY = 'defaultRedirectOverride'

export const consumeStoredRedirectUrl = () => {
  const target = sessionStorage.getItem(LOCAL_STORAGE_REDIRECT_KEY)
  sessionStorage.removeItem(LOCAL_STORAGE_REDIRECT_KEY)

  return target
}

interface StoreRedirectUrlProps {
  pathname: Location['pathname']
  search?: Location['search']
  hash?: Location['hash']
}

export const storeRedirectUrl = ({ pathname, search, hash }: StoreRedirectUrlProps) => {
  if (![URL_PARTNER_BASE, URL_APP_ADMIN].some(prefix => pathname.startsWith(prefix))) return

  sessionStorage.setItem(
    LOCAL_STORAGE_REDIRECT_KEY,
    `${pathname}${search ? search : ''}${hash ? hash : ''}`
  )
}

export const storeCurrentRedirectUrl = () => {
  storeRedirectUrl({
    pathname: window.location.pathname,
    search: window.location.search,
    hash: window.location.hash,
  })
}
