import { type DomainAccessControlConfig } from '../utils/domain/accessControl'
import { Permission } from './permissions'
import { FeatureToggle } from './featureToggles'

export const ordersOperationalDashboardDashboardAccessControl: DomainAccessControlConfig = [
  {
    permissions: [Permission.OrdersV4View],
    featureToggles: [
      FeatureToggle.ordersOperationalDashboard,
      FeatureToggle.ordersOperationalDashboardSearchAndFiltersImprovements,
    ],
  },
  {
    permissions: [Permission.OrdersV4View],
    warehouseFeatureToggles: [
      FeatureToggle.ordersOperationalDashboard,
      FeatureToggle.ordersOperationalDashboardSearchAndFiltersImprovements,
    ],
  },
]
