import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import {
  onboardingAccessControl,
  onboardingRetailerProfileViewAccessControl,
  onboardingSiteSetupAccessControl,
} from '../access-control/onboardingAccess.configuration'

const OnboardingLaunchChecklistPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingLaunchChecklistPage" */
      '../pages/launch-checklist/OnboardingLaunchChecklistPage'
    )
)
const OnboardingRetailerSettingsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingRetailerProfileSettingsPage" */
      '../pages/retailer-profile-settings/OnboardingRetailerProfileSettingsPage'
    )
)
const OnboardingStoreManagementPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingStoreManagementPage" */
      '../pages/store-management/OnboardingStoreManagementPage'
    )
)
const OnboardingInitialSetupPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingInitialSetupPage" */
      '../pages/initial-setup/OnboardingInitialSetupPage'
    )
)
const OnboardingStoreDetailPage = lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingStoreDetailPage" */
      '../pages/store-detail/OnboardingStoreDetailPage'
    )
)
const OnboardingSiteSetupFormPage = lazy(
  () => import('../pages/site-setup-form/OnboardingSiteSetupFormPage')
)
const OnboardingSiteSetupReviewPage = lazy(
  () => import('../pages/site-setup-review/OnboardingSiteSetupReviewPage')
)
// @hygen:inject page-import

export const onboardingRouter = (
  <DomainRoute route="onboarding" accessControl={onboardingAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="onboarding-launch-checklist"
        component={OnboardingLaunchChecklistPage}
        scopePicker={{
          titles: ['onboardingDomain.launchChecklist.header'],
        }}
      />
      <DomainRoute
        exact
        route="onboarding-retailer-settings"
        component={OnboardingRetailerSettingsPage}
        scopePicker={{
          titles: ['onboardingDomain.retailerProfileSetting.header'],
        }}
        accessControl={onboardingRetailerProfileViewAccessControl}
      />
      <DomainRoute
        exact
        route="onboarding-store-management"
        component={OnboardingStoreManagementPage}
        scopePicker={{
          titles: ['onboardingDomain.storeManagement.header'],
        }}
      />
      <DomainRoute exact route="onboarding-initial-setup" component={OnboardingInitialSetupPage} />
      <DomainRoute exact route="onboarding-store-detail" component={OnboardingStoreDetailPage} />
      <DomainRoute
        exact
        route="onboarding-site-setup-form"
        component={OnboardingSiteSetupFormPage}
        accessControl={onboardingSiteSetupAccessControl}
      />
      <DomainRoute
        exact
        route="onboarding-site-setup-review"
        component={OnboardingSiteSetupReviewPage}
        accessControl={onboardingSiteSetupAccessControl}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
