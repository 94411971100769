import type { PropsWithChildren, FunctionComponent, ComponentType } from 'react'
import { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, ButtonGroup, Text } from '@retailer-platform/shared-components/src/tds'
import styled from '@emotion/styled'
import Modal from '../../../../../components/Modal/Modal'

const FooterStyles = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

interface Props {
  storeGroupName: string
  retailerLocationCount: number
  onConfirm(): void
  onClose(): void
}

export const StoreGroupsListConfirmDeleteModal: FunctionComponent<PropsWithChildren<Props>> = ({
  storeGroupName,
  retailerLocationCount,
  onClose,
  onConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm()
    onClose()
  }

  return (
    <Modal width="500px" height="300px" onClose={onClose}>
      {(
        Header: ComponentType<PropsWithChildren<unknown>>,
        Body: ComponentType<PropsWithChildren<unknown>>,
        Footer: ComponentType<PropsWithChildren<unknown>>
      ) => (
        <Fragment>
          <Header>
            <Text typography="subtitle">
              <FormattedMessage
                id="settings.storeGroups.deleteModal.title"
                values={{ storeGroupName }}
              />
            </Text>
          </Header>
          <Body>
            <Text typography="bodyRegular">
              <FormattedMessage
                id="settings.storeGroups.deleteModal.body"
                values={{ retailerLocationCount }}
              />
            </Text>
          </Body>
          <Footer>
            <FooterStyles>
              <ButtonGroup>
                <Button
                  variant="secondary"
                  onClick={onClose}
                  data-testid="delete-modal-cancel-button"
                >
                  <FormattedMessage id="cancel" />
                </Button>

                <Button
                  variant="primary"
                  onClick={handleConfirm}
                  style={{ marginLeft: 30 }}
                  data-testid="delete-modal-confirm-button"
                >
                  <FormattedMessage id="delete" />
                </Button>
              </ButtonGroup>
            </FooterStyles>
          </Footer>
        </Fragment>
      )}
    </Modal>
  )
}
