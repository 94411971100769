/* eslint-disable react/no-unused-state */
import type { PropsWithChildren } from 'react'

import { Component } from 'react'
import mem from 'mem'
import { errors } from '../../../utils/error-handling/errors'
import { fetchInventoryAreas } from '../../common/api'

import withRetailerRouter from '../../common/withRouter'
import { type ScopedRouteParams, AsyncStatus } from '../../common/types'
import { type RetailerRouteComponentProps } from '../../components/RetailerRoute'
import {
  WarehouseContext,
  type WarehouseContextValue,
} from '../../../utils/contexts/warehouse/WarehouseContext'
import { RegionLocationContext, type RegionLocationContextValue } from './RegionLocationContext'

function getLocationStatus(warehouseContext: WarehouseContextValue) {
  if (warehouseContext.loaded) {
    return AsyncStatus.Done
  }

  if (warehouseContext.loading) {
    return AsyncStatus.Busy
  }

  if (warehouseContext.error) {
    return AsyncStatus.Fail
  }

  return AsyncStatus.Initial
}

/**
 * Using this to memoize the context value computed using
 * state + the warehouse context. This is to prevent
 * unnecessary updated by building this object every render
 */
const getContextValue = mem(
  // still doing this with the args for ie11 support...
  // @TODO remove when we have a proper handling in place for browser support
  // and fully remove support for ie11
  (...args: [State, WarehouseContextValue]): RegionLocationContextValue => {
    const [state, warehouseContext] = args
    const warehouseLocations = warehouseContext.loaded
      ? warehouseContext.warehouse.warehouseLocations
      : []

    const allWarehouseLocations = warehouseContext.loaded
      ? [
          ...warehouseContext.warehouse.warehouseLocations,
          ...warehouseContext.warehouse.inactiveWarehouseLocations,
        ]
      : []

    return {
      ...state,
      locationStatus: getLocationStatus(warehouseContext),
      locations: warehouseLocations,
      warehouseLocations,
      allWarehouseLocations,
    }
  }
)

interface RouteQuery {
  region_ids?: string[]
}

interface RouteParams extends ScopedRouteParams {}

interface Props extends PropsWithChildren<RetailerRouteComponentProps<RouteParams, RouteQuery>> {}

interface State extends RegionLocationContextValue {}

class RegionLocationProvider extends Component<Props, State> {
  readonly state = {
    regionStatus: AsyncStatus.Initial,
    locationStatus: AsyncStatus.Initial,
    inventoryAreaStatus: AsyncStatus.Initial,
    regions: [],
    // locations == warehouseLocations
    // leaving for backwards compatibility
    // TODO: clean this up
    locations: [],
    warehouseLocations: [],
    inventoryAreas: [],
    allWarehouseLocations: [],
  }

  componentDidMount() {
    this.fetchInventoryAreas()
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.match.params.partner_id !== this.props.match.params.partner_id ||
      prevProps.match.params.warehouse_id !== this.props.match.params.warehouse_id
    ) {
      this.fetchInventoryAreas()
    }
  }

  async fetchInventoryAreas() {
    const { partner_id, warehouse_id } = this.props.match.params
    this.setState({
      inventoryAreaStatus: AsyncStatus.Busy,
    })

    try {
      const { data: inventoryAreas } = await fetchInventoryAreas(partner_id, warehouse_id, {
        region_ids: this.props.query.region_ids,
      })
      this.setState({
        inventoryAreas,
        inventoryAreaStatus: AsyncStatus.Done,
      })
    } catch (e) {
      errors.captureException(e, `fetchInventoryAreas with error + ${e}`)
      this.setState({
        inventoryAreaStatus: AsyncStatus.Fail,
      })
    }
  }

  render() {
    return (
      <WarehouseContext.Consumer>
        {warehouseContext => {
          if (!warehouseContext) {
            throw new Error('WarehouseContext not provided')
          }

          return (
            <RegionLocationContext.Provider value={getContextValue(this.state, warehouseContext)}>
              {this.props.children}
            </RegionLocationContext.Provider>
          )
        }}
      </WarehouseContext.Consumer>
    )
  }
}

export default withRetailerRouter(RegionLocationProvider)
