const EN_US = {
  'retailerCollectionsDomain.title': 'Retailer Collections',
  'retailerCollectionsDomain.featureNotEnabled': 'This feature is not enabled for this retailer.',
  'retailerCollectionsDomain.failedLoadingConfig':
    'There was an issue obtaining your retailer configuration.',
  'retailerCollectionsDomain.serviceErrors.default':
    'An error has occurred. Please try again later.',
  'retailerCollectionsDomain.serviceErrors.COLLECTION_SLUG_NOT_UNIQUE':
    'The given slug is already in use. Please choose another.',

  'retailerCollectionsDomain.countAlert.info':
    '{numCollections}/{maxCollections} active custom top level collections on {surfaceName}',
  'retailerCollectionsDomain.countAlert.warning':
    "{numCollections}/{maxCollections} active custom top level collections on {surfaceName}. You won't be able to create more.",
  'retailerCollectionsDomain.list.name': 'Name',
  'retailerCollectionsDomain.list.products': 'Products',
  'retailerCollectionsDomain.list.surface': 'Surface',
  'retailerCollectionsDomain.list.owner': 'Owner',
  'retailerCollectionsDomain.list.effectiveDates': 'Effective Dates',
  'retailerCollectionsDomain.list.lastUpdates': 'Last Updates',
  'retailerCollectionsDomain.list.status': 'Status',
  'retailerCollectionsDomain.list.noResults': 'No collections to display',
  'retailerCollectionsDomain.list.retailer': 'Retailer',
  'retailerCollectionsDomain.list.retailerOwned': 'Custom',
  'retailerCollectionsDomain.list.instacartOwned': 'Instacart',
  'retailerCollectionsDomain.list.banners': 'Banners',
  'retailerCollectionsDomain.list.collectionType': 'Collection type',
  'retailerCollectionsDomain.surface.marketplace': 'Marketplace',
  'retailerCollectionsDomain.surface.storefront': 'Storefront',
  'retailerCollectionsDomain.surface.storefrontPro': 'Storefront Pro',
  'retailerCollectionsDomain.view.details': 'Details',
  'retailerCollectionsDomain.view.list': 'Included Collections',
  'retailerCollectionsDomain.view.products': 'Included Products',
  'retailerCollectionsDomain.view.products.notAvailable': 'N/A',
  'retailerCollectionsDomain.view.products.notAvailableHint':
    'This product currently does not match anything we have in the storefront, and it will not be shown.',
  'retailerCollectionsDomain.view.products.columns.productName': 'Product name',
  'retailerCollectionsDomain.view.products.columns.upc': 'UPC',
  'retailerCollectionsDomain.view.products.empty.header': 'This collection is empty',
  'retailerCollectionsDomain.view.products.empty.body': 'Add products to this collection',
  'retailerCollectionsDomain.view.products.empty.action.searchCatalog': 'Search Catalog',
  'retailerCollectionsDomain.view.products.empty.action.enterUPCCodes': 'Enter UPC / RRC Codes',
  'retailerCollectionsDomain.view.products.confirm.delete.confirmHeader': 'Confirm Product Removal',
  'retailerCollectionsDomain.view.products.confirm.delete.content':
    'Are you sure you want to delete {productName} from the collection?',
  'retailerCollectionsDomain.view.products.confirm.delete.confirmLabel': 'Delete',
  'retailerCollectionsDomain.view.products.confirm.delete.cancelLabel': 'Cancel',
  'retailerCollectionsDomain.view.products.tooltip.delete': 'Delete',
  'retailerCollectionsDomain.view.collection.contextMenu.description':
    'Retailer Collection context menu',
  'retailerCollectionsDomain.view.collection.contextMenu.edit': 'Edit',
  'retailerCollectionsDomain.view.collection.contextMenu.setActive': 'Set Active',
  'retailerCollectionsDomain.view.collection.contextMenu.setAdminActive': 'Set Active Admin',
  'retailerCollectionsDomain.view.collection.contextMenu.setInactive': 'Set Inactive',
  'retailerCollectionsDomain.view.collection.contextMenu.pinCollection': 'Pin to the top',
  'retailerCollectionsDomain.view.collection.contextMenu.unpinCollection': 'Unpin from top',
  'retailerCollectionsDomain.view.collection.contextMenu.success':
    'Successfully updated collection',
  'retailerCollectionsDomain.view.collection.contextMenu.error': 'Error updating collection',
  'retailerCollectionsDomain.view.collection.contextMenu.deleteSuccess':
    'Successfully deleted collection',
  'retailerCollectionsDomain.view.collection.contextMenu.deleteError': 'Error updating collection',
  'retailerCollectionsDomain.view.collection.contextMenu.duplicate': 'Duplicate',
  'retailerCollectionsDomain.view.collection.contextMenu.delete': 'Delete',
  'retailerCollectionsDomain.view.collection.contextMenu.confirm.delete.confirmHeader':
    'Confirm Collection Removal',
  'retailerCollectionsDomain.view.collection.contextMenu.confirm.delete.content':
    'Are you sure you want to delete collection {collectionName}? This will also remove child collections and any associated products',
  'retailerCollectionsDomain.view.collection.contextMenu.confirm.delete.confirmLabel': 'Delete',
  'retailerCollectionsDomain.view.collection.contextMenu.confirm.delete.cancelLabel': 'Cancel',
  'retailerCollectionsDomain.view.products.contextMenu.label': 'Add Products',
  'retailerCollectionsDomain.view.products.contextMenu.description':
    'Retailer Collection Products context menu',
  'retailerCollectionsDomain.view.products.contextMenu.searchCatalog': 'Search Catalog',
  'retailerCollectionsDomain.view.products.contextMenu.enterUPCCodes': 'Enter UPC / RRC Codes',
  'retailerCollectionsDomain.view.collections.contextMenu.label': 'Add Collection',
  'retailerCollectionsDomain.view.collections.contextMenu.createNew': 'Create New',
  'retailerCollectionsDomain.view.collections.contextMenu.createBulk': 'Create Bulk',
  'retailerCollectionsDomain.view.collections.errors.input.name': 'Name is required',
  'retailerCollectionsDomain.view.collections.errors.input.slug': 'Collection ID is required',
  'retailerCollectionsDomain.view.collections.errors.input.slug.invalid': `Collection ID must be unique`,
  'retailerCollectionsDomain.view.collections.errors.dynamic.invalidFiltersOnSubmit': `Please include at least one filter`,
  'retailerCollectionsDomain.view.collections.errors.dynamic.invalidPriceFilter.minLargerThanMax': `Invalid price filter: min value cannot be greater than max value`,
  'retailerCollectionsDomain.view.collections.errors.dynamic.invalidPriceFilter.notNumber': `Invalid price filter: min and max values must be numbers`,
  'retailerCollectionsDomain.view.collections.errors.dynamic.HasProducts':
    'Dynamic collection should not pass products',
  'retailerCollectionsDomain.view.collections.errors.static.invalidFiltersOnSubmit':
    'Static collection should not show filters',
  'retailerCollectionsDomain.view.collections.errors.static.invalidProductOnSubmit':
    'Product must have at least a Lookup Code or RRC. Please remove invalid products from the list.',
  'retailerCollectionsDomain.addProductsByUPC.modal.title': 'Add Products By UPC / RRC',
  'retailerCollectionsDomain.addProductsByUPC.modal.addTo': 'Add products to',
  'retailerCollectionsDomain.addProductsByUPC.modal.addCodes': 'Add product codes',
  'retailerCollectionsDomain.addProductsByUPC.modal.selectProductCodeType':
    'Select the type of product code you are adding',
  'retailerCollectionsDomain.addProductsByUPC.modal.hint':
    'Add all of the product codes separated by a comma or space',
  'retailerCollectionsDomain.addProductsByUPC.modal.productsEntered':
    '{productCount} {productCount, plural, one {product} other {products}} entered',
  'retailerCollectionsDomain.addProductsByUPC.modal.buttonLabel':
    'Add {productCount, plural, one {product} other {products}}',

  'retailerCollectionsDomain.filter.optionsLabel': 'Filtering options',
  'retailerCollectionsDomain.filter.ownerGroupTitle': 'Owner',
  'retailerCollectionsDomain.filter.statusGroupTitle': 'Status',
  'retailerCollectionsDomain.filter.placeholder': 'Search collections',
  'retailerCollectionsDomain.filter.active': 'Active',
  'retailerCollectionsDomain.filter.effective': 'Effective',
  'retailerCollectionsDomain.filter.owner.instacart': 'Instacart',
  'retailerCollectionsDomain.filter.owner.retailer': 'Custom',

  'retailerCollectionsDomain.breadcrumb.tooltip.active': 'Active',
  'retailerCollectionsDomain.breadcrumb.tooltip.inactive': 'Inactive',

  'retailerCollectionsDomain.notApplicable': 'N/A',

  'retailerCollectionsDomain.addProductsByUPC.modal.genericError.title': 'An error ocurred.',
  'retailerCollectionsDomain.addProductsByUPC.modal.genericError.body':
    'An error ocurred while adding products to the Retailer Collection.',
  'retailerCollectionsDomain.addProductsByUPC.modal.success.title': 'Products added',
  'retailerCollectionsDomain.addProductsByUPC.modal.success.body':
    '{productCount} {productCount, plural, one {product} other {products}} added to collection',

  'retailerCollectionsDomain.admin.openModal': 'Admin Setting',
  'retailerCollectionsDomain.admin.modal.title':
    "Admin Setting for {warehouseName}'s Navigation Collection",
  'retailerCollectionsDomain.admin.modal.allowedActiveCollectionsTitle':
    'Allowed Active L1 Collections',
  'retailerCollectionsDomain.admin.modal.marketplace': 'Marketplace',
  'retailerCollectionsDomain.admin.modal.marketplaceDescription': 'Leave blank for no limit',
  'retailerCollectionsDomain.admin.modal.storefront': 'Storefront',
  'retailerCollectionsDomain.admin.modal.storefrontPro': 'Storefront Pro',
  'retailerCollectionsDomain.admin.modal.saveSetting': 'Save Setting',
  'retailerCollectionsDomain.admin.modal.saveSettingSuccess': 'Successfully updated settings',
  'retailerCollectionsDomain.admin.modal.saveSettingFailure': 'Updating settings failed!',

  'retailerCollectionsDomain.bulk.openModal': 'Bulk Edit with CSV',
  'retailerCollectionsDomain.bulk.modal.title': 'Bulk Update with CSV',
  'retailerCollectionsDomain.bulk.modal.mainText':
    "You can update your custom collection navigation structure and your product assignments with a .csv file. Download the current .csv file, then upload it when you've made the desired changes. Be sure to make a copy of the original in case you need to revert to the previous version.",
  'retailerCollectionsDomain.bulk.modal.structureTitle': 'Navigation Structure',
  'retailerCollectionsDomain.bulk.modal.structureText':
    'Update the custom collection navigation structure in bulk. The structure includes L1, L2, and L3 collections.',
  'retailerCollectionsDomain.bulk.modal.structureDownload': 'Download Current CSV',
  'retailerCollectionsDomain.bulk.modal.structureUpload': 'Upload New CSV',
  'retailerCollectionsDomain.bulk.modal.productTitle': 'Product Assignment',
  'retailerCollectionsDomain.bulk.modal.productText':
    'Assign products to the custom collection navigation structure in bulk. Collections without any products will not show up on the surface.',
  'retailerCollectionsDomain.bulk.modal.productDownload': 'Download Current CSV',
  'retailerCollectionsDomain.bulk.modal.productUpload': 'Upload New CSV',

  'retailerCollectionsDomain.bulk.structure.title':
    'Update Custom Navigation Collections (Structure Only)',
  'retailerCollectionsDomain.bulk.structure.stepOne': 'Step 1: Upload file',
  'retailerCollectionsDomain.bulk.structure.stepTwo': 'Step 2: Review & Submit',
  'retailerCollectionsDomain.bulk.structure.upload.mainTitle': 'Upload File',
  'retailerCollectionsDomain.bulk.structure.upload.tipsTitle': 'Tips',
  'retailerCollectionsDomain.bulk.structure.upload.tips.one':
    'Use the same CSV format as the current structure CSV you can download. ',
  'retailerCollectionsDomain.bulk.structure.upload.tips.two': 'Double check your CSV heading ',
  'retailerCollectionsDomain.bulk.structure.upload.tips.three':
    'The slug column determines the collection unique. We will match the slug column to define what collection remains the same. ',
  'retailerCollectionsDomain.bulk.structure.upload.tips.four':
    'Slug cannot be duplicated, but you can have duplicated collection names.',
  'retailerCollectionsDomain.bulk.structure.upload.tips.five':
    'Learn more about CSV upload for navigation collection structure.',
  'retailerCollectionsDomain.bulk.structure.upload.continue': 'Continue',
  'retailerCollectionsDomain.bulk.structure.upload.cancel': 'Cancel',

  'retailerCollectionsDomain.bulk.structure.upload.csvUpload.uploadText':
    'Drag and drop the file here',
  'retailerCollectionsDomain.bulk.structure.upload.csvUpload.or': 'or',
  'retailerCollectionsDomain.bulk.structure.upload.csvUpload.uploadButton': 'Browse',
  'retailerCollectionsDomain.bulk.structure.upload.csvUpload.retryButton': 'Select New File',
  'retailerCollectionsDomain.bulk.structure.upload.csvUpload.fileTypeError':
    'Invalid file format or empty. Please upload a .csv file."',
  'retailerCollectionsDomain.bulk.structure.upload.csvUpload.uploadError':
    'Your file could not be uploaded.',

  'retailerCollectionsDomain.bulk.structure.summary.mainTitle': 'Review Changes',
  'retailerCollectionsDomain.bulk.structure.summary.mainText':
    "Carefully review the changes you're making with this file before you confirm the update. Changes may take up to 2 hours to reflect in the system, and up to 24 hours to be visible on the surface.",
  'retailerCollectionsDomain.bulk.structure.summary.uploadedFile': 'Uploaded file',
  'retailerCollectionsDomain.bulk.structure.summary.remove': 'Remove',
  'retailerCollectionsDomain.bulk.structure.summary.removeContent':
    '{numCollections} collection(s) will be removed, containing {numProducts} product(s).',
  'retailerCollectionsDomain.bulk.structure.summary.noChange': 'No Change',
  'retailerCollectionsDomain.bulk.structure.summary.noChangeContent':
    '{numCollections} collection(s) are unchanged.',
  'retailerCollectionsDomain.bulk.structure.summary.new': 'New',
  'retailerCollectionsDomain.bulk.structure.summary.newHierarchyContent':
    'Adding {numCollections} new top level collection(s). These collections will be hidden by default. ',
  'retailerCollectionsDomain.bulk.structure.summary.newContent':
    'Adding {numCollections} new collection(s) that require product assignments. ',
  'retailerCollectionsDomain.bulk.structure.summary.continue': 'Submit',
  'retailerCollectionsDomain.bulk.structure.summary.cancel': 'Previous',

  'retailerCollectionsDomain.bulk.product.title':
    'Update Custom Navigation Collections (Product Only)',
  'retailerCollectionsDomain.bulk.product.stepOne': 'Step 1: Upload file',
  'retailerCollectionsDomain.bulk.product.stepTwo': 'Step 2: Review & Submit',
  'retailerCollectionsDomain.bulk.product.upload.mainTitle': 'Upload File',
  'retailerCollectionsDomain.bulk.product.upload.tipsTitle': 'Tips',
  'retailerCollectionsDomain.bulk.product.upload.tips.one':
    'Use the same CSV format as the current product assignment CSV you can download.',
  'retailerCollectionsDomain.bulk.product.upload.tips.two':
    'You can enter product UPC or RRC in the file.',
  'retailerCollectionsDomain.bulk.product.upload.tips.three':
    'Products need to be approved in your catalog to be assigned to a collection',
  'retailerCollectionsDomain.bulk.product.upload.tips.four':
    'You need to create the collections before you can assign products to them. ',
  'retailerCollectionsDomain.bulk.product.upload.tips.five':
    'Make sure the navigation collection structure exist before adding product codes.',
  'retailerCollectionsDomain.bulk.product.upload.tips.six':
    'This file cannot create collections. You can create new collections here or upload a file.',
  'retailerCollectionsDomain.bulk.product.upload.continue': 'Continue',
  'retailerCollectionsDomain.bulk.product.upload.cancel': 'Cancel',
  'retailerCollectionsDomain.bulk.product.upload.noChanges': 'No updates detected',
  'retailerCollectionsDomain.bulk.product.upload.slug': 'Collection ID',
  'retailerCollectionsDomain.bulk.product.upload.added': 'Added',
  'retailerCollectionsDomain.bulk.product.upload.removed': 'Removed',
  'retailerCollectionsDomain.bulk.product.upload.unchanged': 'Unchanged',
  'retailerCollectionsDomain.bulk.product.upload.apply': 'Apply changes',
  'retailerCollectionsDomain.bulk.product.upload.done': 'Done',
  'retailerCollectionsDomain.bulk.product.upload.warningDupes': `Warning: Mutiple lines in your CSV have resolved to the same product`,

  'retailerCollectionsDomain.bulk.product.summary.mainTitle': 'Review Changes',
  'retailerCollectionsDomain.bulk.product.summary.mainText':
    "Carefully review the changes you're making with this file before you confirm the update. Changes may take up to 2 hours to reflect in the system, and up to 24 hours to be visible on the surface.",
  'retailerCollectionsDomain.bulk.product.summary.uploadedFile': 'Uploaded file',
  'retailerCollectionsDomain.bulk.product.summary.remove': 'Remove',
  'retailerCollectionsDomain.bulk.product.summary.removeContent':
    '{numProducts} product(s) from {numCollections} collection(s)',
  'retailerCollectionsDomain.bulk.product.summary.noChange': 'No Change',
  'retailerCollectionsDomain.bulk.product.summary.noChangeContent':
    '{numProducts} products(s) are unchanged.',
  'retailerCollectionsDomain.bulk.product.summary.new': 'New',
  'retailerCollectionsDomain.bulk.product.summary.newContent':
    'Adding {numProducts} product(s) into {numCollections} collection(s)',
  'retailerCollectionsDomain.bulk.product.summary.unmatched': 'Unmatched',
  'retailerCollectionsDomain.bulk.product.summary.unmatchedContent':
    '{numProducts} product(s) in {numCollections} collection(s) are unmatched in your catalog',
  'retailerCollectionsDomain.bulk.product.summary.continue': 'Submit',
  'retailerCollectionsDomain.bulk.product.summary.cancel': 'Previous',

  'retailerCollectionsDomain.addProductsBySearch.title': 'Search Catalog to Add or Remove Products',
  'retailerCollectionsDomain.addProductsBySearch.addTo': 'Add products to',

  'retailerCollectionsDomain.productSearch.searchTypeNameHint': 'bananas, granola...',
  'retailerCollectionsDomain.productSearch.columns.name': 'Name',
  'retailerCollectionsDomain.productSearch.columns.upc': 'UPC',
  'retailerCollectionsDomain.productSearch.searchType.name': 'Name',
  'retailerCollectionsDomain.productSearch.searchType.upc': 'UPC',
  'retailerCollectionsDomain.productSearch.searchType.rrc': 'RRC',
  'retailerCollectionsDomain.productSearch.searchType.productId': 'Product ID',

  'retailerCollectionsDomain.productSearch.instructions':
    'Search the catalog to add products to this collection',

  'retailerCollectionsDomain.productSearch.noResults.title': 'No Results',
  'retailerCollectionsDomain.productSearch.noResults.body':
    'There were no products found for the given criteria.',

  'retailerCollectionsDomain.form.section.details': 'Collection Details',
  'retailerCollectionsDomain.form.section.assets': 'Collection Assets',

  'retailerCollectionsDomain.form.actions.save': 'Save',
  'retailerCollectionsDomain.form.actions.cancel': 'Cancel',
  'retailerCollectionsDomain.form.actions.copy': 'copy',

  'retailerCollectionsDomain.form.parentCollection.label': 'Parent Collection',
  'retailerCollectionsDomain.form.parentCollection.description':
    'Select the parent collection where you want this new collection to be placed.',
  'retailerCollectionsDomain.form.parentCollection.parentCollection.depthError':
    'Collections cannot be more than {maxDepth} levels deep',
  'retailerCollectionsDomain.form.parentCollection.required':
    'Parent Collection selection required',
  'retailerCollectionsDomain.form.required': 'This field is required.',
  'retailerCollectionsDomain.form.min': 'This field is below the minimum value of {minValue}.',
  'retailerCollectionsDomain.form.max': 'This field is above the maximum value of {maxValue}.',

  'retailerCollectionsDomain.form.surfaces.label': 'Surfaces',
  'retailerCollectionsDomain.form.surfaces.description':
    'Designate which surfaces {numCollections, plural, one {this collection} other {these collections}} will be displayed on.',
  'retailerCollectionsDomain.form.surfaces.error': 'Please pick at least one surface.',

  'retailerCollectionsDomain.form.name.label': 'Name',
  'retailerCollectionsDomain.form.status.label': 'Status',

  'retailerCollectionsDomain.form.slug.label': 'Collection ID',
  'retailerCollectionsDomain.form.slug.required': 'Collection ID must be provided',
  'retailerCollectionsDomain.form.slug.description':
    "A unique identifier for this collection, it's used for updating through the CSV file.\n It can't be edited once the collection is created.",

  'retailerCollectionsDomain.form.contentType.label': 'Content Type',
  'retailerCollectionsDomain.form.contentType.value.collections': 'Collections',
  'retailerCollectionsDomain.form.contentType.value.products': 'Products',

  'retailerCollectionsDomain.form.contentType.collectionOnlyCollection':
    '{numCollections, plural, one {This is a} other {These are}} top level (L1) {numCollections, plural, one {collection} other {collections}}, which can only contain collections.',
  'retailerCollectionsDomain.form.contentType.productOrCollectionsCollection':
    '{numCollections, plural, one {This is a} other {These are}} L{collectionLevel} {numCollections, plural, one {collection} other {collections}}, which can contain collections or products.',
  'retailerCollectionsDomain.form.contentType.productOnlyCollection':
    '{numCollections, plural, one {This is a} other {These are}} L{collectionLevel} {numCollections, plural, one {collection} other {collections}}, which can only contain products.',

  'retailerCollectionsDomain.form.contentType.product.enabledTooltip':
    'This collection type only contains products.',
  'retailerCollectionsDomain.form.contentType.collection.enabledTooltip':
    'This collection type only contains collections.',
  'retailerCollectionsDomain.form.contentType.edit.disabledTooltip':
    "A collection's content type can not be modified after creation.",
  'retailerCollectionsDomain.form.contentType.duplicate.disabledTooltip':
    "A collection's content type can not be modified during duplication.",
  'retailerCollectionsDomain.form.contentType.product.disabledTooltip':
    'Based on the parent collection. {numCollections, plural, one {This collection} other {These collections}} can not contain products.',
  'retailerCollectionsDomain.form.contentType.collection.disabledTooltip':
    'Based on the parent collection.  {numCollections, plural, one {This collection} other {These collections}} can not contain collections.',

  'retailerCollectionsDomain.form.effectiveDates.label': 'Effective Dates',
  'retailerCollectionsDomain.form.effectiveDates.description': 'Leave blank for no end date',
  'retailerCollectionsDomain.form.effectiveDates.error':
    'End date and start date are both required if either is present',

  'retailerCollectionsDomain.form.position.label': 'Position',
  'retailerCollectionsDomain.form.position.description':
    "A numerical value determining the collection's relative position to other sibling collections. A smaller value will rank the collection higher than its siblings. If no position value is set, collections are sorted alphabetically after all collections with a position value.",

  'retailerCollectionsDomain.form.datePicker.clearAll': 'Clear all',
  'retailerCollectionsDomain.form.datePicker.clearEndDate': 'Clear end date',

  'retailerCollectionsDomain.form.tileImageUrl.label': 'Collection Tile Image',

  'retailerCollectionsDomain.form.processingTime':
    'This might take up to 24 hours to appear in the specified surfaces.',

  'retailerCollectionsDomain.create.title': 'Create new retailer collection',
  'retailerCollectionsDomain.create.success': 'Retailer Collection created successfully!',
  'retailerCollectionsDomain.create.error': 'There was an error creating this retailer collection',

  'retailerCollectionsDomain.edit.title': 'Edit collection',
  'retailerCollectionsDomain.edit.success': 'Retailer Collection updated successfully!',
  'retailerCollectionsDomain.edit.error': 'There was an error updating this retailer collection',

  'retailerCollectionsDomain.duplicate.title': 'Duplicate collection',
  'retailerCollectionsDomain.duplicate.success': 'Retailer Collection duplicated successfully!',
  'retailerCollectionsDomain.duplicate.error':
    'There was an error duplicating this retailer collection',
  'retailerCollectionsDomain.ic-duplicate.success': 'Instacart Collection duplicated successfully!',
  'retailerCollectionsDomain.ic-duplicate.error':
    'There was an error duplicating this Instacart collection',

  'retailerCollectionsDomain.addProductsToCollection.multi.added.header':
    '{productCount, plural, one {Product} other {Products}} added',
  'retailerCollectionsDomain.addProductsToCollection.multi.added.content':
    '{productCount} {productCount, plural, one {product} other {products}} successfully added to the collection',
  'retailerCollectionsDomain.addProductsToCollection.multi.error.header': 'An error ocurred',
  'retailerCollectionsDomain.addProductsToCollection.multi.error.content':
    'Could not add products to the collection because an error occurred.',

  'retailerCollectionsDomain.addProductsToCollection.single.added.header': 'Product added',
  'retailerCollectionsDomain.addProductsToCollection.single.added.content':
    '{productName} was successfully added to the collection',
  'retailerCollectionsDomain.addProductsToCollection.single.error.header': 'An error ocurred',
  'retailerCollectionsDomain.addProductsToCollection.single.error.content':
    'Could not add {productName} to the collection because an error occurred.',

  'retailerCollectionsDomain.removeProductsFromCollection.single.removed.header': 'Product Removed',
  'retailerCollectionsDomain.removeProductsFromCollection.single.removed.content':
    '{productName} was successfully removed from the collection',
  'retailerCollectionsDomain.removeProductsFromCollection.single.error.header': 'An error ocurred',
  'retailerCollectionsDomain.removeProductsFromCollection.single.error.content':
    'Could not remove {productName} from the collection because an error occurred.',

  'retailerCollectionsDomain.bulkCreateCollections.title': 'Bulk Add Collections',
  'retailerCollectionsDomain.bulkCreateCollections.addTo': 'Adding collections to',
  'retailerCollectionsDomain.bulkCreateCollections.collectionNames.label': 'Collection Names',
  'retailerCollectionsDomain.bulkCreateCollections.collectionsNames.description':
    'Add all of the collection names, each on it’s own line',
  'retailerCollectionsDomain.bulkCreateCollections.numCollectionsEntered':
    '{numCollections} new {numCollections, plural, one {collection} other {collections}} entered',
  'retailerCollectionsDomain.bulkCreateCollections.createCollections':
    'Create {numCollections, plural, one {collection} other {collections}}',
  'retailerCollectionsDomain.bulkCreateCollections.cancel': 'Cancel',
  'retailerCollectionsDomain.bulkCreateCollections.success': 'Successfully created collections',

  'retailerCollectionsDomain.bulkCreateCollections.partialSuccess.title':
    'Some collections could not be created',
  'retailerCollectionsDomain.bulkCreateCollections.partialSuccess.content':
    '{numSucceeded} {numSucceeded, plural, one {collection} other {collections}} created. However, {numFailed} {numFailed, plural, one {collection} other {collections}}  could not be created due to:{br}{message}{br}{br}The names remain in the form if you want to try again.',

  'retailerCollectionsDomain.bulkCreateCollections.error.allFailed.content':
    '{numFailed} {numFailed, plural, one {collection} other {collections}}  could not be created due to:{br}{message}{br}{br}The names remain in the form if you want to try again.',

  'retailerCollectionsDomain.bulkCreateCollections.error.default.title':
    'Failed to create collections',
  'retailerCollectionsDomain.bulkCreateCollections.error.default.content':
    'An error ocurred when creating these collections. Please try again.',
  'retailerCollectionsDomain.v2.list.title': 'Collections',
  'retailerCollectionsDomain.v3.list.title': 'Collections',
  'retailerCollectionsDomain.v2.list.uses': 'Uses',
  'retailerCollectionsDomain.v2.list.create': 'Create',
  'retailerCollectionsDomain.v2.list.filterControls.period': 'Compared to last period',
  'retailerCollectionsDomain.v2.list.table.title': 'Collection Details',
  'retailerCollectionsDomain.v2.list.table.search': 'Search',
  'retailerCollectionsDomain.v2.list.table.search.placeholder.v3':
    'Search by collection name or ID',
  'retailerCollectionsDomain.v2.list.table.navToggle': 'Include Department Collections',
  'retailerCollectionsDomain.v2.list.metrics.addToCart': 'Add to Cart',
  'retailerCollectionsDomain.v2.list.metrics.impressions': 'Impressions',
  'retailerCollectionsDomain.v2.list.metrics.gmv': 'GMV',
  'retailerCollectionsDomain.v2.list.metrics.conversion': 'Conversion Rate',
  'retailerCollectionsDomain.v2.list.metrics.more.title': 'View Collection Analytics',
  'retailerCollectionsDomain.v2.list.metrics.more.details': 'Analyze your collections performance',
  'retailerCollectionsDomain.v2.create.title': 'New Collection',
  'retailerCollectionsDomain.v2.form.create.title': 'Create Collection',
  'retailerCollectionsDomain.v2.form.products.title': 'Products',
  'retailerCollectionsDomain.v2.form.details.name': 'Name',
  'retailerCollectionsDomain.v2.form.details.name.description':
    'The name that displays on the storefront. Max 45 characters.',
  'retailerCollectionsDomain.v2.form.details.banners': 'Banner(s)',
  'retailerCollectionsDomain.v2.form.details.banners.description':
    'Banner selection will determine catalog results.',
  'retailerCollectionsDomain.v2.form.details.banners.error': 'Banners are required',

  'retailerCollectionsDomain.v3.list.filters.retailers': 'Banners',
  'retailerCollectionsDomain.v3.list.filters.retailerCreated': 'Retailer created',
  'retailerCollectionsDomain.v3.list.filters.instacartCreated': 'Instacart created',
  'retailerCollectionsDomain.v3.list.filters.allCollectionTypes': 'All collection types',

  'retailerCollectionsDomain.v2.form.details.localization.section.title': 'Localization',
  'retailerCollectionsDomain.v2.form.details.localization.section.description':
    'Add language translations to this name.',
  'retailerCollectionsDomain.v2.form.details.localization.section.button': 'Add language',
  'retailerCollectionsDomain.v2.form.details.localization.section.info':
    'French translation is required for Canadian retailers.',

  'retailerCollectionsDomain.v2.form.details.localization.modal.title': 'Manage languages',
  'retailerCollectionsDomain.v2.form.details.localization.modal.button.save': 'Save',
  'retailerCollectionsDomain.v2.form.details.localization.modal.button.cancel': 'Cancel',

  'retailerCollectionsDomain.v2.form.details.localization.select.label.name': 'Name',
  'retailerCollectionsDomain.v2.form.details.localization.select.label.default.locale':
    'Default language',
  'retailerCollectionsDomain.v2.form.details.localization.select.label.added.locale':
    'Added languages',
  'retailerCollectionsDomain.v2.form.details.localization.select.dropdown.placeholder':
    'Select a language',
  'retailerCollectionsDomain.v2.form.details.localization.select.input.placeholder':
    'Enter the name in {language}',

  'retailerCollectionsDomain.v2.form.details.slug': 'ID',
  'retailerCollectionsDomain.v2.form.details.slug.description':
    'The unique identifier to use as the page URL handle. This ID is also used for updating the collection through CSV upload.',
  'retailerCollectionsDomain.v2.form.collectionType.title': 'Collection Type',
  'retailerCollectionsDomain.v2.form.collectionType.static': 'Static',
  'retailerCollectionsDomain.v2.form.collectionType.static.description': `Add a curated list of products.`,
  'retailerCollectionsDomain.v2.form.collectionType.dynamic': 'Dynamic',
  'retailerCollectionsDomain.v2.form.collectionType.dynamic.description': `Define rules that determine which products are added.`,
  'retailerCollectionsDomain.v2.form.collectionType.dfs': 'Dynamically Fed Static',
  'retailerCollectionsDomain.v2.form.collectionType.dfs.description': `Use filters to assign products automatically as they are added to the catalog.`,
  'retailerCollectionsDomain.v2.form.collectionType.confirm.title': 'Change collection type',
  'retailerCollectionsDomain.v2.form.collectionType.confirm.body.title': `Are you sure you want to change the collection type?`,
  'retailerCollectionsDomain.v2.form.collectionType.confirm.body.description': `All of the changes you have made will be discarded.`,
  'retailerCollectionsDomain.v2.form.collectionType.confirm.body.description.products': `All of the changes you have made to products will be discarded.`,
  'retailerCollectionsDomain.v2.form.collectionType.confirm.change': 'Change',
  'retailerCollectionsDomain.v2.form.collectionType.confirm.cancel': 'Cancel',
  'retailerCollectionsDomain.v2.form.bannerChange.confirm.title': 'Banner change',
  'retailerCollectionsDomain.v2.form.bannerChange.confirm.body.description': `Are you sure you want to remove banner(s)? This will impact product availability in your catalog.`,
  'retailerCollectionsDomain.v2.form.bannerChange.confirm.change': 'Change',
  'retailerCollectionsDomain.v2.form.bannerChange.confirm.cancel': 'Cancel',
  'retailerCollectionsDomain.v2.form.productSort.title': 'Product Sort',
  'retailerCollectionsDomain.v2.form.productSort.pinnedDisclamier': `Pinned products and their ranking are exclusive to white label storefronts, and they do not influence the order of products displayed in collections on the Instacart marketplace.`,
  'retailerCollectionsDomain.v2.form.productSort.manualOrderDisclaimer': `Manual order ranking is exclusive to white label storefronts and does not influence the order in which products are displayed in collections on the Instacart Marketplace.`,
  'retailerCollectionsDomain.v2.form.productSort.bestMatch': 'Best match',
  'retailerCollectionsDomain.v2.form.productSort.bestMatch.description': `Personalized customer behavior: Most likely to add to their cart will appear first.`,
  'retailerCollectionsDomain.v2.form.productSort.dateAdded': 'Date added',
  'retailerCollectionsDomain.v2.form.productSort.dateAdded.description': `Newest products appear first.`,
  'retailerCollectionsDomain.v2.form.productSort.popularity': 'Popularity',
  'retailerCollectionsDomain.v2.form.productSort.popularity.description': `Top selling products will appear first.`,
  'retailerCollectionsDomain.v2.form.productSort.manualOrder': 'Manual order',
  'retailerCollectionsDomain.v2.form.productSort.manualOrder.description':
    'Based on the order in which they are entered.',
  'retailerCollectionsDomain.v2.form.productSearch.searchPlaceholder': `Enter product names, lookup codes, RRCs, or IDs separated by commas.`,
  'retailerCollectionsDomain.v2.form.productSearch.searchType.label': `Select the type of product code to search for`,
  'retailerCollectionsDomain.v2.form.productSearch.searchType.all': 'All',
  'retailerCollectionsDomain.v2.form.productSearch.searchType.upc': 'UPC',
  'retailerCollectionsDomain.v2.form.productSearch.searchType.rrc': 'RRC',
  'retailerCollectionsDomain.v2.form.productSearch.searchType.id': 'Product ID',
  'retailerCollectionsDomain.v2.form.productSearch.numProducts': '{numProducts} products added',
  'retailerCollectionsDomain.v2.form.productSearch.removeAll': 'Remove',
  'retailerCollectionsDomain.v2.form.productSearch.moveProduct': 'Move Products',
  'retailerCollectionsDomain.v2.form.productSearch.pinnedCount': 'products pinned',
  'retailerCollectionsDomain.v2.form.productSearch.pinnedTooltip.title': 'Pinning & ordering logic',
  'retailerCollectionsDomain.v2.form.productSearch.pinnedTooltip.one': `You may have a max limit of 50 pinned products.`,
  'retailerCollectionsDomain.v2.form.productSearch.pinnedTooltip.two': `Pinned products will be moved to the top of your collection ordered by rank number low to high.`,
  'retailerCollectionsDomain.v2.form.productSearch.pinnedTooltip.three': `If there are multiple items with the same rank number, product sort selection will be respected.`,
  'retailerCollectionsDomain.v2.form.productSearch.noProducts': `There are no products in this collection. Use the search field to find products by name, lookup code, RRC, or ID.`,
  'retailerCollectionsDomain.v2.form.productSearch.noProductsAlt': 'Empty Collection Image',
  'retailerCollectionsDomain.v2.form.productSearch.blockRightPanelMessage':
    'Select a banner you would like to add products from for your collection.',
  'retailerCollectionsDomain.v2.form.productSearch.missingQueries': `{numQueries} queries did not match any products in your catalog`,
  'retailerCollectionsDomain.v2.form.productSearch.AddAll': 'Add All',
  'retailerCollectionsDomain.v2.form.productSearch.numProductsSelected': `{numProducts} products selected`,
  'retailerCollectionsDomain.v2.form.dynamic.title': `Products that satisfy the rules are automatically added to the collection.`,
  'retailerCollectionsDomain.v2.form.dynamic.intersection.1': 'Products that match',
  'retailerCollectionsDomain.v2.form.dynamic.intersection.2': 'of the following:',
  'retailerCollectionsDomain.v2.form.dynamic.addRule': 'Add rule',
  'retailerCollectionsDomain.v2.form.dynamic.any': 'ANY',
  'retailerCollectionsDomain.v2.form.dynamic.all': 'ALL',
  'retailerCollectionsDomain.v2.form.dynamic.to': 'to',
  'retailerCollectionsDomain.v2.form.dynamic.min': 'Min',
  'retailerCollectionsDomain.v2.form.dynamic.max': 'Max',
  'retailerCollectionsDomain.v2.form.dynamic.unlimited': 'Unlimited',
  'retailerCollectionsDomain.v2.form.dynamic.no.limit': 'No limit',
  'retailerCollectionsDomain.v2.form.dynamic.range': '{min} to {max}',
  'retailerCollectionsDomain.v2.form.dynamic.multiRetailerDisclaimer': `Selecting multiple banners will limit the available rules. Retailer-specific rules, such as departments and custom attributes, are only applicable to individual retailers.`,
  'retailerCollectionsDomain.v2.form.dynamic.priceDisclaimer': `Price-based rules are applied to the pre-markup/pre-discount price of the item`,
  'retailerCollectionsDomain.v2.form.rightPanelToggle.title': 'Current View',
  'retailerCollectionsDomain.v2.form.rightPanelToggle.products': 'Products',
  'retailerCollectionsDomain.v2.form.rightPanelToggle.products.description': `View products assigned to the collection.`,
  'retailerCollectionsDomain.v2.form.rightPanelToggle.filters': 'Filters',
  'retailerCollectionsDomain.v2.form.rightPanelToggle.filters.description': `View filters that will be used to assign product to the collection.`,
  'retailerCollectionsDomain.v2.productSearch.table.name': 'Product Name',
  'retailerCollectionsDomain.v2.productSearch.table.upc': 'Lookup Code',
  'retailerCollectionsDomain.v2.productSearch.table.rrc': 'RRC',
  'retailerCollectionsDomain.v2.productSearch.table.id': 'Product ID',
  'retailerCollectionsDomain.v2.productSearch.table.banners': 'Banners',
  'retailerCollectionsDomain.v2.details.name': 'Name',
  'retailerCollectionsDomain.v2.details.banners': 'Banners',
  'retailerCollectionsDomain.v2.details.products': 'Products',
  'retailerCollectionsDomain.v2.details.productCount': '{productCount} products',
  'retailerCollectionsDomain.v2.details.productCount.more': '+{productCount}',
  'retailerCollectionsDomain.v2.details.largeCollectionWarning': `Change made to manual collections with over 250 products may take up to 2 hours to reflect on storefront.`,
  'retailerCollectionsDomain.v2.deleteModal.success': 'Retailer Collection deleted successfully!',
  'retailerCollectionsDomain.v2.deleteModal.error':
    'Error deleting collection. Please try again later.',
  'retailerCollectionsDomain.v2.view.viewAnalytics': 'Analytics',
  'retailerCollectionsDomain.v2.view.edit': 'Edit',
  'retailerCollectionsDomain.v2.view.delete': 'Delete Collection',
  'retailerCollectionsDomain.v2.view.rules.error':
    'There was a problem displaying applied filters at this time.',
  'retailerCollectionsDomain.v2.view.rules.title': 'Rules',
  'retailerCollectionsDomain.v2.view.rules.all': 'Products matching ALL of the following:',
  'retailerCollectionsDomain.v2.view.rules.any': 'Products matching ANY of the following:',
  'retailerCollectionsDomain.v2.view.preview': 'Preview',
  'retailerCollectionsDomain.v2.view.promote.title': 'What can I do with a collection?',
  'retailerCollectionsDomain.v2.view.promote.description': `You can showcase your collection in a few ways:`,
  'retailerCollectionsDomain.v2.view.promote.placement.1': 'Create a collections placement',
  'retailerCollectionsDomain.v2.view.promote.placement.2': 'on your storefront',
  'retailerCollectionsDomain.v2.view.promote.navigation': 'Add it to a department',
  'retailerCollectionsDomain.v2.view.promote.pages': 'Use it in a custom page',
  'retailerCollectionsDomain.v2.view.copy': 'Copy Collection ID',
  'retailerCollectionsDomain.v2.view.copied': 'Collection ID Copied to Clipboard',
  'retailerCollectionsDomain.v2.view.dupe': 'Duplicate Collection',
  'retailerCollectionsDomain.v2.view.notFound': `Sorry we can't find the collection you're looking for.`,
  'retailerCollectionsDomain.v2.view.back': `Return to list`,
  'retailerCollectionsDomain.v2.deleteModal.cancel': 'Cancel',
  'retailerCollectionsDomain.v2.deleteModal.delete': 'Delete',
  'retailerCollectionsDomain.v2.deleteModal.description':
    'Are you sure you want to delete this collection?',
  'retailerCollectionsDomain.v2.deleteModal.title': 'Delete Collection',
  'retailerCollectionsDomain.v2.edit.title': 'Edit Collection',
  'retailerCollectionsDomain.v2.edit.success': 'Retailer Collection updated successfully!',
  'retailerCollectionsDomain.v2.edit.error': 'Error updating collection. Please try again later.',
  'retailerCollectionsDomain.v2.bulk.modal.mainText':
    "You can update your product assignments for all collections with a single .csv file. Download the current .csv file, then upload it when you've made the desired changes. Be sure to make a copy of the original in case you need to revert to the previous version.",
  'retailerCollectionsDomain.v2.bulk.modal.productText':
    'Assign products to your collections in bulk. Collections without any products will not show up on the surface.',
  'retailerCollectionsDomain.v2.bulk.product.title': 'Update Collection Products',
  'retailerCollectionsDomain.v2.csvUpload.error.tooManyProducts':
    'Maximum products supported in a single CSV is {maximumProducts}. Please split your upload into smaller batches.',
  'retailerCollectionsDomain.v2.csvUpload.error.noProducts':
    'No product assignments for collection with id {slug}',
  'retailerCollectionsDomain.v2.csvUpload.title': 'Upload CSV',
  'retailerCollectionsDomain.v2.csvUpload.button': 'Select CSV',
  'retailerCollectionsDomain.v2.csvUpload.reupload': 'Reupload',
  'retailerCollectionsDomain.v2.csvUpload.dragDrop': 'Drag and Drop file here',
  'retailerCollectionsDomain.v2.csvUpload.or': 'or',
  'retailerCollectionsDomain.v2.csvUpload.error.fileTypeError': 'Incorrect file type uploaded',
  'retailerCollectionsDomain.v2.csvUpload.error.fileContentError': 'Invalid file uploaded',
  'retailerCollectionsDomain.v2.csvUpload.error.uploadError': 'Error uploading file',
  'retailerCollectionsDomain.v2.csvUpload.error.error': 'There was an error.',
  'retailerCollectionsDomain.v2.csvUpload.error.retry': 'Retry',
  'retailerCollectionsDomain.v2.csvUpload.actions.cancel': 'Cancel',
  'retailerCollectionsDomain.v2.csvUpload.actions.add': 'Add',
  'retailerCollectionsDomain.v2.csvUpload.tooltip': 'CSV upload requires collection ID',
  'retailerCollectionsDomain.v2.csvUpload.badFormat': `Invalid CSV. Please include all required columns ('Collection slug' and one of 'Lookup code', 'Retailer reference code', 'UPC', or 'SKU').`,
  'retailerCollectionsDomain.v2.csvUpload.downloadSampleFile': 'Download sample file',
  'retailerCollectionsDomain.v2.education.banner.heading':
    'Introducing new & improved Collection curation',
  'retailerCollectionsDomain.v2.education.banner.information':
    'Introducing new & improved Collection curation We enhanced the Collections tool to make it even easier to present and promote your products across different customer journeys and marketing channels.',
  'retailerCollectionsDomain.v2.education.banner.check-it-out': 'Check it out',
  'retailerCollectionsDomain.v2.education.banner.learn-more': 'Learn More',
  'retailerCollectionsDomain.v2.education.modal.heading':
    'Introducing new & improved Collection curation',
  'retailerCollectionsDomain.v2.education.modal.whats-new': 'Whats New?',
  'retailerCollectionsDomain.v2.education.modal.whats-new.detail':
    'We enhanced the Collections tool to make it even easier to present and promote your products across different customer journeys and marketing channels:',
  'retailerCollectionsDomain.v2.education.modal.whats-new.step-one': '1. Dynamic Collections',
  'retailerCollectionsDomain.v2.education.modal.whats-new.step-two': '2. Navigation Independence',
  'retailerCollectionsDomain.v2.education.modal.whats-new.step-three': '3. Grow basket sizes',
  'retailerCollectionsDomain.v2.education.modal.whats-new.step-four': '4. Understand performance',
  'retailerCollectionsDomain.v2.education.modal.whats-new.ready': 'Ready to learn more?',
  'retailerCollectionsDomain.v2.education.modal.whats-new.next': 'Next: Dynamic Collections',
  'retailerCollectionsDomain.v2.education.modal.whats-new.learn': 'Learn more',
  'retailerCollectionsDomain.v2.education.modal.dynamic-collections': 'Dynamic Collections',
  'retailerCollectionsDomain.v2.education.modal.dynamic-collections.detail':
    'Create dynamic collections in seconds! Define rules that auto-populate the collection with products that satisfy your conditions.',
  'retailerCollectionsDomain.v2.education.modal.dynamic-collections.next':
    'Next: Navigation Independence',
  'retailerCollectionsDomain.v2.education.modal.navigation-independence': 'Navigation Independence',
  'retailerCollectionsDomain.v2.education.modal.navigation-independence.detail':
    'Use collections wherever, whenever. Creating collections and managing the storefront navigation are now independent activities. This gives the flexibility to hide collections from the navigation, but use them in other ways, such as for targeted campaigns. You can still add collections to the navigation by using the new',
  'retailerCollectionsDomain.v2.education.modal.navigation-independence.detail.link':
    'Navigation tool.',
  'retailerCollectionsDomain.v2.education.modal.navigation-independence.next':
    'Next: Grow basket sizes',
  'retailerCollectionsDomain.v2.education.modal.grow': 'Grow basket sizes',
  'retailerCollectionsDomain.v2.education.modal.grow.detail':
    'Use collections within your storefront content pages, or within Campaigns & Offers to drive any marketing or merchandising objective.',
  'retailerCollectionsDomain.v2.education.modal.grow.next': 'Next: Understand performance',
  'retailerCollectionsDomain.v2.education.modal.performance': 'Understand performance',
  'retailerCollectionsDomain.v2.education.modal.performance.detail':
    'Analyze traffic and monitor advanced metrics. Use the Collections dashboard to view key performance indicators (KPI) and data about how your collections are performing.',
  'retailerCollectionsDomain.v2.education.modal.dismiss': 'Dismiss',
  'retailerCollectionsDomain.v2.education.modal.back': 'Back',
  'retailerCollectionsDomain.v2.education.modal.remind-me': 'Remind me later',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.title': 'Manage inventory file mappings',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.close': 'Close',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.add': 'Add',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.current-mappings': 'Current mappings',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.no-mappings': 'No mappings',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.slug': 'Slug',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.loading': 'Loading...',
  'retailerCollectionsDomain.v2.form.invf-mappings.modal.add-new': `Add a new mapping for this collection`,
  'retailerCollectionsDomain.v2.invf-mappings.title': 'Inventory File Mappings',
  'retailerCollectionsDomain.v2.moveModal.title': 'Move Products',
  'retailerCollectionsDomain.v2.moveModal.selected': 'Products Selected',
  'retailerCollectionsDomain.v2.moveModal.collection': 'Target Collection',
  'retailerCollectionsDomain.v2.moveModal.collection.submit': 'Move',
  'retailerCollectionsDomain.v2.moveModal.collection.cancel': 'Cancel',
  'retailerCollectionsDomain.flyers.readyForReview': 'Ready for Review',
  'retailerCollectionsDomain.flyers.nameWithStoreCount':
    '{name} ({number} {number, plural, =1 {store} other {stores}})',
  'retailerCollectionsDomain.flyers.list.title': 'Flyers',
  'retailerCollectionsDomain.flyers.list.table.noResults': 'No flyers to display',
  'retailerCollectionsDomain.flyers.list.table.loading': 'Loading...',
  'retailerCollectionsDomain.flyers.list.table.title': 'Flyer details',
  'retailerCollectionsDomain.flyers.list.table.headers.flyer': 'Flyer',
  'retailerCollectionsDomain.flyers.list.table.headers.status': 'Status',
  'retailerCollectionsDomain.flyers.list.table.headers.start': 'Start',
  'retailerCollectionsDomain.flyers.list.table.headers.end': 'End',
  'retailerCollectionsDomain.flyers.build.title': 'Flyer Builder',
  'retailerCollectionsDomain.flyers.status.draft': 'Draft',
  'retailerCollectionsDomain.flyers.status.scheduled': 'Scheduled',
  'retailerCollectionsDomain.flyers.status.preview': 'In Preview',
  'retailerCollectionsDomain.flyers.status.ended': 'Ended',
  'retailerCollectionsDomain.flyers.status.active': 'Active',
  'retailerCollectionsDomain.flyers.status.cancelled': 'Cancelled',
  'retailerCollectionsDomain.flyers.status.unknown': 'Unknown',
  'retailerCollectionsDomain.flyers.build.header.primaryAction': 'Review all flyers',
  'retailerCollectionsDomain.flyers.build.header.secondaryAction': 'Preview flyer',
  'retailerCollectionsDomain.flyers.build.navigator.page': 'Page',
  'retailerCollectionsDomain.flyers.build.navigator.nextPage': 'Next page',
  'retailerCollectionsDomain.flyers.build.navigator.previousPage': 'Previous page',
  'retailerCollectionsDomain.flyers.build.navigator.pageTotal': 'of {pageTotal}',
  'retailerCollectionsDomain.flyers.build.navigator.zoomToFit': 'Zoom to fit',
  'retailerCollectionsDomain.flyers.build.toolbar.tools.move': 'Move',
  'retailerCollectionsDomain.flyers.build.toolbar.tools.draw': 'Draw',
  'retailerCollectionsDomain.flyers.build.slicer.size': '{columns} x {rows}',
  'retailerCollectionsDomain.flyers.build.slicer.confirm': 'Confirm',
  'retailerCollectionsDomain.flyers.build.slicer.undo': 'Undo',
  'retailerCollectionsDomain.flyers.build.slicer.advanced.bleed': 'Bleed',
  'retailerCollectionsDomain.flyers.build.slicer.advanced.gutter': 'Gutter',
  'retailerCollectionsDomain.flyers.build.blocks.heading': 'Blocks',
  'retailerCollectionsDomain.flyers.build.blocks.empty': 'No blocks',
  'retailerCollectionsDomain.flyers.build.blocks.draft': 'Draft Block ({number})',
  'retailerCollectionsDomain.flyers.build.blocks.stored': 'Block {number}',
  'retailerCollectionsDomain.flyers.build.blocks.fullSlice': 'Start a full-page slice',
  'retailerCollectionsDomain.flyers.build.form.nextBlock': 'Cycle next block',
  'retailerCollectionsDomain.flyers.build.form.previousBlock': 'Cycle previous block',
  'retailerCollectionsDomain.flyers.build.form.unsavedChanges': 'Unsaved Changes',
  'retailerCollectionsDomain.flyers.build.form.saved': 'Saved',
  'retailerCollectionsDomain.flyers.build.form.destination': 'Destination',
  'retailerCollectionsDomain.flyers.build.form.save': 'Save',
  'retailerCollectionsDomain.flyers.build.form.delete': 'Delete',
  'retailerCollectionsDomain.flyers.build.form.toasts.saveSuccess':
    'Successfully saved blocks: {ids}!',
  'retailerCollectionsDomain.flyers.build.unsavedChangesModal.title': 'Unsaved changes',
  'retailerCollectionsDomain.flyers.build.unsavedChangesModal.description':
    'Please save all changes before continuing!',
  'retailerCollectionsDomain.flyers.build.unsavedChangesModal.cancel': 'Keep editing',
  'retailerCollectionsDomain.flyers.build.unsavedChangesModal.save': 'Save all changes',
  'retailerCollectionsDomain.flyers.build.form.toasts.delimiter': ', ',
  'retailerCollectionsDomain.flyers.build.form.toasts.saveFailed': 'Failed to save!',
  'retailerCollectionsDomain.flyers.build.form.toasts.deleteSuccess': 'Successfully deleted!',
  'retailerCollectionsDomain.flyers.build.form.toasts.deleteFailed': 'Failed to delete!',
  'retailerCollectionsDomain.flyers.build.form.destinationOptions.flyerData': 'Flyer Data',
  'retailerCollectionsDomain.flyers.build.form.destinationOptions.collection': 'Collection',
  'retailerCollectionsDomain.flyers.build.form.destinationOptions.flyerPage': 'Flyer Page',
  'retailerCollectionsDomain.flyers.build.form.destinationOptions.recipe': 'Recipe',
  'retailerCollectionsDomain.flyers.build.form.destinationOptions.url': 'URL',
  'retailerCollectionsDomain.flyers.build.form.help': 'Choose where the block will drive customers',
  'retailerCollectionsDomain.flyers.build.form.actions.activity.inputPlaceholder':
    'Enter activity ID or name...',
  'retailerCollectionsDomain.flyers.build.form.actions.activity.help':
    'Only products or groups of products found in the flyer data will appear here. Enter the associated activity ID or name to link the destination.',
  'retailerCollectionsDomain.flyers.build.form.actions.activity.onPage': 'On this page',
  'retailerCollectionsDomain.flyers.build.form.actions.activity.otherPage': 'Other page',
  'retailerCollectionsDomain.flyers.build.form.actions.flyerPage.label': 'Page {number}',
  'retailerCollectionsDomain.flyers.build.form.actions.flyerPage.inputPlaceholder':
    'Select flyer page...',
  'retailerCollectionsDomain.flyers.build.form.actions.url.title':
    'URL restriction on Instacart App',
  'retailerCollectionsDomain.flyers.build.form.actions.url.subtitle':
    'We do not enable URLs on the Instacart app due to X. As a backup we will do X. Your URL will work for your whitelabel storefront. ',
  'retailerCollectionsDomain.flyers.build.form.actions.url.inputPlaceholder': 'Enter URL',
  'retailerCollectionsDomain.flyers.build.settings.title': 'Settings',
  'retailerCollectionsDomain.flyers.build.settings.autosave.label': 'Enable Autosave',
  'retailerCollectionsDomain.flyers.build.settings.mouseDebug.label': 'Enable Mouse Debug',
  'retailerCollectionsDomain.flyers.build.settings.hideBlocks.label': 'Hide Blocks',
  'retailerCollectionsDomain.flyers.build.settings.reviewOverlay.label': 'Show Review Overlay',
  'retailerCollectionsDomain.flyers.build.settings.deleteAllBlocks': 'Delete all blocks',
  'retailerCollectionsDomain.flyers.build.settings.exportToCSV': 'Export to CSV',
  'retailerCollectionsDomain.flyers.build.mouseDebug.title': 'Mouse Debug',
  'retailerCollectionsDomain.flyers.build.mouseDebug.coords': 'X: {x}, Y: {y}',
  'retailerCollectionsDomain.flyers.build.mouseDebug.absolute': 'Absolute Position',
  'retailerCollectionsDomain.flyers.build.mouseDebug.stage': 'Stage Position',
  'retailerCollectionsDomain.flyers.build.mouseDebug.flyer': 'Flyer Image Position',
  'retailerCollectionsDomain.flyers.review.header.primaryAction': 'Activate',
  'retailerCollectionsDomain.flyers.review.header.primaryActionPublished': 'Deactivate all flyers',
  'retailerCollectionsDomain.flyers.review.title': 'Flyers Review',
  'retailerCollectionsDomain.flyers.review.subtitle': 'Flyer Group',
  'retailerCollectionsDomain.flyers.review.education.title': 'Let’s review!',
  'retailerCollectionsDomain.flyers.review.education.body':
    'Take a minute to ensure your flyers are ready to go. You may preview each flyer on various storefronts, ensuring they are linked to the correct products & destinations.',
  'retailerCollectionsDomain.flyers.review.education.activate.title': 'How to activate',
  'retailerCollectionsDomain.flyers.review.education.activate.body':
    'You must mark each flyer as “ready” in order to publish.',
  'retailerCollectionsDomain.flyers.review.details.schedule.heading': 'Schedule',
  'retailerCollectionsDomain.flyers.review.details.schedule.subtitle': '{startDate} - {endDate}',
  'retailerCollectionsDomain.flyers.review.details.flyers.heading': 'Flyers',
  'retailerCollectionsDomain.flyers.review.details.flyers.subtitle':
    '{count} of {total} flyers are ready.',
  'retailerCollectionsDomain.flyers.review.details.flyers.stats.pages': '{number} pages',
  'retailerCollectionsDomain.flyers.review.details.flyers.stats.blocks': '{number} blocks',
  'retailerCollectionsDomain.flyers.review.details.flyers.stats.separator': ' • ',
  'retailerCollectionsDomain.flyers.review.details.flyers.actions.edit': 'Edit',
  'retailerCollectionsDomain.flyers.review.details.flyers.actions.viewQuickReview': 'Quick Review',
  'retailerCollectionsDomain.flyers.review.details.flyers.actions.hideQuickReview':
    'Hide Quick Review',
  'retailerCollectionsDomain.flyers.review.detailers.flyers.quick.attention':
    '{number} {number, plural, =1 {block} other {blocks}} {number, plural, =1 {needs} other {need}} attention!',
  'retailerCollectionsDomain.flyers.review.details.flyers.quick.page': 'Page {number}',
  'retailerCollectionsDomain.flyers.review.details.flyers.quick.actionTypes.flyerData':
    'Flyer Data',
  'retailerCollectionsDomain.flyers.review.details.flyers.quick.actionTypes.flyerPage':
    'Flyer Page',
  'retailerCollectionsDomain.flyers.review.details.flyers.quick.actionTypes.url': 'URL',
  'retailerCollectionsDomain.flyers.review.details.flyers.quick.actionTypes.recipe': 'Recipe',
  'retailerCollectionsDomain.flyers.review.details.flyers.quick.actionTypes.collection':
    'Collection',
  'retailerCollectionsDomain.flyers.review.details.flyers.quick.actionTypes.unknown': 'Unknown',
  'retailerCollectionsDomain.form.brand.search.beforeSearch': 'Before search',
  'retailerCollectionsDomain.form.brand.search.noResults': 'No results',
  'retailerCollectionsDomain.form.brand.search.selectPlaceholder': 'Search for a brand',
} as const

export default EN_US
