import styled from '@emotion/styled'
import { Button } from '@retailer-platform/shared-components/src/tds'
import type { FunctionComponent } from 'react'

type Props = {
  onClickAccept: () => void
  onClickCancel: () => void
}

const Container = styled.div({
  display: 'flex',
  marginLeft: 'auto',
  marginRight: '0px',
  padding: '8px',
  gap: '8px',
})

const ApplyCancelComponent: FunctionComponent<Props> = ({ onClickAccept, onClickCancel }) => (
  <Container>
    <div css={{ marginLeft: 'auto', marginRight: '0px' }} />
    <Button
      data-testid="multi-scope-picker-cancel-button"
      variant="secondary"
      onClick={onClickCancel}
    >
      Cancel
    </Button>
    <Button data-testid="multi-scope-picker-apply-button" onClick={onClickAccept}>
      Apply
    </Button>
  </Container>
)

export default ApplyCancelComponent
