import React, { useEffect, useRef } from 'react'
import { type FunctionComponent } from 'react'
import { MenuHeader } from '@retailer-platform/shared-components/src/tds'
import { useIntl } from 'react-intl'
import { NavMenuItem } from './NavMenuItem'

export type Item = {
  label: string
  route: string
  onClick: () => void
}

export type ItemGroup = {
  groupHeaderText: string
  items: Item[]
}

export type Props = {
  groups: ItemGroup[]
  searchValue: string
  activeItem?: Item
  onMouseEnter: () => void
}

export const NavMenuItemGroup: FunctionComponent<Props> = ({
  groups,
  searchValue,
  activeItem,
  onMouseEnter,
}) => {
  const intl = useIntl()
  const containerRef = useRef<HTMLDivElement>(null)
  const activeItemRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (activeItem && activeItemRef.current) {
      activeItemRef.current.scrollIntoView({ block: 'nearest' })
    }
  }, [activeItem])

  const nothingFoundBox = (
    <div style={{ padding: 24 }}>{intl.formatMessage({ id: 'navV2.search.nothingFound' })}</div>
  )

  return (
    <div ref={containerRef} style={{ maxHeight: '400px', width: '364px', overflowY: 'auto' }}>
      {groups?.length > 0
        ? groups.map(itemGroup => (
            <React.Fragment key={itemGroup.groupHeaderText + itemGroup.items}>
              <MenuHeader key={itemGroup.groupHeaderText} title={itemGroup.groupHeaderText} />
              {itemGroup.items.map(item => (
                <div
                  ref={
                    activeItem?.label === item.label && activeItem?.route === item.route
                      ? activeItemRef
                      : null
                  }
                  key={item.label + itemGroup.groupHeaderText + item.route}
                >
                  <NavMenuItem
                    searchValue={searchValue}
                    displayItem={item}
                    activeItem={activeItem}
                    onMouseEnter={onMouseEnter}
                  />
                </div>
              ))}
            </React.Fragment>
          ))
        : nothingFoundBox}
    </div>
  )
}
