import { spacing, type TimeOfDay } from '@retailer-platform/shared-components'
import styled from '@emotion/styled'
import { type Moment } from 'moment'
import { type FormContextValues } from 'react-hook-form-5'
import { type OpenMode } from '../store-open-mode-select/StoreHoursBulkStoreOpenModeSelect'
import { type ClearOverrideHoursCriteriaInput, GoogleTypeDayOfWeek } from '../../../../api'

export const HourTypeContainer = styled.div({
  padding: `${spacing.X12} 0`,
})

export const Row = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  margin: '10px 0px',
})

export const HourEntryRow = styled(Row)({
  height: '40px',
})

export const InputContainer = styled.div({
  width: '150px',
})

export type FormDayHours = {
  openMode?: OpenMode
  openTime?: TimeOfDay
  closeTime?: TimeOfDay
}

export type FormRegularHours = Record<GoogleTypeDayOfWeek, FormDayHours>

export type FormSpecialHours = {
  date: Moment
  description?: string
  openMode?: OpenMode
  openTime?: TimeOfDay
  closeTime?: TimeOfDay
}

export type SpecialHoursUpdateMode = 'replace' | 'append'

export type FormServiceTypeHours = {
  clearOverrideHoursCriteria?: ClearOverrideHoursCriteriaInput[]
  regular: FormRegularHours
  regularReplaceHours: boolean //a separate field because useForm doesn't like null fields
  special?: FormSpecialHours[]
  specialReplaceHours: boolean //a separate field because useForm doesn't like null fields
  specialHoursUpdateMode: SpecialHoursUpdateMode
}

export type FormData = {
  delivery?: FormServiceTypeHours
}

export type ServiceType = 'delivery'

// FUTURE DEV: add new service types here
export const SERVICE_TYPES: ServiceType[] = ['delivery']

export type HourType = 'regular' | 'special'

export const shouldDisplayEditHoursFormForPath = (path: string, form: FormContextValues) => {
  // FUTURE DEV: add new service types here
  const serviceType: ServiceType = 'delivery' //only support type right now

  let hourType: HourType
  if (path.includes('regular')) hourType = 'regular'
  if (path.includes('special')) hourType = 'special'
  if (!hourType) throw Error(`Could not determine hour type in path: ${hourType}`)

  return shouldDisplayEditHoursForm(serviceType, hourType, form)
}

export const shouldDisplayEditHoursForm = (
  serviceType: ServiceType,
  hourType: HourType,
  form: FormContextValues
) => {
  const replaceHoursEnabled = form.watch(`${serviceType}.${hourType}ReplaceHours`, false)
  return replaceHoursEnabled
}

export const daysOfWeekKeys = Object.keys(GoogleTypeDayOfWeek).filter(
  e => GoogleTypeDayOfWeek[e] != GoogleTypeDayOfWeek.DayOfWeekUnspecified
)

export const daysOfWeekOrder = [
  GoogleTypeDayOfWeek.Monday,
  GoogleTypeDayOfWeek.Tuesday,
  GoogleTypeDayOfWeek.Wednesday,
  GoogleTypeDayOfWeek.Thursday,
  GoogleTypeDayOfWeek.Friday,
  GoogleTypeDayOfWeek.Saturday,
  GoogleTypeDayOfWeek.Sunday,
]

export const shouldDisplayTimeSelects = (watchOpenMode: string) => watchOpenMode === 'open'
