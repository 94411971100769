import { MenuItem } from '@retailer-platform/shared-components/src/tds'
import { type FunctionComponent } from 'react'
import { useTrackEvent } from '../../../../utils/events/useTrackEvent.hook'
import { type Item } from './NavMenuItemGroup'
import { BaseNavLink } from './NavLink'

export type Props = {
  searchValue: string
  displayItem: Item
  activeItem: Item | null
  onMouseEnter: () => void
}

export const NavMenuItem: FunctionComponent<Props> = ({
  searchValue,
  displayItem,
  activeItem,
  onMouseEnter,
}) => {
  const trackEvent = useTrackEvent()

  return (
    <BaseNavLink
      key={displayItem.label + displayItem.route}
      route={displayItem.route}
      data-route={displayItem.route}
      onClick={() => {
        trackEvent({
          id: 'navV2.searchSpotlight.navigate',
          description: 'User is navigating to a search result',
          data: {
            searchValue: searchValue,
            action: displayItem.label,
            route: displayItem.route,
            navigateBy: 'click',
          },
        })
        displayItem.onClick?.()
      }}
      onMouseEnter={onMouseEnter}
    >
      <MenuItem
        text={displayItem.label}
        isHover={activeItem?.label === displayItem.label && activeItem?.route === displayItem.route}
      />
    </BaseNavLink>
  )
}
