import type { PropsWithChildren, FunctionComponent } from 'react'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'
import { type NavMenuHierarchy } from '../../NavMenuHierarchy'
import { L1Header } from '../L1Header'
import { useGenerateL2ComponentTree } from '../navBar.common'

type Props = {
  currentlySelectedItem: number
  closeDrawer: () => void
  navMenuHierarchy: NavMenuHierarchy
}

const NavOuterContainer = styled.div({
  height: '100%',
  position: 'relative',
  width: '400px',
  backgroundColor: 'white',
  paddingTop: '24px',
})

const NavMainContent = styled.div(({ marginTop }: { marginTop: boolean }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: marginTop ? '16px' : '0px',
}))

const navGroupPadding = '8px 16px 8px 24px'
const navItemPadding = '8px 16px 8px 21px'

export const DrawerContentsRightPane: FunctionComponent<PropsWithChildren<Props>> = ({
  currentlySelectedItem,
  closeDrawer,
  navMenuHierarchy,
}) => {
  const l1 = navMenuHierarchy.hierarchy[currentlySelectedItem]
  const intl = useIntl()
  const l2Items = useGenerateL2ComponentTree(l1, closeDrawer, true, navItemPadding, navGroupPadding)

  if (!l1) {
    return null
  }
  const Icon = l1.icon

  return (
    <NavOuterContainer>
      {l1.name && (
        <L1Header
          HeaderIcon={Icon}
          HeaderIconPath={l1.iconPath}
          titleMessage={intl.formatMessage({ id: l1.name })}
          description={l1.description}
          customPadding="0px 0px 0px 24px"
          descriptionLink={l1.descriptionLink}
          isNarrow={true}
        />
      )}
      <NavMainContent marginTop={!l1.hasL2Children}>{l2Items}</NavMainContent>
    </NavOuterContainer>
  )
}
