import React, { lazy } from 'react'
import { DashSwitch } from '@retailer-platform/dashboard/routing'
import { DomainRoute } from '../utils/domain/routingComponents'
import { onboardingSiteSetupAccessControl } from '../access-control/onboardingAccess.configuration'

const OnboardingSiteOverviewPage = lazy(
  () => import('../pages/site-overview/OnboardingSiteOverviewPage')
)
// @hygen:inject page-import

export const onboardingAdminRouter = (
  <DomainRoute route="app-admin-onboarding" accessControl={onboardingSiteSetupAccessControl}>
    <DashSwitch>
      <DomainRoute
        exact
        route="app-admin-onboarding-site-overview"
        component={OnboardingSiteOverviewPage}
      />
      {/* @hygen:inject router-route
        injection point for routes, please do not delete */}
    </DashSwitch>
  </DomainRoute>
)
