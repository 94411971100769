import type { PropsWithChildren, ComponentType, FunctionComponent } from 'react'
import { useContext, useMemo } from 'react'
import { useRetailerId } from '../../../../../../utils/routing/params.hooks'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParams } from 'use-query-params'
import { RegionLocationContext } from '../../../../../contexts/regionLocation/RegionLocationContext'
import { useGetCatalogItems } from '../../../../../sections/catalog/graphql/getCatalogItems.hooks'
import { SurfaceContext } from '../../../../../contexts/surface/SurfaceContext'
import { useTempTakedownOverrides } from '../../../../../sections/catalog/graphql/listTempTakedownOverrides.hooks'

export const useSproutsProductLocationsHelper = () => {
  const retailerId = useRetailerId()
  // This accounts for the old and new routes
  const { productId, product_id } = useParams<{
    productId?: string
    product_id?: string
  }>()
  const { surfaceId } = useContext(SurfaceContext)
  const [queryParams] = useQueryParams({
    inventory_area_ids: StringParam,
  })

  const resolvedProductId = productId || product_id
  const { inventoryAreas } = useContext(RegionLocationContext)

  const allInventoryAreaIds = useMemo(() => {
    if (queryParams.inventory_area_ids) {
      return queryParams.inventory_area_ids.split(',')
    }
    return inventoryAreas.map(v => v.id.toString())
  }, [inventoryAreas, queryParams.inventory_area_ids])

  const result = useGetCatalogItems({
    data: {
      parameters: {
        productId: resolvedProductId,
        retailerId: retailerId,
        inventoryAreaIds: allInventoryAreaIds,
        surfaceId: surfaceId.toString(),
      },
    },
  })

  const takeDowns = useTempTakedownOverrides({
    data: {
      productId: productId,
      retailerId: retailerId,
      surfaceId: surfaceId.toString(),
      localeId: '1',
    },
  })

  const availabilityTakeDowns = useMemo(() => {
    return takeDowns?.data?.tempOverrideEntities
      ? takeDowns.data.tempOverrideEntities.filter(e =>
          (e?.tempOverrideAttrs ?? []).some(a => a.attribute.includes('available'))
        )
      : []
  }, [takeDowns?.data?.tempOverrideEntities])

  const takenDownItemIds: string[] = useMemo(() => {
    return availabilityTakeDowns ? availabilityTakeDowns.map(e => e.entityId.itemId) : []
  }, [availabilityTakeDowns])

  const takeDownEnd = useMemo(() => {
    const startDate = availabilityTakeDowns?.[0]?.tempOverrideAttrs.filter(a =>
      a.attribute.includes('available')
    )?.[0]?.createdAt
    let date = new Date()
    if (startDate) {
      date = new Date(startDate)
    }
    date.setDate(date.getDate() + 2)
    return date.toLocaleDateString('en-CA')
  }, [availabilityTakeDowns])

  // We check if the item is on sale by comparing the current date to the sale start and end dates
  const isSalePriceActive = (saleStartAt: string | null, saleEndAt: string | null) => {
    if (!saleStartAt || !saleEndAt) return false

    const currentDate = new Date()
    // If the dates are invalid, we return false
    try {
      const startDate = new Date(saleStartAt)
      const endDate = new Date(saleEndAt)

      return currentDate >= startDate && currentDate <= endDate
    } catch (e) {
      return false
    }
  }

  const items = result.data?.results.items.map(i => ({
    id: i.itemId,
    size: i.retailerProductsAttributes.size,
    unit: i.retailerProductsAttributes.sizeUom,
    created_at: i.itemAttributes.createdAt,
    cost_price_per_unit: i.itemAttributes.costPricePerUnit?.replace(/\$/g, ''),
    price: i.itemAttributes.price?.replace(/\$/g, ''),
    visible: i.itemAttributes.visible,
    product_type: i.itemAttributes.productType,
    taxable: i.itemAttributes.taxable,
    warehouse_id: i.itemAttributes.retailerId,
    unlisted: i.itemAttributes.unlisted,
    par_weight: i.itemAttributes.parWeight,
    cost_unit: i.itemAttributes.costUnit,
    product_id: i.itemAttributes.productId,
    inventory_area_id: i.inventoryAreaAttributes.inventoryAreaId,
    available: i.itemAttributes.available,
    sale_start_at: i.itemAttributes.saleStartAt,
    sale_end_at: i.itemAttributes.saleEndAt,
    loyalty_cost_price_start_at: i.itemAttributes.loyaltyCostPriceStartAt,
    loyalty_cost_price_end_at: i.itemAttributes.loyaltyCostPriceEndAt,
    loyalty_price: i.itemAttributes.loyaltyPrice?.replace(/\$/g, ''),
    loyalty_price_active: i.itemAttributes.loyaltyPriceActive,
    sale_price_active: isSalePriceActive(i.itemAttributes.saleStartAt, i.itemAttributes.saleEndAt),
    aisles: [
      { id: i.retailerProductsAttributes.aisleIds, name: i.retailerProductsAttributes.aisleNames },
    ],
    inventory_area: {
      name: i.inventoryAreaAttributes.name,
      region_id: i.inventoryAreaAttributes.regionId,
    },
    large_image_url: i.retailerProductsAttributes.largeImageUrl,
    primary_image_url: i.retailerProductsAttributes.primaryImageUrl,
    sale_price: isSalePriceActive(i.itemAttributes.saleStartAt, i.itemAttributes.saleEndAt)
      ? i.itemAttributes.salePriceCents
        ? '$ ' + i.itemAttributes.salePriceCents / 100
        : i.itemAttributes.salePriceCents
      : 'None',
    full_price: i.itemAttributes.fullPrice?.replace(/\$/g, ''),
    brand_name: i.retailerProductsAttributes.brandName,
    derived: {
      has_admin_availability_override: Boolean(i.itemAttributes.availabilityStartDate),
    },
    emergency_take_down: takenDownItemIds.includes(i.itemId.toString()),
    emergency_take_down_end: takeDownEnd,
    in_assortment: Boolean(i.itemAttributes.inAssortment ?? false),
  }))

  const itemIds = useMemo(() => {
    if (!items) return []

    return items.map(({ id }) => id)
  }, [items])
  return { ...result, data: items, itemIds }
}
export const withSproutsProductLocationsHelper = <T,>(
  Component: ComponentType<PropsWithChildren<T>>
): FunctionComponent<PropsWithChildren<T>> => {
  return props => {
    const { isError, isLoading, data, refetch, itemIds } = useSproutsProductLocationsHelper()
    return (
      <Component
        {...props}
        isError={isError}
        isLoading={isLoading}
        data={data}
        refetch={refetch}
        itemIds={itemIds}
      />
    )
  }
}
