import React, { type FunctionComponent } from 'react'
import { type ApolloError } from '@apollo/client'
import { type GridColumns, GridList, withContainerSize } from '@retailer-platform/shared-components'
import { FormattedDate } from 'react-intl'
import { EmptyState, EmptyStateArtwork, EmptyStateTitle } from '@instacart/ids-customers'
import { ErrorPage } from '@retailer-platform/dashboard/gin-and-tonic'
import reportsEmptyStateImg from '../../../../assets/reports-empty-state-illustration.png'
import { ReportsNormalizedReportsListDefaultCell } from '../default-cell/ReportsNormalizedReportsListDefaultCell'
import { ReportsNormalizedReportsListDefaultHeader } from '../default-header/ReportsNormalizedReportsListDefaultHeader'
import { type ReportsNormalizedReportsDatum } from '../../utils/ReportsNormalizedReportsList.types'
import { ReportsNormalizedReportsListDownloadCell } from '../download-cell/ReportsNormalizedReportsListDownloadCell'
import {
  useDomainMessage,
  useDomainMessages,
  type DomainMessages,
} from '../../../../utils/domain/intl'

const ExpandableGridList = withContainerSize(GridList)

interface ReportsNormalizedReportsGridListProps {
  reports: ReportsNormalizedReportsDatum[]
  loading: boolean
  canLoadMore?: boolean
  loadMore: () => void
  error?: ApolloError
  refetch: () => void
  isHeavyQuery: boolean
}

const baseColumns: GridColumns<ReportsNormalizedReportsDatum> = [
  {
    id: 'name',
    width: 200,
  },
  {
    id: 'reportType',
    Cell: ({ row }) =>
      useDomainMessage(
        `reportsDomain.normalizedReports.list.reportTypes.${row.original.reportType}` as DomainMessages
      ),
  },
  {
    id: 'fileType',
    width: 75,
  },
  {
    id: 'banner',
    width: 75,
  },
  {
    id: 'createdAt',
    width: 75,
    Cell: ({ row }) => <FormattedDate timeZone="utc" value={row.original.createdAt} />,
  },
  {
    id: 'tools',
    Header: '',
    Cell: ReportsNormalizedReportsListDownloadCell,
  },
]

const columns: GridColumns<ReportsNormalizedReportsDatum> = baseColumns.map(column => ({
  ...column,
  Cell: column.Cell ?? ReportsNormalizedReportsListDefaultCell,
  Header: column.Header ?? ReportsNormalizedReportsListDefaultHeader,
}))

export const ReportsNormalizedReportsGridList: FunctionComponent<
  React.PropsWithChildren<ReportsNormalizedReportsGridListProps>
> = ({ loading, error, reports, loadMore, canLoadMore, refetch, isHeavyQuery }) => {
  const messages = useDomainMessages({
    isHeavyQuery: 'reportsDomain.normalizedReports.list.isHeavyQuery',
    noResults: 'reportsDomain.normalizedReports.list.noResults',
  })

  if (error) return <ErrorPage action={refetch} />

  return (
    <ExpandableGridList
      onLoadMore={loadMore}
      data={reports || []}
      columns={columns}
      loadingMore={loading}
      canLoadMore={canLoadMore}
      emptyState={
        <div css={{ marginTop: '200px' }}>
          <EmptyState>
            <EmptyStateArtwork src={reportsEmptyStateImg} />
            <EmptyStateTitle>
              {isHeavyQuery ? messages.isHeavyQuery : messages.noResults}
            </EmptyStateTitle>
          </EmptyState>
        </div>
      }
    />
  )
}
