import { useEffect } from 'react'

type DatadogPrivacyValue = 'allow' | 'mask-user-input' | 'mask'

// Define a mapping of privacy values to their priority.
// Higher numbers are more private and are prioritized.
const privacyPriority: Record<DatadogPrivacyValue, number> = {
  allow: 0,
  'mask-user-input': 1,
  mask: 2,
}

// Global array to track active privacy values
let activePrivacyValues: DatadogPrivacyValue[] = []

const updateBodyPrivacy = () => {
  if (activePrivacyValues.length === 0) {
    // Remove the data-dd-privacy attribute if no active privacy values
    document.body.removeAttribute('data-dd-privacy')
  } else {
    // Set the data-dd-privacy attribute to the most private active value
    const mostPrivateValue = activePrivacyValues.reduce((prev, curr) =>
      privacyPriority[curr] > privacyPriority[prev] ? curr : prev
    )
    document.body.setAttribute('data-dd-privacy', mostPrivateValue)
  }
}

/**
 * Custom hook to manage the data-dd-privacy attribute on the HTML <body> tag. This is important
 * to protect user data in the browser from being captured by Datadog.
 *
 * https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options
 *
 * This strategy applies a single privacy value to the HTML <body> tag. This means there is
 * a single privacy value that is applied to the entire application. If there are multiple
 * users of this hook, the most private value will be applied.
 *
 * This strategy was chosen over others since it will handle edge cases where a tool
 * opens a Modal or some other React Portal-y component that renders outside of the tool
 * in the DOM. It gets really tricky to apply a privacy everywhere across a tool for
 * this reason. To resolve this, we apply a single privacy value to the <body> tag so that
 * everything everywhere will be private as needed. A simple solution.
 *
 * This allow developers to have their own privacy rules applied to their own lower elements
 * in the DOM, if they want to override this global privacy value. See this link:
 * https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/#override-an-html-element
 *
 * @param privacyValue - The privacy value to set.
 */
export const useDatadogPrivacy = (privacyValue: DatadogPrivacyValue) => {
  useEffect(() => {
    activePrivacyValues.push(privacyValue)
    updateBodyPrivacy()

    // Cleanup function to remove the privacy value when the component is unmounted
    return () => {
      activePrivacyValues = activePrivacyValues.filter(value => value !== privacyValue)
      updateBodyPrivacy()
    }
  }, [privacyValue])
}
