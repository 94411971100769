import type { PropsWithChildren, FunctionComponent } from 'react'
import { useMemo } from 'react'
import {
  spacing,
  fontWeight,
  fontSize,
  colors,
  Tooltip,
} from '@retailer-platform/shared-components'
import { HelpIcon, AccountIcon, TeamIcon } from '@instacart/ids-core'
import styled from '@emotion/styled'
import clientEnv from '../../utils/global/clientEnv'
import { errors } from '../../utils/error-handling/errors'
import { useCurrentAccountDetails } from '../../api/current-account/currentAccount.hooks'
import { Permission } from '../../legacy/common/types'
import { useTotem } from '../../api/totem/useTotem.hooks'
import { useDashMessages } from '../../intl/intl.hooks'
import { useTotemInfo } from './useTotemInfo'

const SLACK_CHANNEL_TYPE_PUBLIC = 'SLACK_CHANNEL_TYPE_PUBLIC'

const HeaderContainer = styled.div({
  display: 'flex',
  width: '100%',
  height: spacing.X32,
  minHeight: spacing.X32,
  paddingLeft: spacing.X16,
  alignItems: 'center',
  fontSize: fontSize.X12,
  borderBottom: `1px solid ${colors.GRAYSCALE.X20}`,
})
const ContactSpan = styled.span({
  marginRight: spacing.X24,
  marginLeft: spacing.X4,
  b: {
    fontWeight: fontWeight.BOLD,
  },
  a: {
    textDecoration: 'underline',
  },
})
const TooltipWrapper = styled.div({
  display: 'flex',
  alignItems: 'stretch',
  height: '20px',
})

export const ContactHeader: FunctionComponent<PropsWithChildren<unknown>> = () => {
  const i18n = useDashMessages({
    tooltipConfigurationMessage: 'app.admin.contactHeader.tooltip.configuration',
    tooltipHelpMessage: 'app.admin.contactHeader.tooltip.help',
    team: 'app.admin.contactHeader.team',
    slack: 'app.admin.contactHeader.slack',
    oncall: 'app.admin.contactHeader.oncall',
  })
  const { account } = useCurrentAccountDetails()
  const totemInfo = useTotemInfo()

  const hasTotemAccess = useMemo(
    () => account?.permissions.some(p => p.slug === Permission.Totem),
    [account]
  )

  const shouldDisplay = useMemo(
    () =>
      clientEnv.PUBLIC_CLIENT_ENVIRONMENT === 'production' ||
      (totemInfo?.hasDevTooling && clientEnv.PUBLIC_CLIENT_ENVIRONMENT === 'enterprise-dev'),
    [totemInfo]
  )

  const baseTotemUrl = clientEnv.PUBLIC_CLIENT_TOTEM_URL

  const { data, loading, error } = useTotem({
    // having no externalId will skip the query
    externalId: hasTotemAccess && shouldDisplay && totemInfo?.routePattern,
  })

  if (!hasTotemAccess || !shouldDisplay || !data || loading || error) {
    if (error) {
      errors.captureException(error, `ContactHeader with error + ${error}`)
    }
    return <></>
  }

  // find team name associated with the system. it will exist
  const teamName = data?.owner_team?.workday_org_name

  // find the first public slack channels associated with the system. it may not exist
  const slackChannels = data?.contact_details?.general_slack_channels?.channels?.filter(
    channel => channel.type === SLACK_CHANNEL_TYPE_PUBLIC
  )
  const mainSlackChannel = slackChannels && slackChannels.length > 0 && slackChannels[0]

  // find any on-call handle associated with the system. it may not exist
  const onCallHandle = data?.contact_details?.oncall_slack_group?.handle

  const toolTipMessage = mainSlackChannel
    ? `${i18n.tooltipHelpMessage} ${i18n.tooltipConfigurationMessage}`
    : i18n.tooltipConfigurationMessage

  // Hide other icons when there is no slack channel title
  // it looks a little off without this
  const showAccountAndTeamIcons = Boolean(mainSlackChannel)

  return (
    <HeaderContainer>
      <TooltipWrapper>
        <Tooltip placement="bottom" target={<HelpIcon size={'20px'} />}>
          {toolTipMessage}
        </Tooltip>
      </TooltipWrapper>
      {mainSlackChannel && (
        <ContactSpan>
          <b>{i18n.slack}: </b>
          <a
            href={`https://instacart.enterprise.slack.com/archives/${mainSlackChannel.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            #{mainSlackChannel.handle}
          </a>
        </ContactSpan>
      )}
      {onCallHandle && (
        <>
          {showAccountAndTeamIcons && <AccountIcon size={'20px'} css={{ flexShrink: 0 }} />}
          <ContactSpan>
            <b>{i18n.oncall}: </b>@{onCallHandle}
          </ContactSpan>
        </>
      )}
      {teamName && (
        <>
          {showAccountAndTeamIcons && <TeamIcon size={'20px'} css={{ flexShrink: 0 }} />}
          <ContactSpan>
            <b>{i18n.team}: </b>
            <a
              href={`${baseTotemUrl}/teams/${data.owner_team.name}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {teamName}
            </a>
          </ContactSpan>
        </>
      )}
    </HeaderContainer>
  )
}
