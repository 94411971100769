import type { PropsWithChildren, FC } from 'react'
import { useNavHierarchyRetailer, useUserSettingsHierarchy } from '../../NavMenuHierarchy'
import { LeftShortcutsPane } from './LeftShortcutsPane'

export const LeftShortcutsPaneRetailer: FC<PropsWithChildren<unknown>> = () => {
  const { indexedL1ByRoutes: indexedNavHierarchyRetailerL1ByRoutes } = useNavHierarchyRetailer()
  const { indexedL1ByRoutes: indexedSettingsHierarchyL1ByRoutes } = useUserSettingsHierarchy()

  const combinedRoutes = {
    ...indexedNavHierarchyRetailerL1ByRoutes,
    ...indexedSettingsHierarchyL1ByRoutes,
  }

  return <LeftShortcutsPane indexedL1ByRoutes={combinedRoutes} />
}
