import React, { type ReactNode, useMemo } from 'react'
import {
  FixedSizeList as WindowList,
  type FixedSizeListProps as WindowListProps,
} from 'react-window'
import { css } from '@emotion/react'
import { useEffect } from 'react'
import { useTDSContext } from '../../tds/index'
import {
  type GridTableOptions,
  type GridDatum,
  type GridState,
  type GridColumn,
} from './utils/grid.types'
import { useGridDetails, type UseGridDetailsProps } from './utils/grid.hooks'
import { useGridWindowDetails } from './utils/gridWindow.hooks'
import {
  windowStyleOverrides,
  gridOuterStyles,
  gridErrorMessageStyles,
  gridEmptyStateStyles,
  GridStickyRow,
  GridRowsContainer,
  GridCellLoading,
} from './utils/grid.styles'
import { makeWindowListRefHandler } from './utils/gridRefs.utils'
import { DefaultEmptyState } from './components/DefaultEmptyState'
import { GridRenderedRow } from './components/GridRenderedRow'
import { getBodyRowHeight } from './utils/gridCompact.utils'

export interface GridProps<TDatum extends GridDatum>
  extends UseGridDetailsProps<TDatum>,
    Partial<WindowListProps> {
  height: WindowListProps['height']
  errorMessage?: ReactNode
  isLoading?: boolean
  // Should pass one of { emptyState, emptyStateMessage }
  // Ideally we could work this into the typings - was having
  // some issues when trying to update this to a union type.
  emptyState?: ReactNode
  emptyStateMessage?: string
  emptyStateShowHeader?: boolean
  tableOptions?: GridTableOptions<TDatum>
  onStateChanged?: (state: GridState<TDatum>) => void
  useLoadingRows?: boolean
  loadingRowsCount?: number
  compact?: boolean
}

export const Grid = <TDatum extends GridDatum>({
  height,
  data,
  columns,
  isLoading,
  emptyState,
  emptyStateMessage,
  emptyStateShowHeader = true,
  errorMessage,
  innerRef,
  outerRef,
  tableOptions,
  onStateChanged,
  useLoadingRows,
  loadingRowsCount = 10,
  compact = false,
  ...rest
}: GridProps<TDatum>): React.ReactElement | null => {
  // Prepare some loading data to use when loading
  // Prepare a 'loading' version of the given columns

  const loadingColumns: (Omit<GridColumn<TDatum>, 'Cell'> & {
    Cell: React.FunctionComponent<any>
  })[] = useMemo(
    () =>
      columns.map(col => ({
        ...col,
        Cell: GridCellLoading,
      })),
    [columns]
  )

  // Prepare some loading data to use when loading
  const loadingData = useMemo(
    () => Array.from({ length: loadingRowsCount }).fill({}) as GridDatum[],
    [loadingRowsCount]
  ) as typeof data

  const gridDetails = useGridDetails({
    data: useLoadingRows && isLoading ? loadingData : data,
    columns: useLoadingRows && isLoading ? loadingColumns : columns,
    tableOptions,
    compact,
  })
  useEffect(() => {
    onStateChanged?.(gridDetails?.state)
  }, [gridDetails.state, onStateChanged])

  const { renderedHeaders, itemCount, itemData } = useGridWindowDetails(gridDetails)

  const { useTDS } = useTDSContext()

  // Add useTDS to itemData
  const enrichedItemData = useMemo(
    () => ({
      ...itemData,
      useTDS,
    }),
    [itemData, useTDS]
  )

  const emptyStateContent = useMemo(() => {
    if (emptyStateMessage) {
      return <DefaultEmptyState emptyStateMessage={emptyStateMessage} />
    }

    return emptyState
  }, [emptyState, emptyStateMessage])

  const showEmptyState = useMemo(() => {
    if (isLoading) return false
    if (itemCount > 0) return false

    return Boolean(emptyStateContent)
  }, [emptyStateContent, isLoading, itemCount])

  // inspired by https://github.com/bvaughn/react-window#does-this-library-support-sticky-items
  const InnerElement = useMemo(
    () =>
      React.forwardRef<HTMLDivElement, { children: React.ReactNode }>(
        ({ children, ...rest }, ref) => (
          <div ref={ref} {...rest}>
            {renderedHeaders.map((headerRow, index) => (
              <GridStickyRow
                index={index}
                key={'header' + index}
                compact={compact}
                data-testid={`grid-sticky-row-${index}`}
              >
                {headerRow}
              </GridStickyRow>
            ))}

            <GridRowsContainer compact={compact} rowOffset={renderedHeaders.length}>
              {children}
            </GridRowsContainer>
          </div>
        )
      ),
    [renderedHeaders, compact]
  )

  const bodyContent = useMemo(() => {
    if (errorMessage) {
      return (
        <div css={css(gridErrorMessageStyles, { height })} data-testid="grid-body-error-message">
          <>{errorMessage}</>
        </div>
      )
    }

    if (showEmptyState) {
      return (
        <div css={css(gridEmptyStateStyles, { height })} data-testid="grid-body-empty-state">
          {emptyStateShowHeader && renderedHeaders}
          {emptyStateContent}
        </div>
      )
    }

    const modifiedInnerRef = makeWindowListRefHandler(innerRef)
    const modifiedOuterRef = makeWindowListRefHandler(outerRef)

    return (
      <WindowList
        height={height}
        itemCount={itemCount}
        itemSize={getBodyRowHeight(compact)}
        width="100%"
        style={windowStyleOverrides}
        innerRef={modifiedInnerRef}
        outerRef={modifiedOuterRef}
        itemData={enrichedItemData}
        innerElementType={InnerElement}
        {...rest}
      >
        {GridRenderedRow}
      </WindowList>
    )
  }, [
    errorMessage,
    showEmptyState,
    innerRef,
    outerRef,
    height,
    itemCount,
    InnerElement,
    rest,
    emptyStateShowHeader,
    renderedHeaders,
    emptyStateContent,
    compact,
    enrichedItemData,
  ])

  return (
    <div css={gridOuterStyles} data-testid="grid-outer-container">
      {bodyContent}
    </div>
  )
}
